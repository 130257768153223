<app-header></app-header>
<br><br><br><br>


<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="courses-details-header">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="courses-title">
                                <div class="row">
                                    <div class="col-6">
                                        <h2><i class='bx bx-box'></i> {{tutorialData.tutorialName}}</h2>
                                    </div>
                                    <div class="col-6" style="text-align: right;">
                                        <a class="default-btn mb20 font_w_l cursor_p" style="float: right;border-radius: 50px 0px 50px 50px;" routerLink="/learn-film-marking-with-dbima/dashboard">
                                            <i class='bx bxs-dashboard icon-arrow before'></i>
                                            <span class="label">{{lang_.txt_1}}</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </a>
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-4">
                        <app-module-navigation></app-module-navigation>
                    </div>

                    <div class="col-lg-1"></div>

                    <div class="col-lg-6">
                        <div class="courses-details-image cs-card">

                            <div id="intro" style="padding: 30px;">
                                <h1>{{tutorialData.tutorialName}}</h1>
                                <label style="margin-right: 20px;">{{partCount}} {{lang_.txt_2}}</label> <i class='bx bx-time'></i> {{tutorialData.tutorialDurationMinute}} min
                                <div class="row">
                                    <div class="col-8">
                                        <p class="cs-padding-32">{{tutorialData.tutorialOverview}}</p>
                                    </div>
                                    <div class="col-4">
                                        <img src="../../../../../../assets/img/illus/youtube_tutorial.svg" alt="">
                                    </div>
                                </div>
                                <a class="default-btn cursor_p" routerLink="/learn-film-marking-with-dbima/module/{{currentUserProgressModuleID}}/tutorial/{{currentUserProgressPartID}}">
                                    <i class='bx bx-paper-plane icon-arrow before'></i>
                                    <span class="label">{{lang_.txt_3}}</span>
                                    <i class="bx bx-paper-plane icon-arrow after"></i>
                                </a>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-1"></div>

                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>