export const module_txt = [
    {
        txt_1: 'Dashboard',
        txt_2: 'lessons',
        txt_3: 'Start Learning'
    },
    {
        txt_1: 'Tableau de bord',
        txt_2: 'leçons',
        txt_3: 'Commencez à apprendre'
    },
    {
        txt_1: 'Tablero de mandos',
        txt_2: 'lecciones',
        txt_3: 'Empezar a aprender'
    },
    {
        txt_1: 'Műszerfal',
        txt_2: 'leckék',
        txt_3: 'Kezdje el a tanulást'
    },
    {
        txt_1: 'Информационно табло',
        txt_2: 'уроци',
        txt_3: 'Започнете да учите'
    }
];


