<app-header></app-header>
<br><br><br><br>
<div class="my-dashboard-area ptb-100">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="row">
                    <div class="col-12">

                        <a class="default-btn mb20 font_w_l cursor_p cs-text-black cs-hover-border-black cs-border-light-gray ml10" style="float: right;border-radius: 20px 20px 5px 5px;background-color: transparent;border-color: transparent;" routerLink="/learn-film-marking-with-dbima/dashboard"
                            *ngIf="!firstTimeInProgram">
                            <i class='bx bxs-dashboard icon-arrow before cs-text-black'></i>
                            <span class="label">{{lang_.txt_1}}</span>
                            <i class="bx bx-paper-plane icon-arrow after cs-text-black"></i>
                        </a>

                        <a routerLink="/learn-film-marking-with-dbima" class="default-btn mb20 font_w_l cursor_p cs-text-black cs-hover-border-black cs-border-light-gray ml10" style="float: right;border-radius: 20px 20px 5px 5px;background-color: transparent;border-color: transparent;"
                            *ngIf="firstTimeInProgram">
                            <i class='bx bx-paper-plane icon-arrow before cs-text-black'></i>
                            <span class="label">{{lang_.txt_2}}</span>
                            <i class="bx bx-paper-plane icon-arrow after cs-text-black"></i>
                        </a>

                        <a class="default-btn mb20 font_w_l cursor_p cs-text-black cs-hover-border-black cs-border-light-gray" style="float: right;border-radius: 50px 0px 50px 50px;background-color: transparent;border-color: transparent;" routerLink="/give-us-your-feedback">
                            <i class='bx bx-comment-dots icon-arrow before cs-text-black'></i>
                            <span class="label">{{lang_.txt_3}}</span>
                            <i class="bx bx-paper-plane icon-arrow after cs-text-black"></i>
                        </a>
                    </div>
                </div>
                <div class="myDashboard-profile">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-5">
                            <div class="profile-image">
                                <div class="ImgContainer">
                                    <img id="profileImage" *ngIf="userData.profileUrl == null || userData.profileUrl == '' || userData.profileUrl == 'null'" src="../../../../assets/img/illus/Profile Pix-01.svg" style="padding: 30px;" alt="image">
                                    <img id="profileImage" *ngIf="userData.profileUrl != null && userData.profileUrl != '' && userData.profileUrl != 'null'" src="{{userData.profileUrl}}" style="padding: 30px;" alt="Profile Image" onerror="profilePicError(this)">
                                    <div class="overlay">
                                        <div class="text">
                                            <button class="cs-button cs-round" title="Select Profile Picture" onclick="modelShow('profilePicUploadModel')"><i
                                                    class='bx bx-image-add'></i></button>
                                            <br>
                                            <button *ngIf="userData.profileUrl != null && userData.profileUrl != '' && userData.profileUrl != 'null'" class="cs-button cs-round" title="View Profile Picture" onclick="viewImgFullScreen('profileImage')"><i class='bx bx-show'></i></button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-7">
                            <div class="row">
                                <div class="col-6">
                                    <div class="profile-content">
                                        <h3>{{userData.firstName +" "+ userData.lastName}}</h3>
                                        <p>{{userData.description}}</p>
                                        <p *ngIf="userData.organization != null && userData.organization != ''">@ {{userData.organization}}
                                        </p>
                                        <ul class="contact-info">
                                            <li><i class='bx bx-envelope'></i> <a href="mailto:{{userData.email}}">{{userData.email}}</a></li>
                                            <li *ngIf="userData.phoneNumber != null && userData.phoneNumber != ''"><i class='bx bx-phone'></i> <a href="tel:+{{userData.phoneNumber}}">{{userData.phoneNumber}}</a></li>
                                        </ul>

                                        <a routerLink="/" class="myDashboard-logout" (click)="logout()">{{lang_.txt_4}}</a>
                                    </div>
                                </div>
                                <div class="col-6 cs-display-container" *ngIf="!firstTimeInProgram">
                                    <div id="userProgressChart_div" style="width:300px;max-width:300px" class="cs-display-container cs-display-middle">
                                        <div class="cs-display-middle">
                                            <h2>{{userProgressPercentage}}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="myDashboard-navigation">
                                <ul>
                                    <li style="float: right;">
                                        <button type="button" onclick="toggleView('account_details')" *ngIf="!ac_details_opened" (click)="ac_details_btn(1)" style="border-radius: 0px 0px 0px 20px;" class="default-btn cs-right">
                                            <i class='bx bx-edit icon-arrow before'></i>
                                            <span class="label" style="font-weight: lighter;">{{lang_.txt_5}}</span>
                                            <i class="bx bx-edit icon-arrow after"></i>
                                        </button>

                                        <button type="button" onclick="toggleView('account_details')" *ngIf="ac_details_opened" (click)="ac_details_btn(0)" style="border-radius: 0px 0px 0px 20px;" class="default-btn cs-right">
                                            <i class='bx bx-window-close icon-arrow before'></i>
                                            <span class="label" style="font-weight: lighter;">{{lang_.txt_6}}</span>
                                            <i class="bx bx-window-close icon-arrow after"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="myDashboard-content cs-animate-zoom cs-hide" id="account_details">
                    <form class="edit-account" #editAccountForm="ngForm" (ngSubmit)="submit(editAccountForm.value)">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label>{{lang_.txt_7}} <span class="required">*</span> <i class="bx bxs-star"
                                            style="color: #F9A826;"></i></label>
                                    <input [(ngModel)]="userData.firstName" required minlength="3" maxlength="20" ngModel name="fname" type="text" #fname="ngModel" [class.invalid_input]="fname.touched && !fname.valid" class="form-control" id="fname" placeholder="{{lang_.txt_7}}">
                                    <div class="err_msg" *ngIf="fname.touched && !fname.valid">

                                        <div *ngIf="fname.errors.required">{{lang_.txt_8}}</div>
                                        <div *ngIf="fname.errors.minlength">{{lang_.txt_9}}</div>
                                        <div *ngIf="fname.errors.maxlength">{{lang_.txt_10}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label>{{lang_.txt_11}} <span class="required">*</span> <i class="bx bxs-star"
                                            style="color: #F9A826;"></i></label>
                                    <input [(ngModel)]="userData.lastName" required minlength="3" maxlength="20" ngModel name="lname" type="text" #lname="ngModel" [class.invalid_input]="lname.touched && !lname.valid" class="form-control" id="lname" placeholder="Last Name">
                                    <div class="err_msg" *ngIf="lname.touched && !lname.valid">
                                        <div *ngIf="lname.errors.required">{{lang_.txt_12}}</div>
                                        <div *ngIf="lname.errors.minlength">{{lang_.txt_13}}</div>
                                        <div *ngIf="lname.errors.maxlength">{{lang_.txt_14}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="form-group">
                                    <label>{{lang_.txt_15}} <i class="bx bxs-star" style="color: #F9A826;"></i></label>
                                    <input [(ngModel)]="userData.dob" type="date" ngModel name="dob" [class.invalid_input]="dob.touched && !dob.valid" #dob="ngModel" placeholder="{{lang_.txt_15}}" class="form-control cursor_p" id="dob" max="{{today}}">
                                    <div class="err_msg" *ngIf="dob.touched && !dob.valid">
                                        <div>{{lang_.txt_16}}</div>
                                    </div>
                                </div>
                                <!--description-->
                            </div>
                            <div class="col-md-6" [class.col-lg-4]="userData.type != ''" [class.col-lg-12]="userData.type == ''">
                                <div class="form-group">
                                    <label>{{lang_.txt_17}}</label>
                                    <div class="form-control cs-light-gray" style="padding-top: 12px;">
                                        <span *ngIf="profileTypeName == 'Student'">{{lang_.txt_18}}</span>
                                        <span *ngIf="profileTypeName == 'Teacher'">{{lang_.txt_19}}</span>
                                        <span *ngIf="profileTypeName == 'Other'">{{lang_.txt_20}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6" *ngIf="userData.type == '6'">
                                <div class="form-group">
                                    <label>{{lang_.txt_21}} <span class="required">*</span></label>
                                    <input [(ngModel)]="userData.description" type="text" required ngModel name="role_name" #role_name="ngModel" [class.invalid_input]="role_name.touched && !role_name.valid" placeholder="{{lang_.txt_21}}" class="form-control" id="role_name">
                                    <div class="err_msg" *ngIf="role_name.touched && !role_name.valid">{{lang_.txt_22}}</div>
                                </div>
                                <!--description-->
                            </div>
                            <div class="col-md-6" [class.col-lg-8]="userData.type != '6'" [class.col-lg-4]="userData.type == '6'" *ngIf="userData.type != ''">
                                <div class="form-group">
                                    <label>{{lang_.txt_23}} <span class="required">*</span></label>
                                    <input [(ngModel)]="userData.organization" type="text" required ngModel name="place" #place="ngModel" [class.invalid_input]="place.touched && !place.valid" placeholder="School/Establishment Name" class="form-control" id="place">
                                    <div class="err_msg" *ngIf="place.touched && !place.valid">{{lang_.txt_24}}</div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <label>{{lang_.txt_25}}</label>
                                    <div class="form-control cs-light-gray" style="padding-top: 12px;">
                                        {{userData.email}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <label>{{lang_.txt_26}}</label>
                                    <input [(ngModel)]="userData.phoneNumber" ngModel name="phoneNumber" type="phoneNumber" #phoneNumber="ngModel" [class.invalid_input]="phoneNumber.touched && !phoneNumber.valid" class="form-control" id="phoneNumber" placeholder="{{lang_.txt_26}}" pattern="[0-9]*">
                                    <div class="err_msg" *ngIf="phoneNumber.touched && !phoneNumber.valid">
                                        <div *ngIf="phoneNumber.errors.pattern">{{lang_.txt_27}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <label>{{lang_.txt_28}}</label>
                                    <input [(ngModel)]="userData.country" ngModel name="country" type="text" #country="ngModel" [class.invalid_input]="country.touched && !country.valid" class="form-control" id="country" placeholder="{{lang_.txt_28}}" pattern="[a-zA-Z ]*">
                                    <div class="err_msg" *ngIf="country.touched && !phoneNumber.valid">
                                        <div *ngIf="country.errors.pattern">{{lang_.txt_29}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group" style="text-align: right;">
                                    <p> <span class="required cs-xlarge" style="color: red;">*</span> {{lang_.txt_30}}</p>
                                    <p> <i class="bx bxs-star" style="color: #F9A826;"></i> {{lang_.txt_31}}</p>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" *ngIf="editAccountForm.valid">
                                    <i class='bx bx-save icon-arrow before'></i>
                                    <span class="label">{{lang_.txt_32}} </span>
                                    <i class="bx bx-save icon-arrow after"></i>
                                </button>
                                <button type="submit" class="default-btn" *ngIf="!editAccountForm.valid" style="cursor: not-allowed;">
                                    <i class='bx bx-save icon-arrow before'></i>
                                    <span class="label">{{lang_.txt_32}} </span>
                                    <i class="bx bx-save icon-arrow after"></i>
                                </button>
                            </div>
                        </div>
                    </form>

                    <button type="button" class="default-btn font_w_l" style="float: right;border-radius: 50px 0px 50px 50px;" onclick="modelShow('userPasswordChangeModel')">
                        <i class='bx bx-link-external icon-arrow before'></i>
                        <span class="label">{{lang_.txt_33}}</span>
                        <i class="bx bx-link-external icon-arrow after"></i>
                    </button>

                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>


<!-- The Modals -->
<div id="profilePicUploadModel" class="cs-modal" style="z-index: 999;">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16" style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <div>
                <h3>{{lang_.txt_34}}</h3>
                <span onclick="modelClose('profilePicUploadModel')" class="cs-button cs-display-topright cs-large">&times;</span>
                <hr>
            </div>
            <label class="mt-1 mb-0 instruction-text">● {{lang_.txt_35}} ( Max: <b>2MB</b> ) <span class="ml-3"></span></label><br>
            <label class="mt-1 mb-0 instruction-text">● {{lang_.txt_36}} ( <b>.jpg/ .jpeg/ .png</b> ) <span class="ml-3"></span></label><br>

            <form id="form_profilePicUploadModel" (ngSubmit)="uploadProfilePicture();">

                <div class="img-container cs-display-container" style="margin: 20px;padding: 0px;">

                    <img id="img_preview" *ngIf="userData.profileUrl == null || userData.profileUrl == '' || userData.profileUrl == 'null'" src="../../../../assets/img/illus/Profile Pix-01.svg" style="padding: 20px;">

                    <img id="img_preview" *ngIf="userData.profileUrl != null && userData.profileUrl != '' && userData.profileUrl != 'null'" src="{{userData.profileUrl}}" style="padding: 20px;" onerror="profilePicError(this)">

                    <div class="img-input cs-display-middle ">

                        <label class="cursor_p" for="img_preview_input">
                        <i class='bx bxs-image-add' style="font-size: 200px;"></i>
                    </label>

                        <input id="img_preview_input" type="file" class="cursor_p" required (change)="profilePictureInputChange($event)" onchange="readIMG_URL(this, 'img_preview','spp_err_div','spp_submit')" accept=".png, .jpg, .jpeg," />

                    </div>
                </div>

                <div>
                    <div class="row">
                        <div class="col-10 alert alert-danger m-auto cs-hide" id="spp_err_div">
                        </div>
                    </div>
                    <hr>
                    <button type="submit" class="default-btn" style="font-weight: lighter;border-radius: 50px;" id="spp_submit">
                        <i class='bx bx-cloud-upload icon-arrow before'></i>
                        <span class="label" *ngIf="img_uploaded">{{lang_.txt_37}}</span>
                        <span class="label" *ngIf="!img_uploaded && !upload_pending">{{lang_.txt_38}}</span>
                        <span class="label" *ngIf="!img_uploaded && upload_pending">{{lang_.txt_39}}</span>
                        <i class="bx bx-cloud-upload icon-arrow after"></i>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>

<div id="profilePicViewModel" class="cs-modal" style="z-index: 999;">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16" style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <span onclick="modelClose('profilePicViewModel')" class="cs-button cs-display-topright cs-large">&times;</span>
            <img id="full_img_view" src="../../../../assets/img/illus/Profile Pix-01.svg" style="padding: 20px;max-height: 700px;width: auto;">
        </div>
    </div>
</div>

<div id="userPasswordChangeModel" class="cs-modal" style="z-index: 999;">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16" style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <div>
                <h3>{{lang_.txt_40}} </h3>
                <span onclick="modelClose('userPasswordChangeModel')" class="cs-button cs-display-topright cs-large">&times;</span>
                <hr>
            </div>

            <form #userPwdUpdateForm="ngForm" (ngSubmit)='user_pwd_update(userPwdUpdateForm.value)'>
                <div class="row">
                    <div class="col-lg-12 col-md-12 cs-padding-16">
                        <div class="form-group">
                            <input required ngModel name="old_password" type="password" #old_password="ngModel" [class.invalid_input]="old_password.touched && !old_password.valid" class="form-control" id="old_password" placeholder="{{lang_.txt_41}}">
                            <div class="err_msg" *ngIf="old_password.touched && !old_password.valid">
                                {{lang_.txt_42}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 cs-padding-16">
                        <div class="form-group">
                            <input required minlength="6" maxlength="50" ngModel name="password" type="password" #password="ngModel" [class.invalid_input]="password.touched && !password.valid" class="form-control invalid_input" id="password" placeholder="{{lang_.txt_43}}">
                            <div class="err_msg" *ngIf="password.touched && !password.valid">
                                <div *ngIf="password.errors.required">{{lang_.txt_44}}</div>
                                <div *ngIf="password.errors.minlength">{{lang_.txt_45}}</div>
                                <div *ngIf="password.errors.maxlength">{{lang_.txt_46}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 cs-padding-16">
                        <div class="form-group">
                            <input required ngModel name="confirm_password" type="password" #confirm_password="ngModel" [class.invalid_input]="confirm_password.touched && !confirm_password.valid" class="form-control" id="confirm_password" placeholder="{{lang_.txt_47}}">
                            <div class="err_msg" *ngIf="confirm_password.touched && !confirm_password.valid">{{lang_.txt_47}}</div>
                        </div>
                    </div>

                </div>

                <div>
                    <hr>
                    <button type="submit" class="default-btn font_w_l" style="float: right;border-radius: 0px 0px 20px 20px;" *ngIf="confirm_password.valid && password.valid && old_password.valid">
                        <div *ngIf="!change_pwd_req_pending">
                            <i class='bx bx-check-shield icon-arrow before'></i>
                            <span class="label">{{lang_.txt_33}}</span>
                            <i class="bx bx-check-shield icon-arrow after"></i>
                        </div>
                        <div *ngIf="change_pwd_req_pending">
                            <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin"></i></i>
                            <span class="label">{{lang_.txt_48}}</span>
                            <i class="icon-arrow after"><i class="bx bx-loader-alt fa-spin"></i></i>
                        </div>
                    </button>

                    <button type="button" class="font_w_l default-btn" style="float: right;border-radius: 0px 0px 20px 20px;cursor: not-allowed;" *ngIf="!confirm_password.valid || !password.valid || !old_password.valid">
                        <i class='bx bx-check-shield icon-arrow before'></i>
                        <span class="label">{{lang_.txt_33}}</span>
                        <i class="bx bx-check-shield icon-arrow after"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>