<app-header></app-header>
<br><br><br><br>
<app-main-banner></app-main-banner>

<div class="testimonials-area pt-100 pb-70" style="background-image: url(assets/img/pattern.jpg);">
    <div class="container">
        <div class="section-title">
            <h2>{{lang_.txt_1}}</h2>
        </div>
        <div>
            <app-feedback-display></app-feedback-display>
        </div>
    </div>

</div>

<div class="partner-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{lang_.txt_2}}</h2>
        </div>
        <app-our-partners></app-our-partners>
    </div>
</div>