<app-header></app-header>
<br><br><br><br>
<div class="cs-hide-large cs-hide-medium">
    <br>
</div>
<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="courses-details-header">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <div class="courses-title">
                                <h2>{{courseData.courseName}}</h2>
                                <br>
                                <p>{{courseData.courseOverview}}</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="courses-price">

                                <div *ngIf="firstTimeInProgram && currentUserName != null">

                                    <a (click)="registerToTheProgram()" class="default-btn cursor_p" *ngIf="!startingProgram">
                                        <i class='bx bx-paper-plane icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_2}}</span>
                                        <i class="bx bx-paper-plane icon-arrow after"></i>
                                    </a>

                                    <a class="default-btn cursor_p cu-btn-no-icon cu-btn-disabled" disabled *ngIf="startingProgram">
                                        <span class="label">{{lang_.txt_3}}<i class="fa fa-circle-o-notch fa-spin  ml10"></i></span>
                                    </a>
                                </div>

                                <a routerLink="/{{module_link}}" class="default-btn cursor_p" *ngIf="!firstTimeInProgram && currentUserName != null && !programCompleted">
                                    <i class='bx bx-paper-plane icon-arrow before'></i>
                                    <span class="label">{{lang_.txt_4}}</span>
                                    <i class="bx bx-paper-plane icon-arrow after"></i>
                                </a>

                                <a routerLink="/{{module_link}}" class="default-btn cursor_p" *ngIf="!firstTimeInProgram && currentUserName != null && programCompleted">
                                    <i class='bx bx-certification icon-arrow before'></i>
                                    <span class="label">{{lang_.txt_5}}</span>
                                    <i class="bx bx-certification icon-arrow after"></i>
                                </a>

                                <a routerLink="/register" class="default-btn" *ngIf="currentUserName == null ">
                                    <i class='bx bx-log-in-circle icon-arrow before'></i>
                                    <span class="label">{{lang_.txt_6}}</span>
                                    <i class="bx bx-log-in-circle icon-arrow after"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/Tutorial Page.jpg" alt="image">
                        </div>
                        <div class="courses-details-desc">
                            <h3>{{lang_.txt_7}}</h3>
                            <div class="why-you-learn">
                                <ul>
                                    <li *ngFor="let tutorial of tutorialData">
                                        <span>
                                            <i class='bx bx-box'></i>
                                            {{tutorial.tutorialName}}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div>
                            <hr>
                        </div>
                        <div class="courses-sidebar-information">
                            <ul>
                                <li>
                                    <span><i class='bx bx-book'></i> {{lang_.txt_8}}:</span> {{courseData.noOfModules}}
                                </li>
                                <!-- <li>
                                    <span><i class='bx bx-time'></i> Length:</span> {{courseData.courseDurationMinute}} Minutes {{courseData.courseDurationSecond}} Seconds
                                </li> -->
                                <li>
                                    <span><i class='bx bx-detail'></i> {{lang_.txt_9}}:</span> {{lang_.txt_10}}
                                </li>
                                <li>
                                    <span><i class='bx bxs-badge-check'></i> {{lang_.txt_11}}:</span> {{lang_.txt_12}}
                                </li>
                                <!-- <li>
                                    <span><i class='bx bx-support'></i> Language:</span> English
                                    {{courseData.languageCode}}
                                </li>
                                <li>
                                    <span><i class='bx bx-text'></i> Video Subtitle:</span> Yes
                                </li> -->
                                <li>
                                    <span><i class='bx bx-certification'></i> {{lang_.txt_13}}:</span> {{lang_.txt_14}}
                                </li>
                            </ul>

                            <a class="default-btn mb20 font_w_l cursor_p" style="float: right;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;" *ngIf="currentUserName != null && !firstTimeInProgram" routerLink="/learn-film-marking-with-dbima/dashboard">
                                <i class='bx bxs-dashboard icon-arrow before'></i>
                                <span class="label">{{lang_.txt_15}}</span>
                                <i class="bx bx-paper-plane icon-arrow after"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>