import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CourseService } from 'src/app/services/course-service';
import { Languages } from 'src/app/models/languages';
@Component({
    selector: 'app-language-changer',
    templateUrl: './language-changer.component.html',
    styleUrls: ['./language-changer.component.scss']
})
export class LanguageChangerComponent implements OnInit {

    langCode: string;
    languageList: Languages[] = [];

    currentUserName: string;
    currentUserLang: string;
    userLoggedIn = false;


    constructor(private http: HttpClient, private LanguageService: LanguageService, private courseService: CourseService) { }

    ngOnInit(): void {
        this.userData();
        this.langData_loader();
    }

    userData() {

        const userStatus = parseInt(localStorage.getItem('XJFYUserStatus'));
        const userObj = JSON.parse(localStorage.getItem('XJFYUser'));

        if (userStatus === 1) {
            this.currentUserName = userObj.firstName + ' ' + userObj.lastName;
            this.currentUserLang = userObj.language;
        }

        if (this.currentUserName != null) {
            this.userLoggedIn = true;
        } else {
            this.userLoggedIn = false;
        }
    }

    lang_loader() {
        if (this.userLoggedIn) {
            this.langCode = this.currentUserLang;

            localStorage.setItem("e-cademy_lang", this.langCode);
            for (let index = 0; index < this.languageList.length; index++) {
                var element = this.languageList[index];
                if(element.code == this.langCode){
                    localStorage.setItem("e-cademy_courseId", element.courseId);
                }
            }
        } else {
            this.langCode = this.LanguageService.getCode();
            localStorage.setItem("e-cademy_lang", this.langCode);
        }
    }

    lang_selector(id, courseId) {
        localStorage.setItem("e-cademy_lang", id);
        localStorage.setItem("e-cademy_courseId", courseId);
        window.location.reload();
    }

    langData_loader() {
        this.courseService.getAllLanguages().subscribe(response => {
            if (response.status === 1) {
                this.languageList = response.languageDtoList;
                this.lang_loader();
            } else {
                console.log('Something went wrong !.');
            }
        });
    }


}
