export const forgotPassword_txt = [
    {
        txt_1: 'Recover Password',
        txt_2: 'Know your password?',
        txt_3: 'Login',
        txt_4: 'Your email address',
        txt_5: 'Email address is required.',
        txt_6: 'Invalid email address',
        txt_7: 'SENDING REQUEST',
        txt_8: 'GET YOUR OTP CODE',
        txt_9: 'GET YOUR OTP CODE',
        txt_10: 'Your OTP CODE',
        txt_11: 'OTP CODE is required.',
        txt_12: 'Invalid OTP CODE',
        txt_13: 'SENDING REQUEST',
        txt_14: 'CONFIRM',
        txt_15: 'CONFIRM',
        txt_16: 'New password',
        txt_17: 'Password is required.',
        txt_18: 'Password minimum is 6 characters.',
        txt_19: 'Password maximum is 50 characters.',
        txt_20: 'Confirm your new password',
        txt_21: 'Confirm your Password',
        txt_22: 'SENDING REQUEST',
        txt_23: 'CHANGE PASSWORD',
        txt_24: 'CHANGE PASSWORD'
    },
    {
        txt_1: 'Récupérer le mot de passe',
        txt_2: 'Connaissez-vous votre mot de passe',
        txt_3: 'Connexion',
        txt_4: 'Votre adresse électronique',
        txt_5: 'L\'adresse e-mail est obligatoire',
        txt_6: 'Adresse électronique non valide',
        txt_7: 'ENVOI DE LA DEMANDE',
        txt_8: 'OBTENEZ VOTRE CODE OTP',
        txt_9: 'OBTENEZ VOTRE CODE OTP',
        txt_10: 'Votre CODE OTP',
        txt_11: 'Le CODE OTP est requis.',
        txt_12: 'CODE OTP invalide',
        txt_13: 'ENVOI DE LA DEMANDE',
        txt_14: 'CONFIRMEZ',
        txt_15: 'CONFIRMEZ',
        txt_16: 'Nouveau mot de passe',
        txt_17: 'Le mot de passe est obigatoire.',
        txt_18: 'Le mot de passe doit comporter au moins 6 caractères.',
        txt_19: 'Le mot de passe ne doit pas dépasser 50 caractères.',
        txt_20: 'Confirmez votre nouveau mot de passe',
        txt_21: 'Confirmez votre nouveau mot de passe',
        txt_22: 'ENVOI DE LA DEMANDE',
        txt_23: 'CHANGER LE MOT DE PASSE',
        txt_24: 'CHANGER LE MOT DE PASSE'
    },
    {
        txt_1: 'Recuperar contraseña',
        txt_2: '¿Conocer tu contraseña?',
        txt_3: 'Login',
        txt_4: 'Su dirección de correo electrónico',
        txt_5: 'Se necesita la dirección e-mail.',
        txt_6: 'Dirección de correo electrónico no válida',
        txt_7: 'ENVÍO DE LA SOLICITUD',
        txt_8: 'OBTENGA SU CÓDIGO OTP',
        txt_9: 'OBTENGA SU CÓDIGO OTP',
        txt_10: 'Su código OTP',
        txt_11: 'Se requiere el CÓDIGO OTP.',
        txt_12: 'Código OTP inválido',
        txt_13: 'ENVÍO DE LA SOLICITUD',
        txt_14: 'CONFIRMAR',
        txt_15: 'CONFIRMAR',
        txt_16: 'Nueva contraseña',
        txt_17: 'Se necesita la contraseña.',
        txt_18: 'La contarseña debe contener 6 carácteres como mínimo',
        txt_19: 'La contraseña tiene un máximo de 50 caracteres.',
        txt_20: 'Confirme su nueva contraseña',
        txt_21: 'Confirme su nueva contraseña',
        txt_22: 'ENVÍO DE LA SOLICITUD',
        txt_23: 'CAMBIAR CONTRASEÑA',
        txt_24: 'CAMBIAR CONTRASEÑA'
    },
    {
        txt_1: 'Jelszó visszaállítása',
        txt_2: 'Tudod a jelszavadat?',
        txt_3: 'Bejelentkezés',
        txt_4: 'Az Ön e-mail címe',
        txt_5: 'E-mail cím szükséges',
        txt_6: 'Érvénytelen e-mail cím',
        txt_7: 'KÉRÉS KÜLDÉSE',
        txt_8: 'SZEREZD MEG AZ OTP KÓDODAT',
        txt_9: 'SZEREZD MEG AZ OTP KÓDODAT',
        txt_10: 'Az Ön OTP CODE-ja',
        txt_11: 'OTP CODE szükséges.',
        txt_12: 'Érvénytelen OTP CODE',
        txt_13: 'KÉRÉS KÜLDÉSE',
        txt_14: 'MEGERŐSÍTÉS',
        txt_15: 'MEGERŐSÍTÉS',
        txt_16: 'Új jelszó',
        txt_17: 'Jelszó szükséges',
        txt_18: 'A jelszó minimum 6 karakterből álljon!',
        txt_19: 'A jelszó maximális hossza 50 karakter.',
        txt_20: 'Új jelszó megerősítése',
        txt_21: 'Új jelszó megerősítése',
        txt_22: 'KÉRÉS KÜLDÉSE',
        txt_23: 'JELSZÓ MÓDOSÍTÁSA',
        txt_24: 'JELSZÓ MÓDOSÍTÁSA'
    },
    {
        txt_1: 'Възстановяване на парола',
        txt_2: 'Знаете паролата си?',
        txt_3: 'Вход',
        txt_4: 'Вашият имейл адрес',
        txt_5: 'Изисква се имейл адрес.',
        txt_6: 'Невалиден имейл адрес',
        txt_7: 'ИЗПРАЩАНЕ НА ЗАЯВКА',
        txt_8: 'ПОЛУЧАВАНЕ НА ВАШИЯ КОД ЗА ОТП',
        txt_9: 'ПОЛУЧАВАНЕ НА ВАШИЯ КОД ЗА ОТП',
        txt_10: 'Вашият OTP CODE',
        txt_11: 'Изисква се OTP CODE.',
        txt_12: 'Невалиден OTP CODE',
        txt_13: 'ИЗПРАЩАНЕ НА ЗАЯВКА',
        txt_14: 'CONFIRM',
        txt_15: 'CONFIRM',
        txt_16: 'Нова парола',
        txt_17: 'Изисква се парола.',
        txt_18: 'Минималната стойност на паролата е 6 символа.',
        txt_19: 'Максималната стойност на паролата е 50 символа.',
        txt_20: 'Потвърдете новата си парола',
        txt_21: 'Потвърдете паролата си',
        txt_22: 'ИЗПРАЩАНЕ НА ЗАЯВКА',
        txt_23: 'ПРОМЯНА НА ПАРОЛАТА',
        txt_24: 'ПРОМЯНА НА ПАРОЛАТА'
    },
];


