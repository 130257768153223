import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { newsletterSubscribe } from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { LanguageService } from 'src/app/services/language.service';
import { footer_txt } from "./footer-banner_txt";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {

    lang_: any;
    allLang_ = footer_txt;
    langCode: string;
    LangCodeIndex: number;

    subscribePending = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) {}


    ngOnInit(): void {
        this.lang_loader();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    newsletterSubscribe_fun(form) {
        if(form.email != null && form.email != ''){
            this.subscribePending = true;
            this.http
                .get<any>(environment.baseUrl + newsletterSubscribe + form.email)
                .subscribe({
                    next: (data) => {
                        if (data.status == 1) {
                            this.openSnackBar(data.message, 1);
                        } else {
                            this.openSnackBar("You have Already Subscribed ...", 1);
                        }

                        this.subscribePending = false;
                    },
                    error: (error) => {
                        this.openSnackBar("Something went Wrong, Please try again latter !",1);
                        this.subscribePending = false;
                    },
                });
        }else{
            this.openSnackBar("Email is Required !", 1);
        }

    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
