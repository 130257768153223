import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LanguageService } from 'src/app/services/language.service';
import { error_txt } from "./error_txt";
@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {

    lang_: any;
    allLang_ = error_txt;
    langCode: string;
    LangCodeIndex: number;

    url_data;

    error_header;
    error_body;

    constructor(
        private activatedRoute: ActivatedRoute,
        private LanguageService: LanguageService
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.lang_loader();
        this.loadData();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    loadData() {
        var error_code = this.url_data.error_code;
        if (error_code == 404) {
            this.error_header = this.lang_.txt_1;
            this.error_body = this.lang_.txt_2;
        } else if (error_code == 401) {
            this.error_header = this.lang_.txt_3;
            this.error_body = this.lang_.txt_4;
        } else {
            this.error_header = this.lang_.txt_1;
            this.error_body = this.lang_.txt_2;
        }
    }
}
