import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { getAllModuleData } from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { LanguageService } from 'src/app/services/language.service';
import { moduleNavigation_txt } from "./module-navigation_txt";
@Component({
    selector: "app-module-navigation",
    templateUrl: "./module-navigation.component.html",
    styleUrls: ["./module-navigation.component.scss"],
})
export class ModuleNavigationComponent implements OnInit {

    lang_: any;
    allLang_ = moduleNavigation_txt;
    langCode: string;
    LangCodeIndex: number;

    url_data;

    courseID;
    language;
    moduleID;
    lessonID;
    pageName;

    tutorialAllData;
    partData;

    partCount;

    constructor(
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.lang_loader();
        this.loadData();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    loadData() {
        this.language = this.langCode;
        this.courseID = parseInt(localStorage.getItem('e-cademy_courseId'));

        if (typeof this.url_data.m_module_id !== "undefined") {
            this.moduleID = this.url_data.m_module_id;
            this.pageName = "module";
        } else if (typeof this.url_data.l_module_id !== "undefined") {
            this.moduleID = this.url_data.l_module_id;
            this.pageName = "lesson";
        } else if (typeof this.url_data.q_module_id !== "undefined") {
            this.moduleID = this.url_data.q_module_id;
            this.pageName = "quiz";
        } else {
            this.moduleID = "undefined";
        }

        if (typeof this.url_data.l_lesson_id !== "undefined") {
            this.lessonID = this.url_data.l_lesson_id;
        } else if (typeof this.url_data.q_lesson_id !== "undefined") {
            this.lessonID = this.url_data.q_lesson_id;
        } else {
            this.lessonID = "undefined";
        }

        this.http
            .get<any>(
                environment.baseUrl +
                getAllModuleData +
                "language=" +
                this.language +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        localStorage.getItem("XJFYToken")
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.tutorialAllData = data.tutorialDtos;

                    var tnpDataArray = new Array();
                    var tnpDataIndex = 0;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        if (
                            this.tutorialAllData[index].tutorial.id ==
                            this.moduleID
                        ) {
                            this.partData = this.tutorialAllData[index].parts;
                        }

                        var pDataArray = this.tutorialAllData[index].parts;

                        for (
                            let index2 = 0;
                            index2 < pDataArray.length;
                            index2++
                        ) {
                            tnpDataArray[tnpDataIndex] = {
                                t_id: this.tutorialAllData[index].tutorial.id,
                                p_id: pDataArray[index2].id,
                                unlocked: false,
                            };

                            tnpDataIndex++;
                        }
                    }

                    var LastUnlockedModule = parseInt(
                        localStorage.getItem("XJFYUserProgressModuleID")
                    );
                    var LastUnlockedLesson = parseInt(
                        localStorage.getItem("XJFYUserProgressPartID")
                    );
                    var indexOfLastUnlockedTNP;

                    for (let index = 0; index < tnpDataArray.length; index++) {
                        tnpDataArray[index];
                        if (
                            tnpDataArray[index].t_id == LastUnlockedModule &&
                            tnpDataArray[index].p_id == LastUnlockedLesson
                        ) {
                            indexOfLastUnlockedTNP = index;
                        }
                    }

                    for (let index = 0; index < this.partData.length; index++) {
                        this.partData[index].unlocked = false;
                    }

                    for (
                        let index = 0;
                        index <= indexOfLastUnlockedTNP;
                        index++
                    ) {
                        tnpDataArray[index].unlocked = true;

                        for (
                            let index2 = 0;
                            index2 < this.partData.length;
                            index2++
                        ) {
                            if (
                                tnpDataArray[index].t_id ==
                                this.partData[index2].tutorialId &&
                                tnpDataArray[index].p_id ==
                                this.partData[index2].id
                            ) {
                                this.partData[index2].unlocked = true;
                            }
                        }
                    }

                    this.partCount = this.partData.length;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
