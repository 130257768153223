import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Languages } from 'src/app/models/languages';
import { CourseService } from 'src/app/services/course-service';
import { LanguageService } from 'src/app/services/language.service';
import { header_txt } from './header_txt';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

    lang_: any;
    allLang_ = header_txt;
    langCode: string;
    LangCodeIndex: number;

    languageList: Languages[] = [];

    static cardApplied: boolean;

    constructor(private snackBar: MatSnackBar, private LanguageService: LanguageService, private courseService: CourseService) { }

    courseID = parseInt(localStorage.getItem('e-cademy_courseId'));
    language;

    currentUserName: string;
    currentUserID: number;
    currentUserToken: string;
    currentUserprofileUrl: string;

    classApplied = false;

    classApplied2 = false;

    classApplied3 = false;

    ngOnInit(): void {
        this.langData_loader();
        this.lang_loader();
        this.loadData();
    }

    loadData() {
        // tslint:disable-next-line: radix
        const userStatus = parseInt(localStorage.getItem('XJFYUserStatus'));
        const userObj = JSON.parse(localStorage.getItem('XJFYUser'));
        const token = localStorage.getItem('XJFYToken');

        if (userStatus === 1) {
            this.currentUserName = userObj.firstName + ' ' + userObj.lastName;
            this.currentUserID = userObj.id;
            this.currentUserToken = token;
            this.currentUserprofileUrl = userObj.profileUrl;
        }
    }

    langData_loader() {
        this.courseService.getAllLanguages().subscribe(response => {
            if (response.status === 1) {
                this.languageList = response.languageDtoList;
            } else {
                console.log('Something went wrong !.');
            }
        });
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    toggleClass2() {
        const obj = document.getElementById('lang_flag_dropdown_switcher');
        obj.classList.toggle('active');
    }
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    openSnackBar(message, state) {
        if (state === 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state === 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

    remove_active(id) {
        document.getElementById(id).classList.remove('active');
    }

    lang_selector(id, courseId) {
        localStorage.setItem("e-cademy_lang", id);
        localStorage.setItem("e-cademy_courseId", courseId);
        window.location.reload();
    }
}

