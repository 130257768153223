import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {

    langCodeArray_ = ['E', 'F', 'S', 'H', 'B'];

    constructor() { }

    getCode(): string {
        var langCode = localStorage.getItem('e-cademy_lang');
        if (langCode) {
            if (langCode != '' && langCode != null) {
            } else {
                langCode = 'E';
            }
        } else {
            langCode = 'E';
        }
        return langCode;
    }

    getCodeIndex(code: String): number {
        var lca = this.langCodeArray_;
        for (let index = 0; index < lca.length; index++) {
            if (lca[index] == code) {
                return index;
            }
        }

    }

}
