import { Component, OnInit } from "@angular/core";
import { LanguageService } from 'src/app/services/language.service';
import { mainBanner_txt } from "./main-banner_txt";
@Component({
    selector: "app-main-banner",
    templateUrl: "./main-banner.component.html",
    styleUrls: ["./main-banner.component.scss"],
})
export class MainBannerComponent implements OnInit {

    lang_: any;
    allLang_ = mainBanner_txt;
    langCode: string;
    LangCodeIndex: number;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;

    constructor(private LanguageService: LanguageService) {}

    ngOnInit(): void {
        this.lang_loader();
        this.getCurrentUserData();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
        } else {
        }
    }
}
