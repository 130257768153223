<app-header></app-header>
<br><br><br><br>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="courses-details-header">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="courses-title">
                                <div class="row">
                                    <div class="col-10">
                                        <h2 routerLink="/learn-film-marking-with-dbima" class="cursor_p">{{courseData.courseName}}</h2>
                                        <h4>{{lang_.txt_1}}</h4>
                                    </div>
                                    <div class="col-2" style="text-align: right;">
                                        <i class='bx bx-left-arrow-circle cursor_p cs-text-gray cs_nav_icon_2 cs-hover-text-blue' style="margin-top: 20px;" onclick="window.history.back();"></i>
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-4">
                        <div class="courses-sidebar-information">
                            <h2>{{lang_.txt_2}}</h2>
                            <ul>
                                <li class="cursor_p cs-hover-light-gray dash_link cs-light-gray" onclick="show_dashboard_dives('dash_modules',this)">
                                    <span><i class='bx bx-box'></i> {{lang_.txt_3}}:</span> 12
                                </li>
                                <li class="cursor_p cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_lessons',this)">
                                    <span><i class='bx bx-slideshow'></i> {{lang_.txt_4}}:</span> 36
                                </li>
                                <li class="cursor_p cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_quizzes',this)">
                                    <span><i class='bx bx-detail'></i> {{lang_.txt_5}}:</span> 36
                                </li>
                                <li>
                                    <div class="cursor_p">
                                        <span><i class='bx bx-bolt-circle'></i> {{lang_.txt_6}}:</span> 2
                                    </div>
                                    <br>
                                    <div class="cs-animate-zoom" id="lessons">
                                        <div>
                                            <button class="cs-button cs-block cs-round row  cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_exam_1',this)" style="width: 90%;margin-left: 20px;">{{lang_.txt_7}}</button>

                                            <button class="cs-button cs-block cs-round row  cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_exam_2',this)" style="width: 90%;margin-left: 20px;">{{lang_.txt_8}}</button>
                                        </div>
                                    </div>

                                </li>
                                <li class="cursor_p cs-hover-light-gray dash_link" onclick="show_dashboard_dives('dash_certificate',this)" id="certificate_dash_link_tab">
                                    <span><i class='bx bx-certification'></i> {{lang_.txt_9}}:</span>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-1"></div>

                    <div class="col-lg-6">
                        <div class="courses-details-image cs-card">
                            <div style="padding: 30px;">

                                <div class="dashboard_dives cs-animate-zoom " id="dash_modules">

                                    <div class="row">
                                        <div class="col-lg-8 col-md-12">
                                            <div class="courses-details-header">
                                                <div class="courses-title">
                                                    <h2>{{lang_.txt_3}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="container cs-display-container" style="height:100px;float: right;" *ngIf="!firstTimeInProgram">
                                                <div id="modules_userProgressChart_div" style="width:200px;" class="cs-display-container">
                                                    <div class="cs-display-middle">
                                                        <h4>{{userProgressPercentage}}</h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <a routerLink="/learn-film-marking-with-dbima" class="default-btn float-end" *ngIf="firstTimeInProgram">
                                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                                <span class="label">{{lang_.txt_10}}</span>
                                                <i class="bx bx-paper-plane icon-arrow after"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="courses-details-desc">
                                        <div class="row">
                                            <div class="col-12 mb20" *ngFor="let tutorial of tutorialData">
                                                <a href="learn-film-marking-with-dbima/module/{{tutorial.id}}" target="_blank" *ngIf="tutorial.unlocked">
                                                    <i class='bx bx-box pr10' [class.cs-text-green]="tutorial.unlocked"></i> {{tutorial.tutorialName}}
                                                    <p class="cs-small" style="margin-left: 20px;">
                                                        {{tutorial.tutorialOverview}}
                                                    </p>
                                                </a>
                                                <div *ngIf="!tutorial.unlocked" class=" cs-text-gray">
                                                    <i class='bx bx-box pr10'></i> {{tutorial.tutorialName}}
                                                    <p class="cs-small" style="margin-left: 20px;">
                                                        {{tutorial.tutorialOverview}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_lessons">

                                    <div class="row">
                                        <div class="col-lg-8 col-md-12">
                                            <div class="courses-details-header">
                                                <div class="courses-title">
                                                    <h2>{{lang_.txt_4}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="container cs-display-container" style="height:100px;float: right;" *ngIf="!firstTimeInProgram">
                                                <div id="lessons_userProgressChart_div" style="width:200px;" class="cs-display-container">
                                                    <div class="cs-display-middle">
                                                        <h4>{{userProgressPercentage}}</h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <a routerLink="/learn-film-marking-with-dbima" class="default-btn float-end" *ngIf="firstTimeInProgram">
                                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                                <span class="label">{{lang_.txt_10}}</span>
                                                <i class="bx bx-paper-plane icon-arrow after"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="courses-details-desc" style="max-height: 500px;overflow: auto;">

                                        <div *ngFor="let tutorials of tutorialAllData ; let i = index" style="width: fit-content;">
                                            <label class="cs-tiny" style="width: fit-content;color: var(--paragraphColor);">{{i+1}}.
                                                {{tutorials.tutorial.tutorialName}} <i class='bx bx-lock' *ngIf="!tutorials.tutorial.unlocked"></i></label>
                                            <div style="margin-bottom: 20px;margin-top: 10px;" *ngFor="let lesson of tutorialAllData[i].parts;">

                                                <a href="learn-film-marking-with-dbima/module/{{tutorials.tutorial.id}}/tutorial/{{lesson.id}}" target="_blank" class=" cs-hover-text-blue" style="padding-left: 30px;" *ngIf="lesson.unlocked"><i
                                                        class='bx bx-play-circle' [class.cs-text-green]="lesson.unlocked"></i> {{lesson.partName}}<span class="cs-tiny ml10">
                                                            {{lesson.partDurationMinute}}min <span *ngIf="lesson.partDurationSecond != 0">{{lesson.partDurationSecond}}sec</span>
                                                        </span>
                                                    </a>

                                                <div class="cs-text-gray" style="padding-left: 30px;" *ngIf="!lesson.unlocked"><i class='bx bx-lock'></i> {{lesson.partName}}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_quizzes">

                                    <div class="row">
                                        <div class="col-lg-8 col-md-12">
                                            <div class="courses-details-header">
                                                <div class="courses-title">
                                                    <h2>{{lang_.txt_5}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="container cs-display-container" style="height:100px;float: right;" *ngIf="!firstTimeInProgram">
                                                <div id="quizzes_userProgressChart_div" style="width:200px;" class="cs-display-container">
                                                    <div class="cs-display-middle">
                                                        <h4>{{userProgressPercentage}}</h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <a routerLink="/learn-film-marking-with-dbima" class="default-btn float-end" *ngIf="firstTimeInProgram">
                                                <i class='bx bx-paper-plane icon-arrow before'></i>
                                                <span class="label">{{lang_.txt_10}}</span>
                                                <i class="bx bx-paper-plane icon-arrow after"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="courses-details-desc" style="max-height: 500px;overflow: auto;">

                                        <div *ngFor="let tutorials of tutorialAllData ; let i = index" style="width: fit-content;">
                                            <label class="cs-tiny" style="width: fit-content;color: var(--paragraphColor);">{{i+1}}.
                                                {{tutorials.tutorial.tutorialName}} <i class='bx bx-lock' *ngIf="!tutorials.tutorial.unlocked"></i></label>
                                            <div style="margin-bottom: 20px;margin-top: 10px;" *ngFor="let lesson of tutorialAllData[i].parts;">

                                                <a href="learn-film-marking-with-dbima/module/{{tutorials.tutorial.id}}/tutorial/{{lesson.id}}/quiz" target="_blank" class=" cs-hover-text-blue" style="padding-left: 30px;" *ngIf="lesson.unlocked"><i
                                                        class='bx bx-detail' [class.cs-text-green]="lesson.unlocked"></i> {{lesson.partName}}</a>

                                                <div class="cs-text-gray" style="padding-left: 30px;" *ngIf="!lesson.unlocked"><i class='bx bx-lock'></i> {{lesson.partName}}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_exam_1">

                                    <a *ngIf="exam01Completed" class="default-btn mb20 font_w_l cursor_p cs-padding" style=" float: right;border-radius: 50px 10px 10px 50px;" href="learn-film-marking-with-dbima/exam-mcq" target="_blank">
                                        <i class='bx bx-paper-plane cs-large'></i>
                                    </a>

                                    <div class="courses-details-header">
                                        <div class="courses-title">
                                            <h2>{{lang_.txt_7}}</h2>
                                        </div>
                                    </div>
                                    <div class="courses-details-desc">

                                        <div class="cs-padding-16" align='center' *ngIf="!all_quiz_completed">
                                            <br>
                                            <div class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_11}}</h2>
                                                <div style="width: 60%;">
                                                    <p>{{lang_.txt_12}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="cs-padding-16" align='center' *ngIf="all_quiz_completed && !exam01Completed">
                                            <br>
                                            <div class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <i class='bx bx-lock-open cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_13}}</h2>
                                                <div style="width: 60%;">
                                                    <p>{{lang_.txt_14}}</p>
                                                </div>
                                                <br>
                                                <a href="learn-film-marking-with-dbima/exam-mcq" class="default-btn" target="_blank">
                                                    <i class='bx bx-log-in-circle icon-arrow before'></i>
                                                    <span class="label">{{lang_.txt_15}}</span>
                                                    <i class="bx bx-log-in-circle icon-arrow after"></i>
                                                </a>
                                            </div>
                                        </div>

                                        <div *ngIf="exam01Completed" class="cs-padding-16" align='center'>
                                            <br>
                                            <div id="exam01ResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <h2>{{exam01resultDisplay}}</h2>
                                                </div>
                                            </div>
                                            <br>
                                            <div *ngIf="!allCompleted">
                                                <h2>{{lang_.txt_16}}</h2>
                                                <p>{{lang_.txt_17}}<i class="bx bx-certification"></i>
                                                </p>
                                                <br>
                                                <a href="learn-film-marking-with-dbima/exam-project" class="default-btn" target="_blank">
                                                    <i class='bx bx-log-in-circle icon-arrow before'></i>
                                                    <span class="label">{{lang_.txt_18}}</span>
                                                    <i class="bx bx-log-in-circle icon-arrow after"></i>
                                                </a>
                                            </div>
                                            <div *ngIf="allCompleted">
                                                <h2>{{lang_.txt_19}}</h2>
                                                <p class="cursor_p cs-hover-text-blue" onclick="document.getElementById('certificate_dash_link_tab').click();">{{lang_.txt_20}} <i class="bx bx-certification"></i></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_exam_2">
                                    <a *ngIf="exam02Completed" class="default-btn mb20 font_w_l cursor_p cs-padding" style=" float: right;border-radius: 50px 10px 10px 50px;" href="learn-film-marking-with-dbima/exam-project" target="_blank">
                                        <i class='bx bx-paper-plane cs-large'></i>
                                    </a>

                                    <div class="courses-details-header">
                                        <div class="courses-title">
                                            <h2>{{lang_.txt_8}}</h2>
                                        </div>
                                    </div>
                                    <div class="courses-details-desc">
                                        <div class="cs-padding-16" align='center' *ngIf="!exam01Completed">
                                            <br>
                                            <div class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_11}}</h2>
                                                <div style="width: 60%;">
                                                    <p>{{lang_.txt_21}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cs-padding-16" align='center' *ngIf="exam01Completed && !urlSubmitted">
                                            <br>
                                            <div class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <i class='bx bx-lock-open cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_13}}</h2>
                                                <div style="width: 60%;">
                                                    <p>{{lang_.txt_22}}</p>
                                                </div>
                                                <br>
                                                <a href="learn-film-marking-with-dbima/exam-project" class="default-btn" target="_blank">
                                                    <i class='bx bx-log-in-circle icon-arrow before'></i>
                                                    <span class="label">{{lang_.txt_23}}</span>
                                                    <i class="bx bx-log-in-circle icon-arrow after"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="cs-padding-16" align='center' *ngIf="exam01Completed && urlSubmitted && !exam02Completed">
                                            <br>
                                            <div class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <i class='bx bx-loader-alt cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_24}}</h2>
                                                <div style="width: 60%;">
                                                    <p>{{lang_.txt_25}}</p>
                                                </div>
                                                <p><b>{{lang_.txt_26}}</b> <br>{{submittedURL}}</p>
                                                <br>
                                            </div>
                                        </div>
                                        <div *ngIf="exam02Completed" class="cs-padding-16" align='center'>
                                            <br>
                                            <div id="exam02ResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <h2>{{exam02resultDisplay}}</h2>
                                                </div>
                                            </div>
                                            <br>
                                            <div *ngIf="!allCompleted">
                                                <h2>{{lang_.txt_16}}</h2>
                                                <p>{{lang_.txt_17}} <i class="bx bx-certification"></i>
                                                </p>
                                            </div>
                                            <div *ngIf="allCompleted">
                                                <h2>{{lang_.txt_19}}</h2>
                                                <p class="cursor_p cs-hover-text-blue" onclick="document.getElementById('certificate_dash_link_tab').click();">{{lang_.txt_20}} <i class="bx bx-certification"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dashboard_dives cs-animate-zoom cs-hide" id="dash_certificate">
                                    <div class="courses-details-header">
                                        <div class="courses-title">
                                            <h2>{{lang_.txt_9}}</h2>
                                        </div>
                                    </div>

                                    <div class="courses-details-desc">
                                        <div class="cs-padding-16" align='center' *ngIf="!allCompleted">
                                            <br>
                                            <div class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_11}}</h2>
                                                <div style="width: 60%;">
                                                    <p>{{lang_.txt_27}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="allCompleted" class="cs-padding-16" align='center'>
                                            <br>
                                            <div id="fullResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <h2>{{fullResultDisplay}}</h2>
                                                </div>
                                            </div>
                                            <br>
                                            <h2>{{lang_.txt_19}}</h2>
                                            <button *ngIf="!profileDataMissing && !downloadPending" class="default-btn mb20 font_w_l cursor_p cs-blue cs-border cs-border-blue" style="border-radius: 20px 20px 0px 0px;" (click)="getYourCertificate()">
                                                <i class='bx bx-certification icon-arrow before'></i>
                                                <span class="label">{{lang_.txt_28}}</span>
                                                <i class="bx bx-cloud-download icon-arrow after"></i>
                                            </button>

                                            <button *ngIf="!profileDataMissing && downloadPending" class="default-btn mb20 font_w_l cursor_p cs-blue cs-border cs-border-blue" style="border-radius: 20px 20px 0px 0px;" (click)="getYourCertificate()">
                                                <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin "></i></i>
                                                <span class="label">{{lang_.txt_29}}</span>
                                                <i class="icon-arrow after"><i class="bx bx-loader-alt fa-spin "></i></i>
                                            </button>


                                            <div align='center' *ngIf="profileDataMissing">
                                                <hr>
                                                <div>
                                                    <i class='bx bx-lock cs-xxxlarge' style="color: var(--mainColor);"></i>
                                                    <h2>{{lang_.txt_30}}</h2>
                                                    <div style="width: 60%;">
                                                        <p>{{lang_.txt_31}}</p>
                                                    </div>
                                                    <p>{{lang_.txt_32}}</p>
                                                </div>
                                                <br>
                                                <a class="default-btn mb20 font_w_l cursor_p" href="/my-dashboard" style="border-radius: 20px 20px 0px 0px;">
                                                    <i class='bx bx-paper-plane icon-arrow before'></i>
                                                    <span class="label">{{lang_.txt_33}}</span>
                                                    <i class="bx bx-paper-plane icon-arrow after"></i>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-1"></div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>