export const examOne_txt = [
    {
        txt_1: 'Exam 01',
        txt_2: 'Dashboard',
        txt_3: 'Module Overview',
        txt_4: 'Exams',
        txt_5: 'Submit',
        txt_6: 'Exam 02',
        txt_7: 'Quiz',
        txt_8: 'Question',
        txt_9: 'Next Question',
        txt_10: 'Couldn\'t found any quizzes',
        txt_11: 'You Have Completed the Exam',
        txt_12: 'Continue the program and get Certified',
        txt_13: 'Your Result',
        txt_14: 'Unfortunately you didn\'t pass',
        txt_15: 'In order to pass the Exam you have to score at least 40/60.',
        txt_16: 'Re-watch the tutorials and try Again',
        txt_17: 'Watch the tutorial',
        txt_18: 'Congratulations, you passed!',
        txt_19: 'Continue the program and get Certified'
    },
    {
        txt_1: 'Examen 01',
        txt_2: 'Tableau de bord',
        txt_3: 'Aperçu du module',
        txt_4: 'Examens',
        txt_5: 'Soumettre',
        txt_6: 'Examen 02',
        txt_7: 'Quiz',
        txt_8: 'Question',
        txt_9: 'Question suivante',
        txt_10: 'Je n\'ai pas trouvé de quiz',
        txt_11: 'Vous avez terminé l\'examen',
        txt_12: 'Poursuivre le programme et obtenir la certification',
        txt_13: 'Votre résultat',
        txt_14: 'Malheureusement, vous n\'avez pas réussi',
        txt_15: 'Pour réussir l\'examen, vous devez obtenir un score d\'au moins 40/60.',
        txt_16: 'Regardez à nouveau les tutoriels et essayez à nouveau',
        txt_17: 'Regardez le tutoriel',
        txt_18: 'Félicitations, vous avez réussi !',
        txt_19: 'Poursuivre le programme et obtenir la certification'
    },
    {
        txt_1: 'Examen 01',
        txt_2: 'Tablero de mandos',
        txt_3: 'Resumen del módulo',
        txt_4: 'Exámenes',
        txt_5: 'Enviar',
        txt_6: 'Examen 02',
        txt_7: 'Cuestionario',
        txt_8: 'Pregunta',
        txt_9: 'Siguiente pregunta',
        txt_10: 'No se ha encontrado ningún cuestionario',
        txt_11: 'Ha completado el examen',
        txt_12: 'Continuar el programa y obtener la certificación',
        txt_13: 'Su resultado',
        txt_14: 'Desgraciadamente no has pasado',
        txt_15: 'Para aprobar el examen hay que obtener una puntuación mínima de 40/60.',
        txt_16: 'Vuelve a ver los tutoriales e inténtalo de nuevo',
        txt_17: 'Ver el tutorial',
        txt_18: 'Enhorabuena, has aprobado.',
        txt_19: 'Continuar el programa y obtener la certificación'
    },
    {
        txt_1: '01-es vizsga',
        txt_2: 'Műszerfal',
        txt_3: 'Modul áttekintése',
        txt_4: 'Vizsgák',
        txt_5: 'Küldje be a',
        txt_6: '02-es vizsga',
        txt_7: 'Kvíz',
        txt_8: 'Kérdés',
        txt_9: 'Következő kérdés',
        txt_10: 'Nem találtam semmilyen kvízt',
        txt_11: 'Teljesítette a vizsgát',
        txt_12: 'Folytassa a programot és szerezzen tanúsítványt',
        txt_13: 'Az Ön eredménye',
        txt_14: 'Sajnos nem mentél át',
        txt_15: 'A vizsga sikeres letételéhez legalább 40/60 pontot kell elérnie.',
        txt_16: 'Nézze újra az oktatóanyagokat és próbálja meg újra',
        txt_17: 'Nézze meg a bemutatót',
        txt_18: 'Gratulálok, átmentél!',
        txt_19: 'Folytassa a programot és szerezzen tanúsítványt'
    },
    {
        txt_1: 'Изпит 01',
        txt_2: 'Информационно табло',
        txt_3: 'Преглед на модула',
        txt_4: 'Изпити',
        txt_5: 'Подаване на',
        txt_6: 'Изпит 02',
        txt_7: 'Викторина',
        txt_8: 'Въпрос',
        txt_9: 'Следващ въпрос',
        txt_10: 'Не успях да намеря никакви тестове',
        txt_11: 'Завършили сте изпита',
        txt_12: 'Продължете програмата и се сертифицирайте',
        txt_13: 'Вашият резултат',
        txt_14: 'За съжаление не сте преминали',
        txt_15: 'За да издържите успешно изпита, трябва да получите поне 40/60 точки.',
        txt_16: 'Прегледайте отново уроците и опитайте отново',
        txt_17: 'Гледайте урока',
        txt_18: 'Поздравления, издържали сте теста!',
        txt_19: 'Продължете програмата и се сертифицирайте'
    },
];


