import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getAllCoursesUrl, getCourseLanguages } from '../models/endpoints';
import { ApiResponse } from '../models/api-response';

@Injectable({
    providedIn: 'root'
})
// tslint:disable-next-line:class-name
export class CourseService {

    constructor(private http: HttpClient) { }

    getAllCourses() {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(getAllCoursesUrl, { headers: new HttpHeaders().set('Authorization', localStorage.getItem('XJFYToken')) });
    }

    getAllLanguages() {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        return this.http.get<ApiResponse>(getCourseLanguages);
    }

}
