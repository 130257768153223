import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { dataNotFound_txt } from './data-not-found_txt';
@Component({
  selector: 'app-data-not-found',
  templateUrl: './data-not-found.component.html',
  styleUrls: ['./data-not-found.component.scss']
})
export class DataNotFoundComponent implements OnInit {

    lang_: any;
    allLang_ = dataNotFound_txt;
    langCode: string;
    LangCodeIndex: number;

    constructor(private LanguageService: LanguageService) { }

    ngOnInit(): void {
        this.lang_loader();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

}
