export const feedback_txt = [
    {
        txt_1: 'Feedback Form',
        txt_2: 'Type your feedback here',
        txt_3: 'Your feedback is required.',
        txt_4: 'Your feedback should have at least 5 characters.',
        txt_5: 'Your feedback should have at least 250 characters.',
        txt_6: 'Submit',
        txt_7: 'Submitting'
    },
    {
        txt_1: 'Formulaire de commentaires',
        txt_2: 'Tapez vos commentaires ici',
        txt_3: 'Vos commentaires sont nécessaires.',
        txt_4: 'Votre commentaire doit comporter au moins 5 caractères.',
        txt_5: 'Votre commentaire doit comporter au moins 250 caractères.',
        txt_6: 'Soumettre',
        txt_7: 'Soumettre un commentaire'
    },
    {
        txt_1: 'Formulario de comentarios',
        txt_2: 'Escriba su opinión aquí',
        txt_3: 'Su opinión es necesaria.',
        txt_4: 'Su comentario debe tener al menos 5 caracteres.',
        txt_5: 'Su comentario debe tener al menos 250 caracteres.',
        txt_6: 'Enviar',
        txt_7: 'Envío de los comentarios'
    },
    {
        txt_1: 'Visszajelzési űrlap',
        txt_2: 'Írja be visszajelzését ide',
        txt_3: 'Szükség van a visszajelzéseire.',
        txt_4: 'A visszajelzésnek legalább 5 karakterből kell állnia.',
        txt_5: 'A visszajelzésnek legalább 250 karakterből kell állnia.',
        txt_6: 'Küldje be a',
        txt_7: 'A visszajelzés benyújtása'
    },
    {
        txt_1: 'Формуляр за обратна връзка',
        txt_2: 'Въведете обратната си връзка тук',
        txt_3: 'Вашата обратна връзка е необходима.',
        txt_4: 'Вашата обратна връзка трябва да съдържа най-малко 5 символа.',
        txt_5: 'Отзивите ви трябва да съдържат най-малко 250 знака.',
        txt_6: 'Подаване на',
        txt_7: 'Подаване на'
    },

];


