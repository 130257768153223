<footer class="footer-area" style="padding-top: 50px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{lang_.txt_1}}</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="https://dbima.eu" target="_blank">
                                Don Bosco International Media Academy
                                <br>
                                75 Rue Alexandre Dumas, Paris 75020 France
                            </a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/dbimaeu/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/DBIMA_EU" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/dbimaeu/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="https://www.instagram.com/dbima_france/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UC9rc0LuQA7FYBEOHivM1dIA" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{lang_.txt_2}}</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/register">{{lang_.txt_3}}</a></li>
                        <li><a routerLink="/login">{{lang_.txt_4}}</a></li>
                        <li><a routerLink="/certification-verify">{{lang_.txt_5}}</a></li>
                        <li><a routerLink="/my-dashboard">{{lang_.txt_6}}</a></li>
                        <li><a routerLink="/cookie-and-privacy-policy">{{lang_.txt_7}}</a></li>
                        <li><a routerLink="/terms-and-conditions">{{lang_.txt_8}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{lang_.txt_9}}</h3>
                    <div class="newsletter-box">
                        <p>{{lang_.txt_10}}</p>
                        <form class="newsletter-form" #newsletterForm="ngForm" (ngSubmit)='newsletterSubscribe_fun(newsletterForm.value)'>
                            <label>{{lang_.txt_11}}</label>
                            <div class="form-group">
                                <input required ngModel name="email" type="email" #email="ngModel" [class.invalid_input]="email.touched && !email.valid" [email]="true" class="input-newsletter" id="email" placeholder="{{lang_.txt_12}}">
                                <div class="err_msg" style="color: red;" *ngIf="email.touched && !email.valid">
                                    <div *ngIf="email.errors.required">{{lang_.txt_13}}</div>
                                    <div *ngIf="!email.errors.required">{{lang_.txt_14}}</div>
                                </div>
                            </div>

                            <button type="submit" style="cursor: not-allowed;" disabled *ngIf="!newsletterForm.valid"><span>{{lang_.txt_15}}</span></button>

                            <button type="submit" *ngIf="newsletterForm.valid"><span *ngIf="!subscribePending">{{lang_.txt_15}}</span> <span
                                    *ngIf="subscribePending">{{lang_.txt_16}} <i
                                        class="bx bx-loader-alt fa-spin ml0"></i></span></button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mt-lg-5">
                    <div class="container">
                        <div class="logo">
                            <a routerLink="/" class="d-inline-block"><img src="assets/img/logo/site/logo_main.png" alt="image" style="width: auto;height: 100px;"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area" style="margin-top: 0px;">
        <div class="container">
            <p><i class='bx bx-copyright'></i>2022 <a routerLink="/" target="_blank">Cagliero E-Cademy </a> Designed and Developed by ESA CineTech for <a href="https://www.cagliero.eu" target="_blank">Cagliero Creative Solutions</a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>