import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import {
    getCourseDetails,
    getExam1Data,
    trackUserProgressState,
    updateExamResults,
    getTNPIdList,
    getUserStateOfProgramRegistration,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { LanguageService } from 'src/app/services/language.service';
import { examOne_txt } from "./exam-one_txt";

declare var loadExamResultChart;
@Component({
    selector: "app-exam-one",
    templateUrl: "./exam-one.component.html",
    styleUrls: ["./exam-one.component.scss"],
})
export class ExamOneComponent implements OnInit {

    lang_: any;
    allLang_ = examOne_txt;
    langCode: string;
    LangCodeIndex: number;

    noData = true;
    loading = true;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    courseID = parseInt(localStorage.getItem('e-cademy_courseId'));
    language;

    courseData = new CourseClass();

    nodata = true;

    quizData;
    quizCount;
    answersList;
    answersCount = 0;
    answersValidated = false;
    validatedAnswerList = [
        {
            quiz_id: 0,
            state: false,
        },
    ];


    correctAnswersCount = 0;
    quizAttemptCount = 0;

    levelPassed = false;

    all_quiz_completed = false;
    currentlyViewingQuizID;

    firstLessonURl;

    exam01Completed = false;
    exam02Completed = false;

    autoSaveInterval;
    exam01resultDisplay;

    firstTimeInProgram = true;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) { }

    ngOnInit(): void {
        this.lang_loader();
        this.loadCourseData();
        this.getCurrentUserData();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    loadCourseData() {
        this.language = this.langCode;
        this.http
            .get<any>(
                environment.baseUrl +
                getCourseDetails +
                "language=" +
                this.language +
                "&courseId=" +
                this.courseID
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                },
                error: (error) => {
                    this.openSnackBar("Something went Wrong, Please try again latter !", 1);
                },
            });
    }

    //------------------------------ User Data

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.getUserStateOfProgramRegistration_function();
        }
    }

    getUserStateOfProgramRegistration_function() {
        this.http
            .get<any>(
                environment.baseUrl + getUserStateOfProgramRegistration +"courseId="+this.courseID+"&userId="+ this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstTimeInProgram = false;
                        this.trackUserProgressState_function();
                    } else {
                        this.firstTimeInProgram = true;
                        this.router.navigate(["error/401"]);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error!," + error.message,
                        0
                    );
                },
            });
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                trackUserProgressState +
                "userId=" +
                this.currentUserId +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;

                        if (data.percentage == 100) {
                            this.all_quiz_completed = true;

                            if (!data.exam01) {
                                this.loadData();
                            } else {
                                this.exam01Completed = true;

                                if (!data.exam02) {
                                } else {
                                    this.exam02Completed = true;
                                }

                                this.noData = false;
                                this.loading = false;

                                this.autoSaveInterval = setTimeout(() => {
                                    this.callToLoadExamResultChart(
                                        "examResultChart",
                                        data.result01,
                                        60 - data.result01
                                    );
                                    this.exam01resultDisplay =
                                        "" + data.result01 + "/60";
                                }, 1000);
                            }
                        } else {
                            this.router.navigate(["error/401"]);
                        }

                        if (data.percentage == 100) {
                        } else {
                            this.router.navigate(["error/401"]);
                        }
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    //---------------------------------------------------------------------------------------

    loadData() {
        this.http
            .get<any>(environment.baseUrl + getExam1Data + this.courseID, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    this.currentUserToken
                ),
            })
            .subscribe({
                next: (data) => {
                    console.log(data);
                    if (data.status == 1) {
                        this.nodata = false;
                        this.quizData = data.quizList;

                        this.quizCount = data.quizList.length;

                        if ( this.quizCount != 0) {
                            this.noData = false;
                        }

                        this.loading = false;
                        this.currentlyViewingQuizID = this.quizData[0].id;
                        this.getFirstLessonURl();
                    } else {
                        this.loading = false;
                        this.nodata = true;
                        document
                            .getElementById("no_data")
                            .classList.remove("cs-hide");
                    }
                },
                error: (error) => {
                    this.noData = true;
                    this.loading = false;
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    switchQuestion(quiz_id) {
        this.currentlyViewingQuizID = quiz_id;
        for (let index = 0; index < this.quizData.length; index++) {
            if (this.quizData[index].id == quiz_id) {
                document
                    .getElementById("qDiv_" + quiz_id + "")
                    .classList.remove("cs-hide");
            } else {
                document
                    .getElementById("qDiv_" + this.quizData[index].id + "")
                    .classList.add("cs-hide");
            }
        }
    }

    selectAnswer(Q_id, A_id) {
        if (!this.levelPassed) {
            var button = document.getElementById("QA_" + Q_id + "_" + A_id);
            var quiz_answers_div =
                button.parentElement.parentElement.parentElement.parentElement;
            var quiz_answers_button = quiz_answers_div.getElementsByClassName(
                "quiz-answers-button"
            );
            for (let index = 0; index < quiz_answers_button.length; index++) {
                quiz_answers_button[index].classList.remove("cs-blue");
            }
            button.classList.add("cs-blue");
            document
                .getElementById("navBtn_" + Q_id + "")
                .classList.add("cs-pale-blue");

            this.storeAnswers();
        }
    }

    storeAnswers() {
        var quiz = document.getElementById("Quiz");
        var answers = quiz.getElementsByClassName("cs-blue");

        var answersArray = new Array();

        for (let index = 0; index < answers.length; index++) {
            var q_a = answers[index].id.replace("QA_", "").split("_");
            answersArray[index] = {
                q_id: q_a[0],
                answer: q_a[1],
            };
        }
        this.answersList = answersArray;
        this.answersCount = answersArray.length;
    }

    validateAnswers() {
        this.quizAttemptCount++;
        this.answersValidated = true;
        var validatedAnswers = new Array();

        for (let index = 0; index < this.answersList.length; index++) {
            var x_q_id = this.answersList[index].q_id;
            var x_answer = this.answersList[index].answer;

            for (let index2 = 0; index2 < this.quizData.length; index2++) {
                var db_q_id = this.quizData[index2].id;
                var db_answer = this.quizData[index2].answerSequence;
                var answer_state;

                if (x_q_id == db_q_id) {
                    if (x_answer == db_answer) {
                        answer_state = true;
                    } else {
                        answer_state = false;
                    }
                    validatedAnswers[index] = {
                        quiz_id: db_q_id,
                        x_answer: x_answer,
                        db_answer: db_answer,
                        state: answer_state,
                    };
                    this.validatedAnswerList[index] = {
                        quiz_id: db_q_id,
                        state: answer_state,
                    };
                }
            }
        }

        this.correctAnswersCount = 0;
        for (let index = 0; index < this.validatedAnswerList.length; index++) {
            var navBtn = document.getElementById(
                "navBtn_" + this.validatedAnswerList[index].quiz_id + ""
            );

            navBtn.classList.remove("cs-pale-blue");
            navBtn.classList.remove("cs-pale-green");
            navBtn.classList.remove("cs-pale-red");
            navBtn.classList.remove("cs-pale-blue");

            if (this.validatedAnswerList[index].state) {
                this.correctAnswersCount++;
                navBtn.classList.add("cs-pale-green");
            } else {
                navBtn.classList.add("cs-pale-red");
            }
        }

        this.callToLoadExamResultChart(
            "examResultChart",
            this.correctAnswersCount,
            30 - this.correctAnswersCount
        );

        if (this.correctAnswersCount >= 20) {
            this.updateExamResults_fun(this.correctAnswersCount * 2);
        }
    }

    getFirstLessonURl() {
        this.http
            .get<any>(environment.baseUrl + getTNPIdList, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    this.currentUserToken
                ),
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstLessonURl =
                            "learn-film-marking-with-dbima/module/" +
                            data.idList[0].tutorialId +
                            "/tutorial/" +
                            data.idList[0].partId +
                            "";
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    updateExamResults_fun(result) {
        this.http
            .get<any>(
                environment.baseUrl +
                updateExamResults +
                "userId=" +
                this.currentUserId +
                "&courseId=" +
                this.courseID +
                "&result=" +
                result +
                "&type=1",
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.openSnackBar(data.message, 1);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    //Remove this function
    // Dummy_checkAllAnswers() {
    //     for (let index = 0; index < this.quizData.length; index++) {
    //         this.selectAnswer(
    //             this.quizData[index].id,
    //             this.quizData[index].answerSequence
    //         );
    //         this.selectAnswer(this.quizData[index].id, 1);
    //     }
    // }

    callToLoadExamResultChart(id, correct, incorrect) {
        loadExamResultChart(id, correct, incorrect);
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
