export const header_txt = [
    {
        txt_1: 'Follow us',
        txt_2: 'Home',
        txt_3: 'Tutorials',
        txt_4: 'Verify Certificate',
        txt_5: 'Contact Us',
        txt_6: 'Login',
        txt_7: 'Register',
    },
    {
        txt_1: 'Suivez-nous',
        txt_2: 'Accueil',
        txt_3: 'Tutoriels',
        txt_4: 'Vérifiez le certificat',
        txt_5: 'Contactez-nous',
        txt_6: 'Se connecter',
        txt_7: 'S\'inscrire',
    },
    {
        txt_1: 'Síguenos',
        txt_2: 'Inicio',
        txt_3: 'Tutoriales',
        txt_4: 'Verifica tu certificado',
        txt_5: 'Contáctanos',
        txt_6: 'Login',
        txt_7: 'Registro',
    },
    {
        txt_1: 'Kövess minket!',
        txt_2: 'Főoldal',
        txt_3: 'Oktatóanyagok',
        txt_4: 'Tanúsítvány ellenőrzése',
        txt_5: 'Kapcsolat',
        txt_6: 'Bejelentkezés',
        txt_7: 'Regisztráció',
    },
    {
        txt_1: 'Следвайте ни',
        txt_2: 'Начало',
        txt_3: 'Наръчници',
        txt_4: 'Проверка на сертификата',
        txt_5: 'Свържете се с нас',
        txt_6: 'Вход',
        txt_7: 'Регистрация',
    },

];


