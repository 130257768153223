<section class="error-area ptb-70">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content" align="center">
                    <img src="assets/img/illus/void.svg" alt="image" style="height: 250px;width:auto;">
                    <h2 class="mt-5">{{lang_.txt_1}}</h2>
                </div>
            </div>
        </div>
    </div>
</section>