export const quiz_txt = [
    {
        txt_1: 'Dashboard',
        txt_2: 'Watch the tutorial',
        txt_3: 'Question',
        txt_4: 'Couldn\'t found any quizzes',
        txt_5: 'Unfortunately you didn\'t pass',
        txt_6: 'In order to pass the quiz you have to score 3/3.',
        txt_7: 'Attempt',
        txt_8: 'Re-watch the tutorial and try Again',
        txt_9: 'You have One last Attempt',
        txt_10: 'Think again , update the answers and re-submit.',
        txt_11: 'Congratulations, you passed!',
        txt_12: 'You have Completed this lesson',
        txt_13: 'Continue the program and get Certified',
        txt_14: 'Back to Lesson',
        txt_15: 'Re-submit',
        txt_16: 'Submit',
        txt_17: 'Next lesson',
        txt_18: 'Continue Program'
    },
    {
        txt_1: 'Tableau de bord',
        txt_2: 'Regardez le tutoriel',
        txt_3: 'Question',
        txt_4: 'Je n\'ai pas trouvé de quiz',
        txt_5: 'Malheureusement, vous n\'avez pas réussi',
        txt_6: 'Pour réussir le quiz, vous devez obtenir un score de 3/3.',
        txt_7: 'Tentative',
        txt_8: 'Regardez à nouveau le tutoriel et essayez à nouveau',
        txt_9: 'Vous avez une dernière chance',
        txt_10: 'Réfléchissez à nouveau, mettez à jour les réponses et soumettez à nouveau.',
        txt_11: 'Félicitations, vous avez réussi !',
        txt_12: 'Vous avez terminé cette leçon',
        txt_13: 'Poursuivre le programme et obtenir la certification',
        txt_14: 'Retour à la leçon',
        txt_15: 'Soumettre à nouveau',
        txt_16: 'Soumettre',
        txt_17: 'Prochaine leçon',
        txt_18: 'Prochaine leçon'
    },
    {
        txt_1: 'Tablero de mandos',
        txt_2: 'Ver el tutorial',
        txt_3: 'Pregunta',
        txt_4: 'No se ha encontrado ningún cuestionario',
        txt_5: 'Desgraciadamente no has pasado',
        txt_6: 'Para aprobar el examen hay que obtener una puntuación de 3/3.',
        txt_7: 'Intento',
        txt_8: 'Vuelve a ver el tutorial e inténtalo de nuevo',
        txt_9: 'Tienes un último intento',
        txt_10: 'Piénselo de nuevo, actualice las respuestas y vuelva a presentarlas.',
        txt_11: 'Enhorabuena, has aprobado.',
        txt_12: 'Has completado esta lección',
        txt_13: 'Continuar el programa y obtener la certificación',
        txt_14: 'Volver a la lección',
        txt_15: 'Vuelva a enviar',
        txt_16: 'Enviar',
        txt_17: 'Próxima lección',
        txt_18: 'Continuar con el programa'
    },
    {
        txt_1: 'Műszerfal',
        txt_2: 'Nézze meg a bemutatót',
        txt_3: 'Kérdés',
        txt_4: 'Nem találtam semmilyen kvízt',
        txt_5: 'Sajnos nem mentél át',
        txt_6: 'A kvíz teljesítéséhez 3/3 pontot kell elérnie.',
        txt_7: 'Kísérlet',
        txt_8: 'Nézze újra a bemutatót és próbálja újra',
        txt_9: 'Van még egy utolsó próbálkozásod',
        txt_10: 'Gondolja át újra , frissítse a válaszokat és küldje be újra.',
        txt_11: 'Gratulálok, átmentél!',
        txt_12: 'Ön befejezte ezt a leckét',
        txt_13: 'Folytassa a programot és szerezzen tanúsítványt',
        txt_14: 'Vissza a leckéhez',
        txt_15: 'Újbóli benyújtás',
        txt_16: 'Küldje be a',
        txt_17: 'Következő lecke',
        txt_18: 'Program folytatása'
    },
    {
        txt_1: 'Информационно табло',
        txt_2: 'Гледайте урока',
        txt_3: 'Въпрос',
        txt_4: 'Не успях да намеря никакви тестове',
        txt_5: 'За съжаление не сте преминали',
        txt_6: 'За да преминете успешно теста, трябва да получите 3/3 точки.',
        txt_7: 'Опит за',
        txt_8: 'Гледайте отново урока и опитайте отново',
        txt_9: 'Имате един последен опит',
        txt_10: 'Помислете отново , актуализирайте отговорите и ги изпратете отново.',
        txt_11: 'Поздравления, издържали сте теста!',
        txt_12: 'Завършихте този урок',
        txt_13: 'Продължете програмата и се сертифицирайте',
        txt_14: 'Обратно към урока',
        txt_15: 'Повторно изпращане на',
        txt_16: 'Подаване на',
        txt_17: 'Следващ урок',
        txt_18: 'Продължаване на програмата'
    }
];


