<app-header></app-header>
<br><br><br><br>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="courses-details-header">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="courses-title">
                                <div class="row">
                                    <div class="col-6">
                                        <h2 routerLink="/learn-film-marking-with-dbima/module/{{tutorialData.id}}" class="cursor_p"><i class='bx bx-box'></i> {{tutorialData.tutorialName}}</h2>
                                        <h4 *ngFor="let part of partData">
                                            <div *ngIf="lessonID == part.id" style="margin-left: 35px;">
                                                <i class='bx bx-book'></i> {{part.partName}}
                                            </div>
                                        </h4>
                                    </div>
                                    <div class="col-6" style="text-align: right;">
                                        <a class="default-btn mb20 font_w_l cursor_p" style="float: right;border-radius: 50px 0px 50px 50px;" routerLink="/learn-film-marking-with-dbima/dashboard">
                                            <i class='bx bxs-dashboard icon-arrow before'></i>
                                            <span class="label">{{lang_.txt_1}}</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </a>
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-4">
                        <app-module-navigation></app-module-navigation>
                    </div>

                    <div class="col-lg-1"></div>

                    <div class="col-lg-6">
                        <div class="courses-details-image cs-card">
                            <a class="default-btn mb20 font_w_l cursor_p" href="learn-film-marking-with-dbima/module/{{moduleID}}/tutorial/{{lessonID}}" style="float: right;border-bottom-left-radius: 20px;">
                                <i class='bx bxs-caret-right-circle icon-arrow before'></i>
                                <span class="label">{{lang_.txt_2}}</span>
                                <i class="bx bxs-caret-right-circle icon-arrow after"></i>
                            </a>
                            <br>
                            <div id="Quiz">
                                <div style="padding: 30px;">

                                    <div *ngFor="let quiz of quizData ; let i = index" style="margin-bottom: 50px;">
                                        <h5 class="cs-text-gray">{{lang_.txt_3}} 0{{i+1}}</h5>

                                        <div class="row cursor_p cs-padding" (click)="toggleAnswerDiv(quiz.id)" id="Q_{{quiz.id}}">
                                            <div class="col-10">
                                                <h2>
                                                    <label *ngIf="answersValidated && !levelPassed">
                                                        <i class="bx bx-check-circle cs-text-green mr10"
                                                            *ngIf="validatedAnswerList[i].state"></i>
                                                        <i class="bx bx-x-circle cs-text-red mr10"
                                                            *ngIf="!validatedAnswerList[i].state"></i>
                                                    </label> {{quiz.question}}
                                                </h2>
                                            </div>
                                            <div class="col-2" style="text-align: right;">
                                                <i class="bx toggleIcon cs-xlarge" [class.bx-chevron-down]="i != 0" [class.bx-chevron-up]="i == 0"></i>
                                            </div>
                                        </div>

                                        <div class="row quiz-answers-div cs-padding cs-animate-bottom" [class.cs-hide]="i != 0">
                                            <div class="col-lg-12 col-md-12 cs-padding-16">
                                                <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed" (click)="selectAnswer(quiz.id,1)">
                                                    <div class="col-1 mr10">
                                                        <button class="cs-button cs-circle cs-border  cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_1">A</button>
                                                    </div>
                                                    <div class="col-10 pt-2" style="padding-left: 30px;">
                                                        {{quiz.answer01}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 cs-padding-16">
                                                <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed" (click)="selectAnswer(quiz.id,2)">
                                                    <div class="col-1 mr10">
                                                        <button class="cs-button cs-circle cs-border cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_2">B</button>
                                                    </div>
                                                    <div class="col-10 pt-2" style="padding-left: 30px;">
                                                        {{quiz.answer02}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 cs-padding-16" (click)="selectAnswer(quiz.id,3)">
                                                <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed">
                                                    <div class="col-1 mr10">
                                                        <button class="cs-button cs-circle cs-border cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_3">C</button>
                                                    </div>
                                                    <div class="col-10 pt-2" style="padding-left: 30px;">
                                                        {{quiz.answer03}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 cs-padding-16" (click)="selectAnswer(quiz.id,4)">
                                                <div class="cursor_p row" [class.cs-hover-text-blue]="!levelPassed">
                                                    <div class="col-1 mr10">
                                                        <button class="cs-button cs-circle cs-border cs-small mr10 quiz-answers-button" id="QA_{{quiz.id}}_4">D</button>
                                                    </div>
                                                    <div class="col-10 pt-2" style="padding-left: 30px;">
                                                        {{quiz.answer04}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr>
                                    </div>

                                    <div style="align-content: center;" class="cs-padding-32 cs-hide" align='center' id="no_data">
                                        <img src="../../../../../../assets/img/illus/void.svg" alt="" style="width: 300px;height: auto;">
                                        <h3 style="padding-top: 50px;">{{lang_.txt_4}}</h3>
                                    </div>

                                    <div class="cs-padding-16" align='center'>
                                        <div id="resultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                            <div class="cs-display-middle" *ngIf="answersValidated">
                                                <h2>{{correctAnswersCount}}/3</h2>
                                            </div>
                                        </div>
                                        <div style="margin-top: 20px;" *ngIf="answersValidated">
                                            <div *ngIf="correctAnswersCount != 3">
                                                <h1>{{lang_.txt_5}}</h1>
                                                <p>{{lang_.txt_6}}</p>
                                                <div *ngIf="quizAttemptCount == 2">
                                                    <p><b>2<sup>nd</sup> {{lang_.txt_7}}</b></p>
                                                    <p>{{lang_.txt_8}}</p>
                                                </div>

                                                <p *ngIf="quizAttemptCount != 2"><b>{{lang_.txt_9}}</b> , {{lang_.txt_10}}</p>
                                            </div>
                                            <div *ngIf="correctAnswersCount == 3 && !levelPassed">
                                                <h1>{{lang_.txt_11}}</h1>
                                            </div>
                                            <div *ngIf="levelPassed">
                                                <h1>{{lang_.txt_12}}</h1>
                                                <p>{{lang_.txt_13}} <i class="bx bx-certification"></i></p>
                                            </div>
                                        </div>

                                    </div>


                                    <a class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" *ngIf="answersValidated && correctAnswersCount != 3 && quizAttemptCount == 2" href="learn-film-marking-with-dbima/module/{{moduleID}}/tutorial/{{lessonID}}">
                                        <i class='bx bx-left-arrow-circle icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_14}}</span>
                                        <i class="bx bx-left-arrow-circle icon-arrow after"></i>
                                    </a>

                                    <button class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" *ngIf="answersValidated && correctAnswersCount != 3 && quizAttemptCount != 2" (click)="validateAnswers()">
                                        <i class='bx bx-repost icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_15}}</span>
                                        <i class="bx bx-repost icon-arrow after"></i>
                                    </button>

                                    <button class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" *ngIf="!nodata && answersCount == 3 && !answersValidated" (click)="validateAnswers()">
                                        <i class='bx bx-paper-plane icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_16}}</span>
                                        <i class="bx bx-paper-plane icon-arrow after"></i>
                                    </button>

                                    <button disabled class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;cursor: not-allowed;" *ngIf="!nodata && answersCount != 3 && !answersValidated">
                                        <i class='bx bx-paper-plane icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_16}}</span>
                                        <i class="bx bx-paper-plane icon-arrow after"></i>
                                    </button>

                                    <a disabled class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" *ngIf="answersValidated && correctAnswersCount == 3 && !levelPassed" href="{{nextLessonURl}}">
                                        <i class='bx bx-book icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_17}} <i class='bx bx-right-arrow-circle'></i></span>
                                        <i class="bx bx-book icon-arrow after"></i>
                                    </a>

                                    <a disabled class="default-btn mb20 font_w_l cursor_p cs-right" style="border-radius: 50px;font-weight: lighter;" *ngIf="levelPassed" href="{{continueLessonURl}}">
                                        <i class='bx bx-book icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_18}}</span>
                                        <i class="bx bx-paper-plane icon-arrow after"></i>
                                    </a>

                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-lg-1"></div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>