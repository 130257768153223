import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {
    getCourseDetails,
    getUserStateOfProgramRegistration,
    registerToTheProgram,
    trackUserProgressState,
} from "../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { LanguageService } from 'src/app/services/language.service';
import { program_txt } from "./program_txt";
@Component({
    selector: "app-program",
    templateUrl: "./program.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramComponent implements OnInit {

    lang_: any;
    allLang_ = program_txt;
    langCode: string;
    LangCodeIndex: number;

    noData = true;
    loading = true;

    firstTimeInProgram;
    firstPartDataID;
    module_link: string;

    courseID = parseInt(localStorage.getItem('e-cademy_courseId'));
    language: string;

    courseData = new CourseClass();
    tutorialData;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserTutorialId: number;
    currentUserPartId: number;

    programCompleted = false;

    startingProgram = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) { }

    ngOnInit(): void {
        this.lang_loader();
        this.getCurrentUserData();
        if (this.currentUserName != null) {
            this.getUserStateOfProgramRegistration_function();
        }
        this.loadData();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
        }
        if (this.currentUserName != null) {
            this.language = userObj.language;
        } else {
            this.language = localStorage.getItem('e-cademy_lang');
        }
    }

    getUserStateOfProgramRegistration_function() {
        this.http
            .get<any>(
                environment.baseUrl + getUserStateOfProgramRegistration +"courseId="+this.courseID+"&userId="+ this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstTimeInProgram = false;
                        this.trackUserProgressState_function();
                    } else {
                        this.firstTimeInProgram = true;
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error!," + error.message,
                        0
                    );
                },
            });
    }

    loadData() {
        this.http
            .get<any>(
                environment.baseUrl +
                getCourseDetails +
                "language=" +
                this.language +
                "&courseId=" +
                this.courseID
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;

                    if (data.course) {
                        if (data.course.length != 0) {
                            this.noData = false;
                        }
                        this.loading = false;
                        this.tutorialData = data.tutorialList;
                        window.document.title =
                            this.courseData.courseName + " - Erasmus +";
                        this.firstPartDataID = data.partId;
                    } else {
                        this.noData = true;
                        this.loading = false;
                        this.openSnackBar(
                            "Something went wrong !",
                            0
                        );
                    }

                },
                error: (error) => {
                    this.noData = true;
                    this.loading = false;
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                trackUserProgressState +
                "userId=" +
                this.currentUserId +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );

                        this.firstTimeInProgram = false;
                        this.currentUserTutorialId = data.tutorialId;
                        this.currentUserPartId = data.partId;

                        if (data.percentage == 100) {
                            if (!data.exam01) {
                                this.module_link = "learn-film-marking-with-dbima/exam-mcq";
                            } else {
                                if (!data.exam02) {
                                    this.module_link = "learn-film-marking-with-dbima/exam-project";
                                } else {
                                    this.programCompleted = true;
                                    this.module_link = "learn-film-marking-with-dbima/dashboard";
                                }
                            }
                        } else {
                            this.module_link =
                                "learn-film-marking-with-dbima/module/" +
                                this.currentUserTutorialId;
                        }
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    registerToTheProgram() {
        this.startingProgram = true;
        this.http
            .post<any>(
                environment.baseUrl + registerToTheProgram,
                {
                    courseId: this.courseID,
                    noOfParts: 0,
                    userId: this.currentUserId,
                    completedParts: 0,
                    completeStatus: false,
                    certificateStatus: false,
                    exam01: 0,
                    exam02: 0,
                    tutorialId: this.tutorialData[0].id,
                    partId: this.firstPartDataID,
                },
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.module_link =
                            "learn-film-marking-with-dbima/module/" +
                            this.tutorialData[0].id;
                        this.router.navigate([this.module_link]);
                    } else {
                        this.startingProgram = false;
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                    this.startingProgram = false;
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
