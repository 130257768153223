import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// @ts-ignore
import {ApiResponse} from '../model/api-response';
import {fileUpload} from '../models/endpoints';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class UploadService {

    constructor(private http: HttpClient) {}

    uploadFile(file: any) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        // tslint:disable-next-line:max-line-length
        return this.http.post<ApiResponse>(environment.baseUrl + fileUpload, formData,  {headers: new HttpHeaders().set('Authorization',  localStorage.getItem('XJFYToken'))});
    }

}
