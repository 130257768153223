import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import {
    getAllModuleData,
    getQuizData,
    trackUserProgressState,
    updateUserCourseProgress,
    getTNPIdList,
    getUserStateOfProgramRegistration,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { TutorialClass } from "src/app/classes/TutorialClass";
import { LanguageService } from 'src/app/services/language.service';
import { quiz_txt } from "./quiz_txt";

declare var loadPieChart;
@Component({
    selector: "app-quiz",
    templateUrl: "./quiz.component.html",
    styleUrls: ["./quiz.component.scss"],
})
export class QuizComponent implements OnInit {

    lang_: any;
    allLang_ = quiz_txt;
    langCode: string;
    LangCodeIndex: number;

    noData = true;
    loading = true;

    url_data;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    courseID = parseInt(localStorage.getItem('e-cademy_courseId'));
    language;
    moduleID;
    lessonID;

    nodata = true;

    courseData = new CourseClass();
    tutorialData = new TutorialClass();
    tutorialAllData;
    partData;

    quizData;
    answersList;
    answersCount;
    answersValidated = false;
    validatedAnswerList = [
        {
            quiz_id: 0,
            state: false,
        },
        {
            quiz_id: 0,
            state: false,
        },
        {
            quiz_id: 0,
            state: false,
        },
    ];
    correctAnswersCount = 0;
    quizAttemptCount = 0;

    nextModuleID;
    nextPartID;
    nextISExam = false;
    nextLessonURl;

    tnpData;

    thisLastUnlockedLesson;
    thisPageIsUnlocked = false;

    levelPassed = false;
    continueLessonURl;

    all_quiz_completed = false;

    autoSaveInterval;

    firstTimeInProgram = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.lang_loader();
        this.moduleID = this.url_data.q_module_id;
        this.lessonID = this.url_data.q_lesson_id;
        this.getCurrentUserData();
    }


    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    //------------------------------ User Data

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.getUserStateOfProgramRegistration_function();
        }
    }

    getUserStateOfProgramRegistration_function() {
        this.http
            .get<any>(
                environment.baseUrl + getUserStateOfProgramRegistration + "courseId=" + this.courseID + "&userId=" + this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstTimeInProgram = false;
                        this.trackUserProgressState_function();
                    } else {
                        this.firstTimeInProgram = true;
                        this.router.navigate(["error/401"]);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error!," + error.message,
                        0
                    );
                },
            });
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                trackUserProgressState +
                "userId=" +
                this.currentUserId +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;
                        if (data.percentage == 100) {
                            this.all_quiz_completed = true;
                        }
                        this.accessCheckForThisPage();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }
    //---------------------------------------------------------------------------------------

    //------------------------------ Access Validators

    accessCheckForThisPage() {
        this.http
            .get<any>(environment.baseUrl + getTNPIdList, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    localStorage.getItem("XJFYToken")
                ),
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.tnpData = data.idList;
                        this.validateUserAccess();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    validateUserAccess() {
        var tnpDataArray = this.tnpData;
        var LastUnlockedModule = parseInt(
            localStorage.getItem("XJFYUserProgressModuleID")
        );

        var LastUnlockedLesson = parseInt(
            localStorage.getItem("XJFYUserProgressPartID")
        );

        this.thisLastUnlockedLesson = LastUnlockedLesson;
        var indexOfLastUnlockedTNP;

        for (let index = 0; index < tnpDataArray.length; index++) {
            tnpDataArray[index];
            if (
                tnpDataArray[index].tutorialId == LastUnlockedModule &&
                tnpDataArray[index].partId == LastUnlockedLesson
            ) {
                indexOfLastUnlockedTNP = index;
            }
        }

        for (let index = 0; index <= indexOfLastUnlockedTNP; index++) {
            tnpDataArray[index].unlocked = true;
            if (
                tnpDataArray[index].tutorialId == this.moduleID &&
                tnpDataArray[index].partId == this.lessonID
            ) {
                this.thisPageIsUnlocked = true;
                if (
                    LastUnlockedLesson != this.lessonID ||
                    this.all_quiz_completed
                ) {
                    this.levelPassed = true;
                }
            }
        }

        if (!this.thisPageIsUnlocked) {
            this.router.navigate(["error/401"]);
        } else {
            this.loadData();
        }
    }

    //---------------------------------------------------------------------------------------

    loadData() {
        this.language = this.langCode;

        this.http
            .get<any>(
                environment.baseUrl +
                getAllModuleData +
                "language=" +
                this.language +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                    this.tutorialAllData = data.tutorialDtos;

                    if (data.tutorialDtos.length != 0) {
                        this.noData = false;
                    }
                    this.loading = false;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        if (
                            this.tutorialAllData[index].tutorial.id ==
                            this.moduleID
                        ) {
                            this.partData = this.tutorialAllData[index].parts;
                            this.tutorialData =
                                this.tutorialAllData[index].tutorial;
                        }
                    }

                    var tnpDataArray = this.tnpData;
                    for (
                        let index3 = 0;
                        index3 < tnpDataArray.length;
                        index3++
                    ) {
                        if (
                            tnpDataArray[index3].tutorialId == this.moduleID &&
                            tnpDataArray[index3].partId == this.lessonID
                        ) {
                            if (index3 + 1 != tnpDataArray.length) {
                                this.nextModuleID =
                                    tnpDataArray[index3 + 1].tutorialId;
                                this.nextPartID =
                                    tnpDataArray[index3 + 1].partId;
                                this.nextISExam = false;
                            } else {
                                this.nextModuleID = this.moduleID;
                                this.nextPartID = this.lessonID;
                                this.nextISExam = true;
                            }
                        }
                    }

                    if (this.levelPassed) {
                        this.autoSaveInterval = setTimeout(() => {
                            this.setAnswers();
                        }, 1000);
                    }
                },
                error: (error) => {
                    this.noData = true;
                    this.loading = false;
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });

        this.http
            .get<any>(environment.baseUrl + getQuizData + this.lessonID, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    this.currentUserToken
                ),
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.nodata = false;
                        this.quizData = data.quizList;
                    } else {
                        this.nodata = true;
                        document
                            .getElementById("no_data")
                            .classList.remove("cs-hide");
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    toggleAnswerDivView(q_parent_element, hide) {
        var a_div =
            q_parent_element.getElementsByClassName("quiz-answers-div")[0];
        var icon = q_parent_element.getElementsByClassName("toggleIcon")[0];

        if (hide) {
            a_div.classList.remove("cs-hide");
            icon.classList.remove("bx-chevron-down");
            icon.classList.add("bx-chevron-up");
        } else {
            a_div.classList.add("cs-hide");
            icon.classList.add("bx-chevron-down");
            icon.classList.remove("bx-chevron-up");
        }
    }

    toggleAnswerDiv(element) {
        var q_parent_element = document.getElementById(
            "Q_" + element
        ).parentElement;

        var hide = q_parent_element
            .getElementsByClassName("quiz-answers-div")[0]
            .classList.contains("cs-hide");

        this.hideAllAnswerDives();

        this.toggleAnswerDivView(q_parent_element, hide);
    }

    selectAnswer(Q_id, A_id) {
        if (!this.levelPassed) {
            var button = document.getElementById("QA_" + Q_id + "_" + A_id);
            var quiz_answers_div =
                button.parentElement.parentElement.parentElement.parentElement;
            var quiz_answers_button = quiz_answers_div.getElementsByClassName(
                "quiz-answers-button"
            );
            for (let index = 0; index < quiz_answers_button.length; index++) {
                quiz_answers_button[index].classList.remove("cs-blue");
            }
            button.classList.add("cs-blue");

            this.storeAnswers();
        }
    }

    storeAnswers() {
        var quiz = document.getElementById("Quiz");
        var answers = quiz.getElementsByClassName("cs-blue");

        var answersArray = new Array();

        for (let index = 0; index < answers.length; index++) {
            var q_a = answers[index].id.replace("QA_", "").split("_");
            answersArray[index] = {
                q_id: q_a[0],
                answer: q_a[1],
            };
        }
        this.answersList = answersArray;
        this.answersCount = answersArray.length;
    }

    validateAnswers() {
        this.quizAttemptCount++;
        this.answersValidated = true;
        var validatedAnswers = new Array();

        for (let index = 0; index < this.answersList.length; index++) {
            var x_q_id = this.answersList[index].q_id;
            var x_answer = this.answersList[index].answer;

            for (let index2 = 0; index2 < this.quizData.length; index2++) {
                var db_q_id = this.quizData[index2].id;
                var db_answer = this.quizData[index2].answerSequence;
                var answer_state;

                if (x_q_id == db_q_id) {
                    if (x_answer == db_answer) {
                        answer_state = true;
                    } else {
                        answer_state = false;
                    }
                    validatedAnswers[index] = {
                        quiz_id: db_q_id,
                        x_answer: x_answer,
                        db_answer: db_answer,
                        state: answer_state,
                    };
                    this.validatedAnswerList[index] = {
                        quiz_id: db_q_id,
                        state: answer_state,
                    };
                }
            }
        }

        this.hideAllAnswerDives();

        this.callToLoadPieChart(
            "resultChart",
            this.validatedAnswerList[0].state,
            this.validatedAnswerList[1].state,
            this.validatedAnswerList[2].state
        );

        this.correctAnswersCount = 0;
        for (let index = 0; index < this.validatedAnswerList.length; index++) {
            if (this.validatedAnswerList[index].state) {
                this.correctAnswersCount++;
            }
        }

        if (this.correctAnswersCount == 3) {
            this.updateUserCourseProgress_fun();
        }
    }

    hideAllAnswerDives() {
        var quiz_div = document.getElementById("Quiz");
        var a_div = quiz_div.getElementsByClassName("quiz-answers-div");
        var toggleIcon = quiz_div.getElementsByClassName("toggleIcon");

        for (let index = 0; index < a_div.length; index++) {
            a_div[index].classList.add("cs-hide");
        }
        for (let index = 0; index < toggleIcon.length; index++) {
            toggleIcon[index].classList.add("bx-chevron-down");
            toggleIcon[index].classList.remove("bx-chevron-up");
        }
    }

    callToLoadPieChart(id, q1a, q2a, q3a) {
        loadPieChart(id, q1a, q2a, q3a);
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    updateUserCourseProgress_fun() {
        this.http
            .get<any>(
                environment.baseUrl +
                updateUserCourseProgress +
                "userId=" +
                this.currentUserId +
                "&courseId=" +
                this.courseID +
                "&tutorialId=" +
                this.nextModuleID +
                "&partId=" +
                this.nextPartID +
                "",
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.nextLessonURl =
                            "learn-film-marking-with-dbima/module/" +
                            this.nextModuleID +
                            "/tutorial/" +
                            this.nextPartID +
                            "";
                    } else {
                        this.openSnackBar(data.message, 1);
                    }
                    this.openSnackBar(data.message, 1);
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    setAnswers() {
        this.answersValidated = true;
        this.correctAnswersCount = 3;
        this.callToLoadPieChart("resultChart", true, true, true);

        for (let index = 0; index < this.quizData.length; index++) {
            var Q_id = this.quizData[index].id;
            var Q_A_id = this.quizData[index].answerSequence;
            document
                .getElementById("QA_" + Q_id + "_" + Q_A_id + "")
                .classList.add("cs-green");
        }
        var LastUnlockedModule = parseInt(
            localStorage.getItem("XJFYUserProgressModuleID")
        );

        var LastUnlockedLesson = parseInt(
            localStorage.getItem("XJFYUserProgressPartID")
        );
        if (this.nextISExam) {
            this.continueLessonURl = "/learn-film-marking-with-dbima/exam-mcq";
        } else {
            this.continueLessonURl =
                "/learn-film-marking-with-dbima/module/" +
                LastUnlockedModule +
                "/tutorial/" +
                LastUnlockedLesson +
                "";
        }

        this.hideAllAnswerDives();
    }
}
