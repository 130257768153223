export const dashboard_txt = [
    {
        txt_1: 'Dashboard',
        txt_2: 'Program Overview',
        txt_3: 'Main Tutorial Topics',
        txt_4: 'Tutorials',
        txt_5: 'Quiz',
        txt_6: 'Exams',
        txt_7: 'Exam 01',
        txt_8: 'Exam 02',
        txt_9: 'Certificate',
        txt_10: 'Start Tutorials',
        txt_11: 'Locked',
        txt_12: 'You Have to Complete all the tutorial Quizzes to get access to the Exam 01',
        txt_13: 'Unlocked',
        txt_14: 'You Have to unlocked the Exam 01 go for it, Best of luck! ',
        txt_15: 'Attend Exam 01',
        txt_16: 'You Have Completed the Exam',
        txt_17: 'Continue the program and get Certified',
        txt_18: 'Continue to Exam 02',
        txt_19: 'You Have Completed the Program',
        txt_20: 'Get your Certificate Now !',
        txt_21: 'You Have to Complete Exam 01 to get access to the Exam 02',
        txt_22: 'You Have unlocked the Exam 02 Follow the Instruction , Best of luck! ',
        txt_23: 'Exam 02 Instruction',
        txt_24: 'Result Pending',
        txt_25: 'You Have Submitted your Project URL, Your Results Will be Released Soon! ',
        txt_26: 'Your Project URL',
        txt_27: 'You Have to Complete All the Quizzes and Exam to get access to the Certificate',
        txt_28: 'Get your Certificate',
        txt_29: 'Sending request',
        txt_30: 'Certificate Locked',
        txt_31: 'You Are missing Some Data in Your Profile that is Required for generate Certificate',
        txt_32: 'Update Missing Data in your Profile',
        txt_33: 'Update Profile'
    },
    {
        txt_1: 'Tableau de bord',
        txt_2: 'Aperçu du programme',
        txt_3: 'Principaux sujets du tutoriel',
        txt_4: 'Tutoriels',
        txt_5: 'Quiz',
        txt_6: 'Examens',
        txt_7: 'Examen 01',
        txt_8: 'Examen 02',
        txt_9: 'Certificat',
        txt_10: 'Tutoriels de départ',
        txt_11: 'Verrouillé',
        txt_12: 'Vous devez compléter tous les questionnaires du tutoriel pour avoir accès à l\'examen 01.',
        txt_13: 'Déverrouillé',
        txt_14: 'Vous devez avoir débloqué l\'examen 01, allez-y, bonne chance !',
        txt_15: 'Assister à l\'examen 01',
        txt_16: 'Vous avez terminé l\'examen',
        txt_17: 'Poursuivre le programme et obtenir la certification',
        txt_18: 'Passer à l\'examen 02',
        txt_19: 'Vous avez terminé le programme',
        txt_20: 'Obtenez votre certificat maintenant !',
        txt_21: 'Vous devez avoir complété l\'examen 01 pour avoir accès à l\'examen 02',
        txt_22: 'Vous avez débloqué l\'examen 02 Suivez les instructions, Bonne chance !',
        txt_23: 'Examen 02 Instruction',
        txt_24: 'Résultat en attente',
        txt_25: 'Vous avez soumis l\'URL de votre projet, vos résultats seront bientôt publiés !',
        txt_26: 'L\'URL de votre projet',
        txt_27: 'Vous devez compléter tous les quiz et examens pour avoir accès au certificat.',
        txt_28: 'Obtenez votre certificat',
        txt_29: 'Envoi de la demande',
        txt_30: 'Certificat verrouillé',
        txt_31: 'Il vous manque certaines données dans votre profil qui sont nécessaires pour générer un certificat.',
        txt_32: 'Mettez à jour les données manquantes dans votre profil',
        txt_33: 'Mise à jour du profil'
    },
    {
        txt_1: 'Tablero de mandos',
        txt_2: 'Resumen del programa',
        txt_3: 'Principales temas del tutorial',
        txt_4: 'Tutoriales',
        txt_5: 'Cuestionario',
        txt_6: 'Exámenes',
        txt_7: 'Examen 01',
        txt_8: 'Examen 02',
        txt_9: 'Certificado',
        txt_10: 'Inicio Tutoriales',
        txt_11: 'Bloqueado',
        txt_12: 'Tienes que completar todos los cuestionarios del tutorial para acceder al examen 01',
        txt_13: 'Desbloqueado',
        txt_14: 'Tienes que desbloquear el examen 01 ir a por ello, ¡mucha suerte!',
        txt_15: 'Asistir al examen 01',
        txt_16: 'Ha completado el examen',
        txt_17: 'Continuar el programa y obtener la certificación',
        txt_18: 'Continuar con el examen 02',
        txt_19: 'Ha completado el programa',
        txt_20: '¡Obtenga su certificado ahora!',
        txt_21: 'Tienes que completar el examen 01 para acceder al examen 02',
        txt_22: 'Ha desbloqueado el examen 02 Siga las instrucciones, ¡mucha suerte!',
        txt_23: 'Examen 02 Instrucción',
        txt_24: 'Resultado pendiente',
        txt_25: 'Ha enviado la URL de su proyecto, ¡sus resultados se publicarán pronto! ',
        txt_26: 'La URL de su proyecto',
        txt_27: 'Tienes que completar todos los cuestionarios y el examen para acceder al certificado',
        txt_28: 'Obtenga su certificado',
        txt_29: 'Envío de la solicitud',
        txt_30: 'Certificado bloqueado',
        txt_31: 'Le faltan algunos datos en su perfil que son necesarios para generar el certificado',
        txt_32: 'Actualice los datos que faltan en su perfil',
        txt_33: 'Actualizar el perfil'
    },
    {
        txt_1: 'Műszerfal',
        txt_2: 'A program áttekintése',
        txt_3: 'Főbb oktatói témák',
        txt_4: 'Tutorials',
        txt_5: 'Kvíz',
        txt_6: 'Vizsgák',
        txt_7: '01-es vizsga',
        txt_8: '02-es vizsga',
        txt_9: 'Tanúsítvány',
        txt_10: 'Start oktatóanyagok',
        txt_11: 'Zárva',
        txt_12: 'Ki kell töltened az összes oktatói kvízt, hogy hozzáférj a vizsgához 01',
        txt_13: 'Feloldott',
        txt_14: 'Meg kell feloldani a vizsga 01 megy érte, Sok szerencsét! ',
        txt_15: 'Vizsgán való részvétel 01',
        txt_16: 'Teljesítette a vizsgát',
        txt_17: 'Folytassa a programot és szerezzen tanúsítványt',
        txt_18: 'Tovább a 02. vizsgára',
        txt_19: 'Ön befejezte a programot',
        txt_20: 'Szerezd meg a tanúsítványt most !',
        txt_21: 'A 01-es vizsgát ki kell töltenie, hogy hozzáférjen a 02-es vizsgához.',
        txt_22: 'Ön feloldotta a vizsga 02 Kövesse az utasítást , Sok szerencsét! ',
        txt_23: 'Vizsga 02 Utasítás',
        txt_24: 'Eredmény függőben',
        txt_25: 'Ön elküldte a projekt URL címét, az eredményeket hamarosan közzétesszük! ',
        txt_26: 'A projekt URL címe',
        txt_27: 'Ki kell töltenie az összes kvízt és vizsgát, hogy hozzáférjen a tanúsítványhoz.',
        txt_28: 'Szerezze be a tanúsítványt',
        txt_29: 'Kérés küldése',
        txt_30: 'Tanúsítvány zárolva',
        txt_31: 'Hiányzik néhány adat a profiljából, amely szükséges a tanúsítvány létrehozásához',
        txt_32: 'Hiányzó adatok frissítése a profiljában',
        txt_33: 'Profil frissítése'
    },
    {
        txt_1: 'Информационно табло',
        txt_2: 'Преглед на програмата',
        txt_3: 'Основни теми на урока',
        txt_4: 'Наръчници',
        txt_5: 'Викторина',
        txt_6: 'Изпити',
        txt_7: 'Изпит 01',
        txt_8: 'Изпит 02',
        txt_9: 'Сертификат',
        txt_10: 'Начални уроци',
        txt_11: 'Заключен',
        txt_12: 'За да получите достъп до изпита, трябва да завършите всички викторини от урока 01',
        txt_13: 'Отключен',
        txt_14: 'Трябва да отключите изпита 01 отидете за него, Късмет!',
        txt_15: 'Присъствие на изпит 01',
        txt_16: 'Завършили сте изпита',
        txt_17: 'Продължете програмата и се сертифицирайте',
        txt_18: 'Продължете към изпит 02',
        txt_19: 'Завършили сте програмата',
        txt_20: 'Получете своя сертификат сега!',
        txt_21: 'Трябва да завършите изпит 01, за да получите достъп до изпит 02',
        txt_22: 'Отключили сте изпита 02 Следвайте инструкциите , Успех! ',
        txt_23: 'Изпит 02 Инструкция',
        txt_24: 'Резултат в очакване',
        txt_25: 'Изпратихте URL адреса на проекта си, резултатите ще бъдат публикувани скоро!',
        txt_26: 'Вашият URL адрес на проекта',
        txt_27: 'Трябва да завършите всички тестове и изпити, за да получите достъп до сертификата',
        txt_28: 'Получете своя сертификат',
        txt_29: 'Изпращане на заявка',
        txt_30: 'Сертификатът е заключен',
        txt_31: 'Липсват някои данни в профила ви, които са необходими за генериране на сертификат',
        txt_32: 'Актуализиране на липсващи данни в профила ви',
        txt_33: 'Актуализиране на профила'
    }
];


