export const lesson_txt = [
    {
        txt_1: 'Dashboard',
        txt_2: 'VIEW TRANSCRIPT',
        txt_3: 'HIDE TRANSCRIPT',
        txt_4: 'Check your knowledge',
        txt_5: 'Key learning',
    },
    {
        txt_1: 'Tableau de bord',
        txt_2: 'VOIR LA TRANSCRIPTION',
        txt_3: 'CACHER LA TRANSCRIPTION',
        txt_4: 'Vérifiez vos connaissances',
        txt_5: 'Principaux enseignements',
    },
    {
        txt_1: 'Tablero de mandos',
        txt_2: 'VER TRANSCRIPCIÓN',
        txt_3: 'OCULTAR TRANSCRIPCIÓN',
        txt_4: 'Compruebe sus conocimientos',
        txt_5: 'Aprendizaje clave',
    },
    {
        txt_1: 'Műszerfal',
        txt_2: 'ÁTIRAT MEGTEKINTÉSE',
        txt_3: 'ÁTIRAT ELREJTÉSE',
        txt_4: 'Ellenőrizze tudását',
        txt_5: 'A legfontosabb tanulságok',
    },
    {
        txt_1: 'Информационно табло',
        txt_2: 'ПРЕГЛЕД НА СТЕНОГРАМАТА',
        txt_3: 'СКРИВАНЕ НА СТЕНОГРАМАТА',
        txt_4: 'Проверете знанията си',
        txt_5: 'Основни познания',
    }
];


