import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {loginUrl} from '../models/endpoints';
import {Auth} from '../classes/auth';
import {ApiResponse} from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  loginUser(auth: Auth) {
    return this.http.post<ApiResponse>(environment.baseUrl + loginUrl, auth);
  }
}
