export const examTwo_txt = [
    {
        txt_1: 'Exam 02',
        txt_2: 'Dashboard',
        txt_3: 'Module Overview',
        txt_4: 'Exams',
        txt_5: 'Exam 01',
        txt_6: 'Quiz',
        txt_7: 'Congratulation…',
        txt_8: 'for your successful completion of validating all the ',
        txt_9: '12 tutorial videos and the part 1 of the final exam ',
        txt_10: 'Filmmaking for the beginners - First Level',
        txt_11: 'To get certified from Don Bosco International Media Academy, you need to do the following task:',
        txt_12: 'Make a less than 3 minutes film about “YOU” or “Any theme that you like”',
        txt_13: 'Important',
        txt_14: 'Write a Pitch',
        txt_15: 'Title of your film: “Give a name of your choice”',
        txt_16: 'Logline: One line which explains your idea',
        txt_17: 'Synopsis: Write a 5 to 7 lines which gives the short story',
        txt_18: 'Why you want to make this film?',
        txt_19: 'Production',
        txt_20: 'Make your film (Less than 3 minutes, including credits)',
        txt_21: 'Download the exam submission document template and update your answers.',
        txt_22: 'Once completed upload the submission document for the review and get certified.',
        txt_23: 'Download Template',
        txt_24: 'Result Pending',
        txt_25: 'You Have Submitted the Project Link',
        txt_26: 'Your Results Will be Released Soon!',
        txt_27: 'You Have Completed the Program',
        txt_28: 'Get your Certificate Now !',
        txt_29: 'Please select the file',
    },
    {
        txt_1: 'Examen 01',
        txt_2: 'Tableau de bord',
        txt_3: 'Aperçu du module',
        txt_4: 'Examens',
        txt_5: 'Examen 02',
        txt_6: 'Quiz',
        txt_7: 'Félicitations...',
        txt_8: 'pour votre réussite dans la validation de tous les ',
        txt_9: '12 vidéos tutorielles et la partie 1 de l\'examen final ',
        txt_10: 'Réalisation de films pour les débutants - premier niveau',
        txt_11: 'Pour obtenir la certification de la Don Bosco International Media Academy, vous devez accomplir la tâche suivante :',
        txt_12: 'Réalisez un film de moins de 3 minutes sur "VOUS" ou "tout autre thème que vous aimez".',
        txt_13: 'Important',
        txt_14: 'Rédiger un argumentaire',
        txt_15: 'Titre de votre film : "Donnez un nom de votre choix"',
        txt_16: 'Logline : Une ligne qui explique votre idée',
        txt_17: 'Synopsis : Rédigez un texte de 5 à 7 lignes qui présente la nouvelle.',
        txt_18: 'Pourquoi avez-vous voulu faire ce film ?',
        txt_19: 'Production',
        txt_20: 'Réalisez votre film (moins de 3 minutes, générique compris)',
        txt_21: 'Téléchargez le modèle de document de soumission d\'examen et mettez à jour vos réponses.',
        txt_22: 'Une fois terminé, téléchargez le document de soumission pour l\'examen et obtenez la certification.',
        txt_23: 'Télécharger le modèle',
        txt_24: 'Résultat en attente',
        txt_25: 'Vous avez soumis le lien du projet',
        txt_26: 'Vos résultats seront bientôt publiés !',
        txt_27: 'Vous avez terminé le programme',
        txt_28: 'Obtenez votre certificat maintenant !',
        txt_29: 'Veuillez sélectionner le fichier',
    },
    {
        txt_1: 'Examen 01',
        txt_2: 'Tablero de mandos',
        txt_3: 'Resumen del módulo',
        txt_4: 'Exámenes',
        txt_5: 'Examen 02',
        txt_6: 'Cuestionario',
        txt_7: 'Enhorabuena...',
        txt_8: 'por haber completado con éxito la validación de todos los ',
        txt_9: '12 vídeos tutoriales y la parte 1 del examen final ',
        txt_10: 'Realización de películas para principiantes - Primer nivel',
        txt_11: 'Para obtener la certificación de la Academia Internacional de Medios de Comunicación Don Bosco, es necesario realizar la siguiente tarea:',
        txt_12: 'Haz una película de menos de 3 minutos sobre "TÚ" o "Cualquier tema que te guste"',
        txt_13: 'Importante',
        txt_14: 'Redactar una propuesta',
        txt_15: 'Título de su película: "Pon un nombre de tu elección"',
        txt_16: 'Logline: Una línea que explique su idea',
        txt_17: 'Sinopsis: Escriba de 5 a 7 líneas que den cuenta de la historia corta',
        txt_18: '¿Por qué quiere hacer esta película?',
        txt_19: 'Producción',
        txt_20: 'Haga su película (menos de 3 minutos, incluyendo los créditos)',
        txt_21: 'Descargue la plantilla del documento de presentación del examen y actualice sus respuestas.',
        txt_22: 'Una vez completado, suba el documento de presentación para la revisión y obtenga la certificación.',
        txt_23: 'Descargar plantilla',
        txt_24: 'Resultado pendiente',
        txt_25: 'You Have Submitted the Project Link',
        txt_26: 'Ha enviado el enlace del proyecto',
        txt_27: 'Ha completado el programa',
        txt_28: '¡Obtenga su certificado ahora!',
        txt_29: 'Seleccione el archivo',
    },
    {
        txt_1: '01-es vizsga',
        txt_2: 'Műszerfal',
        txt_3: 'Modul áttekintése',
        txt_4: 'Vizsgák',
        txt_5: '02-es vizsga',
        txt_6: 'Kvíz',
        txt_7: 'Gratulálok...',
        txt_8: 'a sikeres befejezéséhez, hogy érvényesítse az összes ',
        txt_9: '12 oktatóvideó és a záróvizsga 1. része ',
        txt_10: 'Filmkészítés kezdőknek - Első szint',
        txt_11: 'A Don Bosco Nemzetközi Médiaakadémia tanúsítványának megszerzéséhez a következő feladatot kell elvégeznie:',
        txt_12: 'Készíts egy 3 percnél rövidebb filmet "TE" vagy "Bármilyen téma, ami tetszik".',
        txt_13: 'Fontos',
        txt_14: 'Pitch írása',
        txt_15: 'A film címe: "Adj egy tetszőleges nevet"',
        txt_16: 'Logline: Egy sor, amely kifejezi az ötletet',
        txt_17: 'Szinopszis: Írj egy 5-7 sort, amely megadja a rövid történetet.',
        txt_18: 'Miért akarja elkészíteni ezt a filmet?',
        txt_19: 'Termelés',
        txt_20: 'Készítse el filmjét (3 percnél rövidebb, a stáblistával együtt)',
        txt_21: 'Töltse le a vizsgadokumentum sablonját, és frissítse válaszait.',
        txt_22: 'Ha elkészült, töltse fel a benyújtási dokumentumot a felülvizsgálatra, és szerezzen tanúsítványt.',
        txt_23: 'Sablon letöltése',
        txt_24: 'Eredmény függőben',
        txt_25: 'Ön elküldte a projekt linkjét',
        txt_26: 'Eredményeidet hamarosan közzétesszük!',
        txt_27: 'Ön befejezte a programot',
        txt_28: 'Szerezd meg a tanúsítványt most !',
        txt_29: 'Kérjük, válassza ki a fájlt',
    },
    {
        txt_1: 'Изпит 01',
        txt_2: 'Информационно табло',
        txt_3: 'Преглед на модула',
        txt_4: 'Изпити',
        txt_5: 'Изпит 02',
        txt_6: 'Викторина',
        txt_7: 'Поздравления...',
        txt_8: 'за успешното завършване на валидирането на всички ',
        txt_9: '12 видеоклипа с уроци и част 1 от финалния изпит ',
        txt_10: 'Създаване на филми за начинаещи - Първо ниво',
        txt_11: 'За да получите сертификат от Международната медийна академия "Дон Боско", трябва да изпълните следните задачи:',
        txt_12: 'Направете филм за по-малко от 3 минути за "ВАС" или "Всяка тема, която ви харесва"',
        txt_13: 'Важни',
        txt_14: 'Напишете предложение',
        txt_15: 'Заглавие на вашия филм: "Дайте име по ваш избор"',
        txt_16: 'Логлайн: Един ред, който обяснява вашата идея',
        txt_17: 'Синопсис: Напишете от 5 до 7 реда, които дават кратка история',
        txt_18: 'Защо искате да снимате този филм?',
        txt_19: 'Производство',
        txt_20: 'Създайте своя филм (по-малко от 3 минути, включително надписите)',
        txt_21: 'Изтеглете шаблона на документа за подаване на изпита и актуализирайте отговорите си.',
        txt_22: 'След като приключите, качете документа за преглед и получете сертификат.',
        txt_23: 'Изтегляне на шаблона',
        txt_24: 'Резултат в очакване',
        txt_25: 'Изпратили сте връзката на проекта',
        txt_26: 'Вашите резултати ще бъдат публикувани скоро!',
        txt_27: 'Завършили сте програмата',
        txt_28: 'Получете своя сертификат сега!',
        txt_29: 'Моля, изберете файла',
    },
];


