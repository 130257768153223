<div class="dropdown">
    <button class="dropbtn" *ngIf="langCode == 'E'" [class.cu-cursor-def]="userLoggedIn">
        <img src="assets/img/flags/eng.svg" class="shadow" alt="image">
        <span style="margin-left: 10px">English<i class='bx bx-chevron-down' *ngIf="!userLoggedIn"></i></span>
    </button>
    <button class="dropbtn" *ngIf="langCode == 'F'">
        <img src="assets/img/flags/fre.svg" class="shadow" alt="image">
        <span style="margin-left: 10px">French <i class='bx bx-chevron-down' *ngIf="!userLoggedIn"></i></span>
    </button>
    <button class="dropbtn" *ngIf="langCode == 'S'">
        <img src="assets/img/flags/spa.svg" class="shadow" alt="image">
        <span style="margin-left: 10px">Spanish <i class='bx bx-chevron-down' *ngIf="!userLoggedIn"></i></span>
    </button>
    <button class="dropbtn" *ngIf="langCode == 'H'">
        <img src="assets/img/flags/hun.svg" class="shadow" alt="image">
        <span style="margin-left: 10px">Hungarian <i class='bx bx-chevron-down' *ngIf="!userLoggedIn"></i></span>
    </button>
    <button class="dropbtn" *ngIf="langCode == 'B'">
        <img src="assets/img/flags/bul.svg" class="shadow" alt="image">
        <span style="margin-left: 10px">Bulgarian <i class='bx bx-chevron-down' *ngIf="!userLoggedIn"></i></span>
    </button>
    <div class="dropdown-content" *ngIf="!userLoggedIn">
        <div *ngFor="let language of languageList">
            <a (click)="lang_selector('E',language.courseId)" class="cursor_p" [class.cs-pale-optionalColor]="langCode == 'E'" *ngIf="language.code == 'E'">
                <img src="assets/img/flags/eng.svg" class="shadow" alt="image">
                <span style="margin-left: 10px">English</span>
            </a>
        </div>

        <div *ngFor="let language of languageList">
            <a (click)="lang_selector('F',language.courseId)" class="cursor_p" [class.cs-pale-optionalColor]="langCode == 'F'" *ngIf="language.code == 'F'">
                <img src="assets/img/flags/fre.svg" class="shadow-sm" alt="flag">
                <span style="margin-left: 10px">French</span>
            </a>
        </div>

        <div *ngFor="let language of languageList">
            <a (click)="lang_selector('S',language.courseId)" class="cursor_p" [class.cs-pale-optionalColor]="langCode == 'S'" *ngIf="language.code == 'S'">
                <img src="assets/img/flags/spa.svg" class="shadow-sm" alt="flag">
                <span style="margin-left: 10px">Spanish</span>
            </a>
        </div>

        <div *ngFor="let language of languageList">
            <a (click)="lang_selector('H',language.courseId)" class="cursor_p" [class.cs-pale-optionalColor]="langCode == 'H'" *ngIf="language.code == 'H'">
                <img src="assets/img/flags/hun.svg" class="shadow-sm" alt="flag">
                <span style="margin-left: 10px">Hungarian</span>
            </a>
        </div>

        <div *ngFor="let language of languageList">
            <a (click)="lang_selector('B',language.courseId)" class="cursor_p" [class.cs-pale-optionalColor]="langCode == 'B'" *ngIf="language.code == 'B'">
                <img src="assets/img/flags/bul.svg" class="shadow-sm" alt="flag">
                <span style="margin-left: 10px">Bulgarian</span>
            </a>
        </div>

    </div>
</div>