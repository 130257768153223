
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { StickyNavModule } from 'ng2-sticky-nav';
import { LightboxModule } from 'ngx-lightbox';
import { FormGroupName, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';

import { HttpClientModule } from '@angular/common/http';

//-----common----->>>>>>>>>
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LoaderComponent } from './components/common/other/loader/loader.component';
import { DataNotFoundComponent } from './components/common/other/data-not-found/data-not-found.component';
import { OurPartnersComponent } from './components/common/our-partners/our-partners.component';
import { FeedbackDisplayComponent } from './components/common/feedback-display/feedback-display.component';
import { ModuleNavigationComponent } from './components/common/module-navigation/module-navigation.component';

//-----layout----->>>>>>>>>
import { IframeLoaderComponent } from './components/layout/iframe-loader/iframe-loader.component';

//-----pages----->>>>>>>>>
import { HomeComponent } from './components/pages/home/home.component';
import { MainBannerComponent } from './components/pages/home/main-banner/main-banner.component';

import { RegisterComponent } from './components/pages/auth/register/register.component';
import { LoginComponent } from './components/pages/auth/login/login.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';

import { ContactComponent } from './components/pages/company/contact/contact.component';

import { MyDashboardComponent } from './components/pages/my-dashboard/my-dashboard.component';

import { DashboardComponent } from './components/pages/program/dashboard/dashboard.component';
import { ProgramComponent } from './components/pages/program/program.component';
import { ModuleComponent } from './components/pages/program/module/module.component';
import { LessonComponent } from './components/pages/program/lesson/lesson.component';
import { QuizComponent } from './components/pages/program/quiz/quiz.component';
import { ExamOneComponent } from './components/pages/program/exam-one/exam-one.component';
import { ExamTwoComponent } from './components/pages/program/exam-two/exam-two.component';

import { CertificationVerifyComponent } from './components/pages/certification-verify/certification-verify.component';

import { FeedbackComponent } from './components/pages/company/feedback/feedback.component';

import { ErrorComponent } from './components/other/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/company/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/pages/company/terms-and-conditions/terms-and-conditions.component';
import { LanguageChangerComponent } from './components/common/language-changer/language-changer.component';

@NgModule({
  declarations: [
    AppComponent,

    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    DataNotFoundComponent,
    OurPartnersComponent,
    FeedbackDisplayComponent,
    ModuleNavigationComponent,

    IframeLoaderComponent,

    HomeComponent,
    MainBannerComponent,

    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,

    ContactComponent,

    MyDashboardComponent,

    DashboardComponent,
    ProgramComponent,
    ModuleComponent,
    LessonComponent,
    QuizComponent,
    ExamOneComponent,
    ExamTwoComponent,

    CertificationVerifyComponent,

    FeedbackComponent,

    ErrorComponent,
     PrivacyPolicyComponent,
     TermsAndConditionsComponent,
     LanguageChangerComponent,
  ],
  imports: [
    MatSnackBarModule,
    MatSelectModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    TabsModule,
    NgxScrollTopModule,
    StickyNavModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    MatSnackBarModule,
    MatSelectModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
