import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { getAllModuleData, getTNPIdList, getUserStateOfProgramRegistration, trackUserProgressState } from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, ActivatedRoute } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { TutorialClass } from "src/app/classes/TutorialClass";
import { LanguageService } from 'src/app/services/language.service';
import { module_txt } from "./module_txt";
@Component({
    selector: "app-module",
    templateUrl: "./module.component.html",
    styleUrls: ["./module.component.scss"],
})
export class ModuleComponent implements OnInit {

    lang_: any;
    allLang_ = module_txt;
    langCode: string;
    LangCodeIndex: number;

    noData = true;
    loading = true;

    url_data;

    courseID = parseInt(localStorage.getItem('e-cademy_courseId'));
    language;
    moduleID;

    courseData = new CourseClass();
    tutorialData = new TutorialClass();
    tutorialAllData;
    partData;

    partCount;

    tnpData;

    thisLastUnlockedLesson;
    thisPageIsUnlocked = false;

    firstTimeInProgram = true;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
    }

    ngOnInit(): void {
        this.moduleID = this.url_data.m_module_id;
        this.getCurrentUserData();
        this.lang_loader();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    //------------------------------ Access Validators

    getCurrentUserData() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var token = localStorage.getItem("XJFYToken");
        var userObj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + " " + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.getUserStateOfProgramRegistration_function();
        }
    }

    getUserStateOfProgramRegistration_function() {
        this.http
            .get<any>(
                environment.baseUrl + getUserStateOfProgramRegistration + "courseId=" + this.courseID + "&userId=" + this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstTimeInProgram = false;
                        this.accessCheckForThisPage();
                    } else {
                        this.firstTimeInProgram = true;
                        this.router.navigate(["error/401"]);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error!," + error.message,
                        0
                    );
                },
            });
    }

    accessCheckForThisPage() {
        this.http
            .get<any>(environment.baseUrl + getTNPIdList, {
                headers: new HttpHeaders().set(
                    "Authorization",
                    localStorage.getItem("XJFYToken")
                ),
            })
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.tnpData = data.idList;
                        this.trackUserProgressState_function();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }


    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                trackUserProgressState +
                "userId=" +
                this.currentUserId +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            "XJFYUserProgressCourseID",
                            data.courseId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressModuleID",
                            data.tutorialId
                        );
                        localStorage.setItem(
                            "XJFYUserProgressPartID",
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;
                        this.validateUserAccess();
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error !  trackUserProgressState_function ," + error.message,
                        0
                    );
                },
            });
    }

    validateUserAccess() {
        var tnpDataArray = this.tnpData;

        var LastUnlockedModule = parseInt(
            localStorage.getItem("XJFYUserProgressModuleID")
        );

        var LastUnlockedLesson = parseInt(
            localStorage.getItem("XJFYUserProgressPartID")
        );

        this.thisLastUnlockedLesson = LastUnlockedLesson;
        var indexOfLastUnlockedTNP;

        for (let index = 0; index < tnpDataArray.length; index++) {
            tnpDataArray[index];
            if (
                tnpDataArray[index].tutorialId == LastUnlockedModule &&
                tnpDataArray[index].partId == LastUnlockedLesson
            ) {
                indexOfLastUnlockedTNP = index;
            }
        }

        for (let index = 0; index <= indexOfLastUnlockedTNP; index++) {
            tnpDataArray[index].unlocked = true;
            if (tnpDataArray[index].tutorialId == this.moduleID) {
                this.thisPageIsUnlocked = true;
            }
        }

        if (!this.thisPageIsUnlocked) {
            this.router.navigate(["error/401"]);
        } else {
            this.loadData();
        }
    }

    //---------------------------------------------------------------------------------------

    loadData() {
        this.language = this.langCode;

        this.http
            .get<any>(
                environment.baseUrl +
                getAllModuleData +
                "language=" +
                this.language +
                "&courseId=" +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        localStorage.getItem("XJFYToken")
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                    this.tutorialAllData = data.tutorialDtos;

                    if (data.tutorialDtos.length != 0) {
                        this.noData = false;
                    }
                    this.loading = false;

                    for (
                        let index = 0;
                        index < this.tutorialAllData.length;
                        index++
                    ) {
                        if (
                            this.tutorialAllData[index].tutorial.id ==
                            this.moduleID
                        ) {
                            this.partData = this.tutorialAllData[index].parts;
                            this.tutorialData =
                                this.tutorialAllData[index].tutorial;
                            window.document.title =
                                this.tutorialData.tutorialName + " - Erasmus +";
                        }
                    }

                    this.partCount = this.partData.length;
                },
                error: (error) => {
                    this.noData = true;
                    this.loading = false;
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                },
            });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

    toggle_transcript() {
        var transcript_toggle_btn = document.getElementById(
            "transcript_toggle_btn"
        );
        if (transcript_toggle_btn.innerText == "VIEW TRANSCRIPT") {
            transcript_toggle_btn.innerText = "HIDE TRANSCRIPT";
        } else {
            transcript_toggle_btn.innerText = "VIEW TRANSCRIPT";
        }

        document.getElementById("transcript").classList.toggle("cs-hide");
    }
}
