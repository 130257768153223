<div class="forgot-password-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0 cs-hide-small cs-hide-medium">
            <div class="forgot-password-image">
                <img src="assets/img/auth-bg/Login.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">.
            <div style="position:absolute;right: 10px;top: 10px;">
                <app-language-changer></app-language-changer>
            </div>
            <div class="forgot-password-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="forgot-password-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/logo/site/logo_main.png" style="width: 100px;height: auto;" alt="image"></a>
                            </div>
                            <h3>{{lang_.txt_1}}</h3>
                            <p>{{lang_.txt_2}} <a routerLink="/login">{{lang_.txt_3}}</a></p>


                            <form #fpSendOtpForm="ngForm">

                                <div *ngIf="fp_get_otp_show">

                                    <div class="form-group">
                                        <input required ngModel name="email" type="email" #email="ngModel" [class.invalid_input]="email.touched && !email.valid" [email]="true" class="form-control" id="email" placeholder="{{lang_.txt_4}}">
                                        <div class="err_msg" *ngIf="email.touched && !email.valid">
                                            <div *ngIf="email.errors.required">{{lang_.txt_5}}</div>
                                            <div *ngIf="!email.errors.required">{{lang_.txt_6}}</div>
                                        </div>
                                    </div>


                                    <button type="button" class="font_w_l forgot-password-btn" (click)="fp_send_otp(fpSendOtpForm.value)" *ngIf="email.valid">
                                        <span *ngIf="fp_get_otp_req_pending">{{lang_.txt_7}} <i class="bx bx-loader-alt fa-spin ml10 cs-medium"></i></span>
                                        <span *ngIf="!fp_get_otp_req_pending">{{lang_.txt_8}}<i class="bx bx-paper-plane ml10 cs-large"></i></span>
                                    </button>

                                    <button type="button" class="font_w_l forgot-password-btn disabled-btn" *ngIf="!email.valid">{{lang_.txt_9}}<i class="bx bx-paper-plane ml10 cs-large"></i>
                                    </button>

                                </div>

                                <div *ngIf="fp_validate_otp_show">

                                    <div class="form-group">
                                        <input required ngModel name="otp_code" type="number" #otp_code="ngModel" [class.invalid_input]="otp_code.touched && !otp_code.valid" class="form-control" id="otp_code" placeholder="{{lang_.txt_10}}">
                                        <div class="err_msg" *ngIf="otp_code.touched && !otp_code.valid">
                                            <div *ngIf="otp_code.errors.required">{{lang_.txt_11}}</div>
                                            <div *ngIf="!otp_code.errors.required">{{lang_.txt_12}}</div>
                                        </div>
                                    </div>

                                    <button type="button" class="font_w_l forgot-password-btn" (click)="fp_validate_otp(fpSendOtpForm.value)" *ngIf="otp_code.valid">
                                        <span *ngIf="fp_validate_otp_req_pending">{{lang_.txt_13}}<i class="bx bx-loader-alt fa-spin ml10 cs-medium"></i></span>
                                        <span *ngIf="!fp_validate_otp_req_pending">{{lang_.txt_14}}<i class="bx bx-check-double ml10 cs-large"></i></span>
                                    </button>

                                    <button type="button" class="font_w_l forgot-password-btn disabled-btn" *ngIf="!otp_code.valid">{{lang_.txt_15}}<i class="bx bx-paper-plane ml10 cs-large"></i>
                                    </button>

                                </div>

                                <div *ngIf="fp_change_pwd_show">

                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <input required minlength="6" maxlength="50" ngModel name="password" type="password" #password="ngModel" [class.invalid_input]="password.touched && !password.valid" class="form-control invalid_input" id="password" placeholder="{{lang_.txt_16}}">
                                                <div class="err_msg" *ngIf="password.touched && !password.valid">
                                                    <div *ngIf="password.errors.required">{{lang_.txt_17}}</div>
                                                    <div *ngIf="password.errors.minlength">{{lang_.txt_18}}</div>
                                                    <div *ngIf="password.errors.maxlength">{{lang_.txt_19}}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <input required ngModel name="confirm_password" type="password" #confirm_password="ngModel" [class.invalid_input]="confirm_password.touched && !confirm_password.valid" class="form-control" id="confirm_password" placeholder="{{lang_.txt_20}}">
                                                <div class="err_msg" *ngIf="confirm_password.touched && !confirm_password.valid">{{lang_.txt_21}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="button" class="font_w_l forgot-password-btn" (click)="fp_change_pwd(fpSendOtpForm.value)" *ngIf="confirm_password.valid && password.valid">
                                        <span *ngIf="fp_change_pwd_req_pending">{{lang_.txt_22}}<i class="bx bx-loader-alt fa-spin ml10 cs-medium"></i></span>
                                        <span *ngIf="!fp_change_pwd_req_pending">{{lang_.txt_23}}<i class="bx bx-check-shield ml10 cs-large"></i></span>
                                    </button>

                                    <button type="button" class="font_w_l forgot-password-btn disabled-btn" *ngIf="!confirm_password.valid || !password.valid">{{lang_.txt_24}}<i class="bx bx-check-shield ml10 cs-large"></i>
                                    </button>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
