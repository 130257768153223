export const mainBanner_txt = [
    {
        txt_1: 'FILMMAKING SKILLS FOR THE BEGINNERS',
        txt_2: 'Basics of Filmmaking for the Modern Digital World!',
        txt_3: 'Learn filmmaking from the industry experts and get certified from Don Bosco International Media Academy',
        txt_4: 'Start Tutorials',
        txt_5: 'Register Now'
    },
    {
        txt_1: 'Compétences cinématographiques pour les débutants',
        txt_2: 'Les bases de la réalisation de films dans le monde numérique moderne!',
        txt_3: 'Apprenez la réalisation de films avec les experts de l\'industrie et obtenez un certificat de l\'Académie Internationale des Médias de Don Bosco.',
        txt_4: 'Commencez les tutoriels',
        txt_5: 'S\'inscrire maintenant'
    },
    {
        txt_1: 'Producción cinematográfica para principantes',
        txt_2: 'Fundamentos de la producción cinematográfica para el moderno mundo digital!',
        txt_3: 'Aprende producción cinematográfica de la mano de expertos de la industria y consigue tu certificado de Don Bosco International Media Academy',
        txt_4: 'Comienzo de los tutoriales',
        txt_5: 'Regístrate ahora'
    },
    {
        txt_1: 'A FILMKÉSZÍTÉS ALAPJAI KEZDŐKNEK',
        txt_2: 'A filmgyártás alapjai a modern digitális világban!',
        txt_3: 'Tanuld meg a filmgyártás fortélyait filmszakemberektől és szerezd meg a Don Bosco International Media Academy oklevelét.',
        txt_4: 'Kezdd el az oktatóanyagot',
        txt_5: 'Regisztrálj most'
    },
    {
        txt_1: 'ФИЛМОВИ УМЕНИЯ ЗА НАЧИНАЕЩИ',
        txt_2: 'Основи на филмопроизводството за съвременния цифров свят!',
        txt_3: 'Научете се да снимате филми от експерти в бранша и се сертифицирайте от Международната медийна академия "Дон Боско',
        txt_4: 'Начални уроци',
        txt_5: 'Регистрирайте се сега'
    },

];


