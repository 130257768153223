export const myDashboard_txt = [
    {
        txt_1: 'Dashboard',
        txt_2: 'Start Tutorials',
        txt_3: 'Feedback',
        txt_4: 'Logout',
        txt_5: 'Account Details',
        txt_6: 'Close',
        txt_7: 'First Name',
        txt_8: 'First Name is required.',
        txt_9: 'First Name is minimum 3 characters.',
        txt_10: 'First Name is maximum 20 characters.',
        txt_11: 'Last Name',
        txt_12: 'Last Name is required.',
        txt_13: 'Last Name is minimum 3 characters.',
        txt_14: 'Last Name is maximum 20 characters.',
        txt_15: 'Date Of Birth',
        txt_16: 'Invalid Date of Birth',
        txt_17: 'Role',
        txt_18: 'Student',
        txt_19: 'Teacher',
        txt_20: 'Other',
        txt_21: 'Role name',
        txt_22: 'Role name is required.',
        txt_23: 'School/Establishment name',
        txt_24: 'School/Establishment name is required.',
        txt_25: 'Email address',
        txt_26: 'Phone Number',
        txt_27: 'Phone number cannot contain letters.',
        txt_28: 'Country',
        txt_29: 'Country name cannot contain numbers.',
        txt_30: 'Required fields',
        txt_31: 'Required fields to generate certificate',
        txt_32: 'Save Changes',
        txt_33: 'CHANGE PASSWORD',
        txt_34: 'Select Profile Picture',
        txt_35: 'Size',
        txt_36: 'Types',
        txt_37: 'Uploaded',
        txt_38: 'Upload',
        txt_39: 'Uploading ...',
        txt_40: 'Change your password',
        txt_41: 'Your old password',
        txt_42: 'Old password is required.',
        txt_43: 'Your new password',
        txt_44: 'Password is required.',
        txt_45: 'Password minimum is 6 characters.',
        txt_46: 'Password maximum is 50 characters.',
        txt_47: 'Confirm your new password',
        txt_48: 'SENDING REQUEST'
    },
    {
        txt_1: 'Tableau de bord',
        txt_2: 'Tutoriels de départ',
        txt_3: 'Commentaires',
        txt_4: 'Déconnexion',
        txt_5: 'Détails du compte',
        txt_6: 'Fermer',
        txt_7: 'Prénom',
        txt_8: 'Le prénom est obligatoire.',
        txt_9: 'Le prénom doit comporter au moins 3 caractères.',
        txt_10: 'Le prénom comporte 20 caractères maximum.',
        txt_11: 'Nom',
        txt_12: 'Le nom est obligatoire.',
        txt_13: 'Le nom de famille doit comporter au moins 3 caractères.',
        txt_14: 'Le nom de famille comporte 20 caractères au maximum.',
        txt_15: 'Date de naissance',
        txt_16: 'Date de naissance invalide',
        txt_17: 'Rôle',
        txt_18: 'Étudiant',
        txt_19: 'Enseignant',
        txt_20: 'Autre',
        txt_21: 'Nom du rôle',
        txt_22: 'El nombre del rol es obligatorio.',
        txt_23: 'Nom de l\'école/établissement',
        txt_24: 'Le nom de l\'école/établissement est obligatoire.',
        txt_25: 'Adresse e-mail',
        txt_26: 'Numéro de téléphone',
        txt_27: 'Le numéro de téléphone ne peut pas contenir de lettres.',
        txt_28: 'Pays',
        txt_29: 'Le nom du pays ne peut pas contenir de chiffres.',
        txt_30: 'Champs obligatoires',
        txt_31: 'Champs obligatoires pour générer le certificat',
        txt_32: 'Enregistrer les modifications',
        txt_33: 'CHANGER LE MOT DE PASSE',
        txt_34: 'Sélectionnez l\'image de profil',
        txt_35: 'Taille',
        txt_36: 'Types',
        txt_37: 'Téléchargé sur',
        txt_38: 'Télécharger',
        txt_39: 'Télécharger ...',
        txt_40: 'Changez votre mot de passe',
        txt_41: 'Votre ancien mot de passe',
        txt_42: 'Un ancien mot de passe est nécessaire.',
        txt_43: 'Votre nouveau mot de passe',
        txt_44: 'Le mot de passe est obigatoire.',
        txt_45: 'Le mot de passe doit comporter au moins 6 caractères.',
        txt_46: 'Le mot de passe ne doit pas dépasser 50 caractères.',
        txt_47: 'Confirmez votre nouveau mot de passe',
        txt_48: 'ENVOI DE LA DEMANDE'
    },
    {
        txt_1: 'Tablero de mandos',
        txt_2: 'Inicio Tutoriales',
        txt_3: 'Comentarios',
        txt_4: 'Cierre de sesión',
        txt_5: 'Detalles de la cuenta',
        txt_6: 'Cerrar',
        txt_7: 'Nombre',
        txt_8: 'Se necesita el nombre.',
        txt_9: 'El nombre tiene un mínimo de 3 caracteres.',
        txt_10: 'El nombre tiene un máximo de 20 caracteres.',
        txt_11: 'Apellidos',
        txt_12: 'Se necesita el apellido.',
        txt_13: 'Los apellidos tienen un mínimo de 3 caracteres.',
        txt_14: 'Los apellidos tienen un máximo de 20 caracteres.',
        txt_15: 'Fecha de nacimiento',
        txt_16: 'Fecha de nacimiento no válida',
        txt_17: 'Papel',
        txt_18: 'Alumno',
        txt_19: 'Profesor',
        txt_20: 'Otro',
        txt_21: 'Nombre del rol',
        txt_22: 'Se necesita el rol.',
        txt_23: 'Nombre de la escuela/establecimiento',
        txt_24: 'Se necesita el nombre de la escuela/institución.',
        txt_25: 'Dirección e-mail',
        txt_26: 'Número de teléfono',
        txt_27: 'El número de teléfono no puede contener letras.',
        txt_28: 'País',
        txt_29: 'El nombre del país no puede contener números.',
        txt_30: 'Campos obligatorios',
        txt_31: 'Campos obligatorios para generar el certificado',
        txt_32: 'Guardar cambios',
        txt_33: 'CAMBIAR CONTRASEÑA',
        txt_34: 'Seleccione la imagen del perfil',
        txt_35: 'Tamaño',
        txt_36: 'Tipos',
        txt_37: 'Subido a',
        txt_38: 'Subir a',
        txt_39: 'Subir...',
        txt_40: 'Cambiar la contraseña',
        txt_41: 'Su antigua contraseña',
        txt_42: 'Se requiere una contraseña antigua.',
        txt_43: 'Su nueva contraseña',
        txt_44: 'Se necesita la contraseña.',
        txt_45: 'La contarseña debe contener 6 carácteres como mínimo',
        txt_46: 'La contraseña tiene un máximo de 50 caracteres.',
        txt_47: 'Confirme su nueva contraseña',
        txt_48: 'ENVÍO DE LA SOLICITUD'
    },
    {
        txt_1: 'Műszerfal',
        txt_2: 'Start oktatóprogramok',
        txt_3: 'Visszajelzés',
        txt_4: 'Kijelentkezés',
        txt_5: 'Számlaadatok',
        txt_6: 'Close',
        txt_7: 'Utónév',
        txt_8: 'Utónév szükséges',
        txt_9: 'A keresztnév legalább 3 karakterből áll.',
        txt_10: 'A keresztnév legfeljebb 20 karakterből áll.',
        txt_11: 'Családi név',
        txt_12: 'Családi név szükséges',
        txt_13: 'A vezetéknév legalább 3 karakter.',
        txt_14: 'A vezetéknév legfeljebb 20 karakter.',
        txt_15: 'Születési dátum',
        txt_16: 'Érvénytelen születési dátum',
        txt_17: 'Szerepvállalás',
        txt_18: 'Hallgató',
        txt_19: 'Tanár',
        txt_20: 'Egyéb',
        txt_21: 'Szerep neve',
        txt_22: 'Szerepkör szükséges',
        txt_23: 'Iskola/intézmény neve',
        txt_24: 'Iskola/Intézmény szükséges',
        txt_25: 'E-mail cím',
        txt_26: 'Telefonszám',
        txt_27: 'A telefonszám nem tartalmazhat betűket.',
        txt_28: 'Ország',
        txt_29: 'Az országnév nem tartalmazhat számokat.',
        txt_30: 'Kötelező mezők',
        txt_31: 'A tanúsítvány létrehozásához szükséges mezők',
        txt_32: 'Változások mentése',
        txt_33: 'JELSZÓ MÓDOSÍTÁSA',
        txt_34: 'Profilkép kiválasztása',
        txt_35: 'Méret',
        txt_36: 'Típusok',
        txt_37: 'Feltöltve',
        txt_38: 'Feltöltés',
        txt_39: 'Feltöltés ...',
        txt_40: 'Jelszó módosítása',
        txt_41: 'A régi jelszava',
        txt_42: 'Régi jelszó szükséges.',
        txt_43: 'Az új jelszó',
        txt_44: 'Jelszó szükséges',
        txt_45: 'A jelszó minimum 6 karakterből álljon!',
        txt_46: 'A jelszó maximális hossza 50 karakter.',
        txt_47: 'Erősítse meg új jelszavát',
        txt_48: 'SENDING REQUEST'
    },
    {
        txt_1: 'Информационно табло',
        txt_2: 'Начални уроци',
        txt_3: 'Обратна връзка',
        txt_4: 'Излизане от системата',
        txt_5: 'Данни за сметката',
        txt_6: 'Затвори',
        txt_7: 'Първо име',
        txt_8: 'Първото име е задължително.',
        txt_9: 'Първото име е с минимум 3 символа.',
        txt_10: 'Първото име е с максимум 20 символа.',
        txt_11: 'Фамилия',
        txt_12: 'Фамилията е задължителна.',
        txt_13: 'Фамилията съдържа минимум 3 символа.',
        txt_14: 'Фамилията е с максимум 20 символа.',
        txt_15: 'Дата на раждане',
        txt_16: 'Невалидна дата на раждане',
        txt_17: 'Роля',
        txt_18: 'Студент',
        txt_19: 'Учител',
        txt_20: 'Други',
        txt_21: 'Име на ролята',
        txt_22: 'Името на ролята е задължително.',
        txt_23: 'Име на училището/заведението',
        txt_24: 'Изисква се името на училището/заведението.',
        txt_25: 'Имейл адрес',
        txt_26: 'Телефонен номер',
        txt_27: 'Телефонният номер не може да съдържа букви.',
        txt_28: 'Държава',
        txt_29: 'Името на държавата не може да съдържа цифри.',
        txt_30: 'Задължителни полета',
        txt_31: 'Задължителни полета за генериране на сертификат',
        txt_32: 'Запазване на промените',
        txt_33: 'ПРОМЯНА НА ПАРОЛАТА',
        txt_34: 'Изберете профилна снимка',
        txt_35: 'Размер',
        txt_36: 'Видове',
        txt_37: 'Качен на',
        txt_38: 'Качване на',
        txt_39: 'Качване на ...',
        txt_40: 'Променете паролата си',
        txt_41: 'Вашата стара парола',
        txt_42: 'Изисква се стара парола.',
        txt_43: 'Вашата нова парола',
        txt_44: 'Изисква се парола.',
        txt_45: 'Минималната стойност на паролата е 6 символа.',
        txt_46: 'Максималната стойност на паролата е 50 символа.',
        txt_47: 'Потвърдете новата си парола',
        txt_48: 'ИЗПРАЩАНЕ НА ЗАЯВКА'
    }
];


