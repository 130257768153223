export const moduleNavigation_txt = [
    {
        txt_1: 'Tutorial Overview',
        txt_2: 'Tutorials',
        txt_3: 'Watch the tutorial',
        txt_4: 'Check your knowledge',
        txt_5: 'Quiz',
        txt_6: 'Yes'
    },
    {
        txt_1: 'Aperçu du tutoriel',
        txt_2: 'Tutoriels',
        txt_3: 'Regardez le tutoriel',
        txt_4: 'Vérifiez vos connaissances',
        txt_5: 'Quiz',
        txt_6: 'Oui'
    },
    {
        txt_1: 'Resumen del tutorial',
        txt_2: 'Tutoriales',
        txt_3: 'Ver el tutorial',
        txt_4: 'Compruebe sus conocimientos',
        txt_5: 'Cuestionario',
        txt_6: 'Sí'
    },
    {
        txt_1: 'Tutorial áttekintés',
        txt_2: 'Tutorials',
        txt_3: 'Nézze meg a bemutatót',
        txt_4: 'Ellenőrizze tudását',
        txt_5: 'Kvíz',
        txt_6: 'Igen'
    },
    {
        txt_1: 'Преглед на урока',
        txt_2: 'Наръчници',
        txt_3: 'Гледайте урока',
        txt_4: 'Проверете знанията си',
        txt_5: 'Викторина',
        txt_6: 'Да'
    },

];


