<app-header></app-header>
<br><br><br><br>


<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="courses-details-header">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="courses-title">
                                <div class="row">
                                    <div class="col-6">
                                        <h2 routerLink="/learn-film-marking-with-dbima/module/{{tutorialData.id}}" class="cursor_p"><i class='bx bx-box'></i> {{tutorialData.tutorialName}}</h2>
                                        <h4 *ngFor="let part of partData">
                                            <div *ngIf="lessonID == part.id" style="margin-left: 35px;">
                                                <i class='bx bx-book'></i> {{part.partName}}
                                            </div>
                                        </h4>
                                    </div>
                                    <div class="col-6" style="text-align: right;">
                                        <a class="default-btn mb20 font_w_l cursor_p" style="float: right;border-radius: 50px 0px 50px 50px;" routerLink="/learn-film-marking-with-dbima/dashboard">
                                            <i class='bx bxs-dashboard icon-arrow before'></i>
                                            <span class="label">{{lang_.txt_1}}</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </a>
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-4">
                        <app-module-navigation></app-module-navigation>
                    </div>

                    <div class="col-lg-1"></div>

                    <div class="col-lg-6">
                        <div class="courses-details-image cs-card">
                            <div id="watch_video">
                                <iframe width="100%" height="300px" src="/layout/iframe-loader" id="youTubeTutorialIframe" align='center' allowFullscreen='true'>
                                </iframe>
                                <div style="padding: 30px;">
                                    <!--<p>{{youTubeURL}}</p>-->

                                    <div class="hero-banner-content black-color">
                                        <div class="btn-box zero_">
                                            <a class="optional-btn optional-btn-css" (click)="toggle_transcript()" id="transcript_toggle_btn">{{lang_.txt_2}}</a>
                                        </div>
                                    </div>

                                    <a class="default-btn mb20 font_w_l cursor_p" href="learn-film-marking-with-dbima/module/{{moduleID}}/tutorial/{{lessonID}}/quiz">
                                        <i class='bx bx-analyse icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_4}}</span>
                                        <i class="bx bx-analyse icon-arrow after"></i>
                                    </a>
                                    <!--{{lessonPartData.partTranscript}}-->
                                    <div id="transcript" class="cs-hide">

                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <div class="cs-right" style="font-size:24px">
                                                    <div *ngIf="wss_loaded">
                                                        <div *ngIf="wss_success">
                                                            <button class="cs-btn cs-round cs-hide" id="stop_btn" (click)="stop_textToSpeak()">
                                                                <i class='bx bx-stop-circle'></i>
                                                            </button>
                                                            <button class="cs-btn cs-round  cs-hide" id="pause_btn" (click)="pause_textToSpeak()">
                                                                <i class='bx bx-pause-circle'></i>
                                                            </button>
                                                            <button class="cs-btn cs-round  cs-hide" id="resume_btn" (click)="resume_textToSpeak()">
                                                                <i class='bx bx-play-circle'></i>
                                                            </button>
                                                            <button class="cs-btn cs-round  " id="speak_btn" (click)="speak_textToSpeak('transcript_text')">
                                                                <i class='bx bx-volume-full'></i>
                                                            </button>
                                                            <button class="cs-btn cs-round " (click)="toggle_settings_textToSpeak()">
                                                                <i class='bx bx-cog'></i>
                                                            </button>
                                                        </div>
                                                        <div *ngIf="wss_failed">
                                                            <button class="cs-btn cs-round ">
                                                                <i class="fa fa-warning" title="Text to Speech Unavailable!"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!wss_loaded">
                                                        <button class="cs-btn cs-round ">
                                                            <i class="fa fa-circle-o-notch fa-spin"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row cs-hide" id="settings_textToSpeak_div" style="margin-top: 20px;">
                                            <div class="col-4">

                                            </div>
                                            <div class="col-8">
                                                <select id='voiceList' class="form-control cursor_p cs-border-black" (change)="stop_textToSpeak()"></select>
                                            </div>
                                            <div class="col-12">
                                                <hr>
                                            </div>

                                        </div>


                                        <p id="transcript_text">{{lessonPartData.partTranscript}}</p>

                                        <br>
                                        <div class="hero-banner-content black-color mb20">
                                            <div class="btn-box zero_">
                                                <a class="optional-btn optional-btn-css" (click)="toggle_transcript()" id="btnsdgsad">{{lang_.txt_3}}</a>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="text_a_j mt20">
                                        <h3 style="font-family: Verdana, Geneva, Tahoma, sans-serif;">{{lang_.txt_5}}</h3>
                                        <p>{{keyLearning}}</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-lg-1"></div>

                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>