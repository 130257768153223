<app-header></app-header>
<br><br><br><br>
<div class="feedback-form-area" style="margin-top: 200px;margin-bottom: 200px;">
    <div class="container">
        <div class="feedback-form-inner">
            <div class="row">
                <div class="col-lg-5 col-md-12 cs-display-container">
                    <img src="assets/img/system/Feedback.png" class="cs-display-middle" alt="image" style="height: 300px;width:auto;">
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="feedback-form">
                        <h3 class="cs-light-gray">{{lang_.txt_1}}<i class='bx bx-left-arrow-circle cursor_p cs-text-gray cs_nav_icon_2 cs-hover-text-blue float-end' onclick="window.history.back();"></i></h3>
                        <form #feedbackForm="ngForm" (ngSubmit)="feedback_submit(feedbackForm.value)">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="single-testimonials-item">
                                        <span class="cs-right cs-tiny cs-text-gray"><span id="feedback_txt_count">0</span>/250</span>
                                        <div class="form-group">
                                            <textarea cols="30" minlength="5" maxlength="250" required ngModel name="message" #message="ngModel" [class.invalid_input]="message.touched && !message.valid" rows="4" class="form-control" placeholder="{{lang_.txt_2}}" onkeyup="checkTextLimit(this,'feedback_txt_count')"></textarea>
                                            <div class="err_msg" *ngIf="message.touched && !message.valid">
                                                <div *ngIf="message.errors.required">{{lang_.txt_3}}</div>
                                                <div *ngIf="message.errors.minlength">{{lang_.txt_4}}</div>
                                                <div *ngIf="message.errors.maxlength">{{lang_.txt_5}}</div>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <img src="{{currentUserProfileUrl}}" onerror="this.src='../../../../assets/img/logo/site/logo_title.png'" class="shadow rounded-circle" alt="image">
                                            <h4>{{currentUserName}}</h4>
                                            <span>{{currentUserType}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <button type="submit" *ngIf="feedbackForm.valid && !feedbackSubmitPending" class="default-btn" style="float: right;border-radius: 20px 0px 20px 10px ;">
                                        <i class='bx bx-send icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_6}}</span>
                                        <i class="bx bx-send icon-arrow after"></i>
                                    </button>
                                    <button type="button" *ngIf="!feedbackForm.valid && !feedbackSubmitPending" disabled style="cursor: not-allowed;float: right;border-radius: 20px 0px 20px 10px ;" class="default-btn">
                                        <i class='bx bx-send icon-arrow before'></i>
                                        <span class="label">{{lang_.txt_6}}</span>
                                        <i class="bx bx-send icon-arrow after"></i>
                                    </button>
                                    <button type="button" *ngIf="feedbackForm.valid && feedbackSubmitPending" style="cursor: not-allowed;float: right;border-radius: 20px 0px 20px 10px ;" class="default-btn">
                                        <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin "></i></i>
                                        <span class="label">{{lang_.txt_7}}</span>
                                        <i class='icon-arrow after'><i class="bx bx-loader-alt fa-spin "></i></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>