import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//-----Routs----->>>>>>>>>
import { HomeComponent } from "./components/pages/home/home.component";
import { LoginComponent } from "./components/pages/auth/login/login.component";
import { RegisterComponent } from "./components/pages/auth/register/register.component";
import { ContactComponent } from "./components/pages/company/contact/contact.component";
import { ErrorComponent } from "./components/other/error/error.component";
import { MyDashboardComponent } from "./components/pages/my-dashboard/my-dashboard.component";
import { ProgramComponent } from "./components/pages/program/program.component";
import { DashboardComponent } from './components/pages/program/dashboard/dashboard.component';
import { ModuleComponent } from "./components/pages/program/module/module.component";
import { LessonComponent } from "./components/pages/program/lesson/lesson.component";
import { QuizComponent } from './components/pages/program/quiz/quiz.component';
import { ExamOneComponent } from './components/pages/program/exam-one/exam-one.component';
import { ExamTwoComponent } from './components/pages/program/exam-two/exam-two.component';
import { IframeLoaderComponent } from './components/layout/iframe-loader/iframe-loader.component';
import { CertificationVerifyComponent } from "./components/pages/certification-verify/certification-verify.component";
import { FeedbackComponent } from './components/pages/company/feedback/feedback.component';
import { ForgotPasswordComponent } from './components/pages/auth/forgot-password/forgot-password.component';
import { PrivacyPolicyComponent } from './components/pages/company/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/pages/company/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "home", component: HomeComponent },
    { path: "login", component: LoginComponent },
    { path: "register", component: RegisterComponent },
    { path: "forgot-password", component: ForgotPasswordComponent },
    { path: "contact", component: ContactComponent },
    { path: "my-dashboard", component: MyDashboardComponent },
    { path: "learn-film-marking-with-dbima", component: ProgramComponent },
    {
        path: "learn-film-marking-with-dbima/dashboard",
        component: DashboardComponent,
    },
    {
        path: "learn-film-marking-with-dbima/module/:m_module_id",
        component: ModuleComponent,
    },
    {
        path: "learn-film-marking-with-dbima/module/:l_module_id/tutorial/:l_lesson_id",
        component: LessonComponent,
    },
    {
        path: "learn-film-marking-with-dbima/module/:q_module_id/tutorial/:q_lesson_id/quiz",
        component: QuizComponent,
    },
    {
        path: "learn-film-marking-with-dbima/exam-mcq",
        component: ExamOneComponent,
    },
    {
        path: "learn-film-marking-with-dbima/exam-project",
        component: ExamTwoComponent,
    },
    {
        path: "certification-verify",
        component: CertificationVerifyComponent,
    },
    { path: "layout/iframe-loader", component: IframeLoaderComponent },
    { path: "error/:error_code", component: ErrorComponent },
    { path: "give-us-your-feedback", component: FeedbackComponent },

    { path: "cookie-and-privacy-policy", component: PrivacyPolicyComponent },
    { path: "terms-and-conditions", component: TermsAndConditionsComponent },

    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
