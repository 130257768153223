<div class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0 cs-hide-small cs-hide-medium">
            <div class="register-image">
                <img src="assets/img/auth-bg/Register.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div style="position:absolute;right: 10px;top: 10px;">
                <app-language-changer></app-language-changer>
            </div>
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/logo/site/logo_main.png" style="width: 100px;height: auto;" alt="image"></a>
                            </div>
                            <h3 style="line-height: 38px !important">{{lang_.txt_1}}</h3>
                            <!-- <h3 style="line-height: 38px !important">Open up your Cagliero <span style="white-space: nowrap;">E-Cademy</span> Account now</h3> -->
                            <p>{{lang_.txt_2}} <a routerLink="/login">{{lang_.txt_3}}</a></p>

                            <form #registerForm="ngForm" (ngSubmit)="submit(registerForm.value)">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required minlength="3" maxlength="20" ngModel name="fname" type="text" #fname="ngModel" [class.invalid_input]="fname.touched && !fname.valid" class="form-control" id="fname" placeholder="{{lang_.txt_4}}">
                                            <div class="err_msg" *ngIf="fname.touched && !fname.valid">
                                                <div *ngIf="fname.errors.required">{{lang_.txt_5}}</div>
                                                <div *ngIf="fname.errors.minlength">{{lang_.txt_6}}</div>
                                                <div *ngIf="fname.errors.maxlength">{{lang_.txt_7}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required minlength="3" maxlength="20" ngModel name="lname" type="text" #lname="ngModel" [class.invalid_input]="lname.touched && !lname.valid" class="form-control" id="lname" placeholder="{{lang_.txt_8}}">
                                            <div class="err_msg" *ngIf="lname.touched && !lname.valid">
                                                <div *ngIf="lname.errors.required">{{lang_.txt_9}}</div>
                                                <div *ngIf="lname.errors.minlength">{{lang_.txt_10}}</div>
                                                <div *ngIf="lname.errors.maxlength">{{lang_.txt_11}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12" [class.col-lg-4]="role.value != ''" [class.col-lg-12]="role.value == ''">
                                        <div class="form-group">
                                            <select required ngModel name="role" #role="ngModel" [class.invalid_input]="role.touched && !role.valid" class="form-control" id="role" style="cursor: pointer;">
                                                <option selected disabled value="">{{lang_.txt_12}}</option>
                                                <option value="4">{{lang_.txt_13}}</option>
                                                <option value="5">{{lang_.txt_14}}</option>
                                                <option value="6">{{lang_.txt_15}}</option>
                                            </select>
                                            <div class="err_msg" *ngIf="role.touched && !role.valid">
                                                <div *ngIf="role.errors.required">{{lang_.txt_16}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-12" *ngIf="role.value == 6">
                                        <div class="form-group">
                                            <input type="text" required ngModel name="role_name" #role_name="ngModel" [class.invalid_input]="role_name.touched && !role_name.valid" placeholder="{{lang_.txt_17}}" class="form-control" id="role_name">
                                            <div class="err_msg" *ngIf="role_name.touched && !role_name.valid">{{lang_.txt_18}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-12" [class.col-lg-8]="role.value != 6" [class.col-lg-12]="role.value == 6" *ngIf="role.value != ''">
                                        <div class="form-group">
                                            <input type="text" required ngModel name="place" #place="ngModel" [class.invalid_input]="place.touched && !place.valid" placeholder="{{lang_.txt_19}}" class="form-control" id="place">
                                            <div class="err_msg" *ngIf="place.touched && !place.valid">{{lang_.txt_20}}</div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <input required ngModel name="email" type="email" #email="ngModel" [class.invalid_input]="email.touched && !email.valid" [email]="true" class="form-control" id="email" placeholder="{{lang_.txt_21}}">
                                            <div class="err_msg" *ngIf="email.touched && !email.valid">
                                                <div *ngIf="email.errors.required">{{lang_.txt_22}}</div>
                                                <div *ngIf="!email.errors.required">{{lang_.txt_23}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required minlength="6" maxlength="50" ngModel name="password" type="password" #password="ngModel" [class.invalid_input]="password.touched && !password.valid" class="form-control invalid_input" id="password" placeholder="{{lang_.txt_24}}">
                                            <div class="err_msg" *ngIf="password.touched && !password.valid">
                                                <div *ngIf="password.errors.required">{{lang_.txt_25}}</div>
                                                <div *ngIf="password.errors.minlength">{{lang_.txt_26}}</div>
                                                <div *ngIf="password.errors.maxlength">{{lang_.txt_27}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <input required ngModel name="confirm_password" type="password" #confirm_password="ngModel" [class.invalid_input]="confirm_password.touched && !confirm_password.valid" class="form-control" id="confirm_password" placeholder="{{lang_.txt_28}}">
                                            <div class="err_msg" *ngIf="confirm_password.touched && !confirm_password.valid">{{lang_.txt_29}}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">

                                        <div *ngIf="!confirmProceed">
                                            <div *ngIf="!waitingConfirm">
                                                <button type="button" *ngIf="!registerForm.valid" disabled>{{lang_.txt_30}}</button>
                                                <button type="button" *ngIf="registerForm.valid && !registerSubmitPending" (click)="waitingConfirm = true;">{{lang_.txt_30}}</button>
                                            </div>
                                            <div *ngIf="waitingConfirm">
                                                <div class="alert alert-warning" role="alert" style="font-family:'Courier New', Courier, monospace;">
                                                    Are you Sure , you want to proceed with the language you selected? Once confirm you cannot change into other available languages.
                                                </div>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <button type="button" (click)="waitingConfirm = false;">No</button>
                                                    </div>
                                                    <div class="col-8">
                                                        <button type="button" (click)="confirmProceed = true;">Yes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="confirmProceed">
                                            <button type="button" *ngIf="!registerForm.valid" disabled>{{lang_.txt_30}}</button>
                                            <button type="submit" *ngIf="registerForm.valid && !registerSubmitPending">{{lang_.txt_30}}</button>
                                            <button type="button" *ngIf="registerSubmitPending">{{lang_.txt_31}} <i class="bx bx-loader-alt fa-spin ml10"></i></button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>