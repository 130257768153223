import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UserService } from "../../../../services/user.service";
import { Auth } from "../../../../classes/auth";
import { LanguageService } from 'src/app/services/language.service';
import { login_txt } from "./login_txt";
import { CourseService } from "src/app/services/course-service";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

    lang_: any;
    allLang_ = login_txt;
    langCode: string;
    LangCodeIndex: number;

    loginForm: FormGroup;
    sessionEmail = null;

    loginSubmitPending = false;

    constructor(
        private router: Router,
        private loginService: UserService,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService,
        private courseService: CourseService
    ) { }

    ngOnInit() {
        this.lang_loader();
        this.page_init();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    page_init() {
        var userStatus = parseInt(localStorage.getItem("XJFYUserStatus"));
        var obj = JSON.parse(localStorage.getItem("XJFYUser"));

        if (userStatus == 1) {
            this.sessionEmail = obj.email;
        }

        this.loginForm = new FormGroup({
            username: new FormControl(this.sessionEmail, Validators.required),
            password: new FormControl(undefined, Validators.required),
        });
    }

    onSubmit() {
        var loginFormData = this.loginForm.value;
        var validateEmail = /^[a-z]+[0-9a-zA-Z_\.]*@[a-z_]+\.[a-z\.]+$/;

        if (loginFormData.username == null || loginFormData.username == '') {
            this.openSnackBar("Email address is missing !", 1);
        } else if (loginFormData.password == null || loginFormData.password == '') {
            this.openSnackBar("Password is missing !", 1);
        } else {
            this.loginSubmitPending = true;

            this.openSnackBar("Checking credentials, Please wait ...", 0);

            const auth = new Auth();
            auth.username = loginFormData.username;
            auth.password = loginFormData.password;

            this.loginService.loginUser(auth).subscribe(
                (response) => {
                    this.openSnackBar(response.status, 1);
                    // @ts-ignore
                    if (response.status === 1) {
                        localStorage.setItem("XJFYUserStatus", "1");
                        localStorage.setItem(
                            "XJFYUser",
                            JSON.stringify(response.user)
                        );
                        localStorage.setItem(
                            "XJFYToken",
                            "Bearer " + response.token
                        );

                        this.setSiteLangNCourse(response.user);

                    } else {
                        this.openSnackBar(
                            "Invalid credentials ! , Please try again",
                            1
                        );
                        this.loginSubmitPending = false;
                    }
                },
                (error) => {
                    console.log(error);
                    this.openSnackBar(
                        "Sorry !, Your request can't be completed right now.",
                        1
                    );
                    this.loginSubmitPending = false;
                }
            );
        }
    }

    setSiteLangNCourse(user) {

        this.langCode = user.language;

        this.courseService.getAllLanguages().subscribe(response => {
            if (response.status === 1) {
                var languageList = response.languageDtoList;
                for (let index = 0; index < languageList.length; index++) {
                    var element = languageList[index];
                    if (element.code == this.langCode) {
                        localStorage.setItem("e-cademy_lang", this.langCode);
                        localStorage.setItem("e-cademy_courseId", element.courseId);
                    }
                }
                this.loginSubmitPending = false;
                this.openSnackBar("Welcome " + user.firstName + " " + user.lastName, 1);
                this.router.navigate([""]);
            } else {
                this.loginSubmitPending = false;
                this.openSnackBar('Something went wrong !.', 1);
            }
        });

    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 3000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }

}
