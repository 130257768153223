<div class="hero-banner bg-white mt-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <!--style="margin-bottom: 32px;"-->
                            <img src="assets/img/logo/header/logo-BG-heavy.png" alt="logo" style="height: 100px;width: auto;" class=" cs-margin-bottom">
                            <span class="sub-title">{{lang_.txt_1}}</span>
                            <h1 style="line-height: 1.2;">{{lang_.txt_2}}</h1>
                            <p>{{lang_.txt_3}}</p>
                            <div class="btn-box">
                                <a routerLink="/learn-film-marking-with-dbima" class="default-btn"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span class="label">{{lang_.txt_4}}</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                                <a routerLink="/register" class="optional-btn" *ngIf="currentUserName == ' ' || currentUserName == null">{{lang_.txt_5}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner/home_banner.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>