export const baseUrl = 'https://api-erasmus.dbima.fr/';
// export const baseUrl = 'http://localhost:8085/';

export const loginUrl = '/user/userLogin';
export const registerUrl = '/user/register';
export const emailCheckUrl = '/user/validate?email=';
export const getAllCoursesByLanguage = '/course/language/all?language=E';
export const getCourseDetails = '/course/search?';
export const getAllModuleData = '/course/dashboard?';
export const getProfileData = '/user/get?id=';
export const getUserStateOfProgramRegistration = '/progress/validateUser?';
export const registerToTheProgram = '/progress/save';
export const trackUserProgressState = '/course/trackByUser?';
export const getQuizData = '/quiz/getByPart?type=0&partId=';
export const getExam1Data = '/quiz/getByCourse?type=1&courseId=';
export const getExam2Data = '/quiz/getByCourse?type=2&courseId=';
export const updateUserCourseProgress = '/progress/updateParts?';
export const getTNPIdList = '/tutorial/idList';
export const userProfileDataUpdate = '/user/update';
export const updateExamResults = '/progress/updateResult?';
export const fileUpload = '/upload';
export const updateProfilePictureURL = '/user/updateUrl?';
export const updateExamProjectURL = '/progress/answer?';
export const projectURLSubmissionCheck = '/progress/answerValidate?';
export const verifyCertification = '/certificate/verify?id=';
export const getCertificate = '/certificate?';
export const getProfileValidate = '/profile/validate?id=';
export const saveFeedback = '/feedback/save';
export const getAllFeedbacks = '/feedback/getAll';
export const saveContact = '/contact/save';
export const sendOTP = '/user/forgot?username=';
export const validateOTP = '/validate/otp?';
export const changePassword = '/user/changePassword';
export const newsletterSubscribe = '/news/subscribe?email=';
export const getAllCoursesUrl = baseUrl + 'course/all';
export const getCourseLanguages = baseUrl + 'course/languages';
















