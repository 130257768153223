export const error_txt = [
    {
        txt_1: 'Error 404 : Page Not Found',
        txt_2: 'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
        txt_3: 'Error 401 : Unauthorized',
        txt_4: 'Sorry, you don\'t have the access to the page you are trying to access',
        txt_5: 'Back Home'
    },
    {
        txt_1: 'Erreur 404 : Page non trouvée.',
        txt_2: 'La page que vous recherchez a peut-être été supprimée, son nom a été modifié ou elle est temporairement indisponible.',
        txt_3: 'Erreur 401 : Non autorisé',
        txt_4: 'Désolé, vous n\'avez pas accès à la page à laquelle vous essayez d\'accéder.',
        txt_5: 'Retour à la maison'
    },
    {
        txt_1: 'Error 404 : Página no encontrada',
        txt_2: 'Es posible que la página que busca haya sido eliminada, haya cambiado de nombre o no esté disponible temporalmente.',
        txt_3: 'Error 401 : No autorizado',
        txt_4: 'Lo sentimos, no tiene acceso a la página a la que intenta acceder',
        txt_5: 'Volver a casa'
    },
    {
        txt_1: '404-es hiba : Oldal nem található',
        txt_2: 'Lehet, hogy a keresett oldal neve megváltozott, vagy ideiglenesen nem elérhető.',
        txt_3: 'Hiba 401 : Nem engedélyezett',
        txt_4: 'Sajnáljuk, de nincs hozzáférése az oldalhoz, amelyet megpróbál elérni.',
        txt_5: 'Back Home'
    },
    {
        txt_1: 'Грешка 404 : Страницата не е намерена',
        txt_2: 'Страницата, която търсите, може да е била премахната, името ѝ да е било променено или да е временно недостъпна.',
        txt_3: 'Грешка 401 : Неоторизиран',
        txt_4: 'Съжаляваме, но нямате достъп до страницата, която се опитвате да достъпите.',
        txt_5: 'Обратно у дома'
    },

];


