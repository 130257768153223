<div class="testimonials-slides" *ngIf="feedbackListCount != 0">
    <owl-carousel-o [options]="testimonialsSlides">

        <ng-template carouselSlide *ngFor="let feedbackData of feedbackList">
            <div class="single-testimonials-item">
                <p>{{feedbackData.feedback.feedback}}</p>
                <div class="info">
                    <img src="{{feedbackData.user.profileUrl}}" class="shadow rounded-circle" alt="image" onerror="this.src = 'assets/img/system/null-profile-pic.png'">
                    <h3>{{feedbackData.user.firstName}} {{feedbackData.user.lastName}}</h3>
                    <span *ngIf="feedbackData.user.type == 1">Admin</span>
                    <span *ngIf="feedbackData.user.type == 4">Student</span>
                    <span *ngIf="feedbackData.user.type == 5">Teacher</span>
                    <span *ngIf="feedbackData.user.type == 6">{{feedbackData.user.description}}</span>
                </div>
            </div>
        </ng-template>

    </owl-carousel-o>

</div>