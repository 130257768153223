<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0 cs-hide-small cs-hide-medium">
            <div class="login-image">
                <img src="assets/img/auth-bg/Login.jpg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div style="position:absolute;right: 10px;top: 10px;">
                <app-language-changer></app-language-changer>
            </div>
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/logo/site/logo_main.png" style="width: 100px;height: auto;" alt="image"></a>
                            </div>
                            <h3>{{lang_.txt_1}}</h3>
                            <p>{{lang_.txt_2}} <a routerLink="/register">{{lang_.txt_3}}</a></p>
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input type="email" placeholder="{{lang_.txt_4}}" class="form-control" formControlName="username">
                                </div>
                                <div class="form-group">
                                    <input type="password" placeholder="{{lang_.txt_5}}" class="form-control" formControlName="password">
                                </div>
                                <button type="submit" *ngIf="!loginSubmitPending">{{lang_.txt_7}}</button>
                                <button type="button" *ngIf="loginSubmitPending">{{lang_.txt_8}} <i
                                        class="bx bx-loader-alt fa-spin ml10"></i></button>

                                <div class="forgot-password">
                                    <a routerLink="/forgot-password">{{lang_.txt_6}}</a>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
