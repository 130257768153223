export const contact_txt = [
    {
        txt_1: 'Reach us via Message',
        txt_2: 'Enter your Name',
        txt_3: 'Name is required.',
        txt_4: 'Name is minimum 3 character.',
        txt_5: 'Name is maximum 50 character.',
        txt_6: 'Phone Number',
        txt_7: 'Phone number is required.',
        txt_8: 'Email Address',
        txt_9: 'Email address is required.',
        txt_10: 'Invalid email address',
        txt_11: 'Your Message ...',
        txt_12: 'Message is required.',
        txt_13: 'Message is minimum 5 character.',
        txt_14: 'Message is maximum 250 character.',
        txt_15: 'Send Message',
        txt_16: 'Sending Message'
    },
    {
        txt_1: 'Nous joindre par message',
        txt_2: 'Saisissez votre nom',
        txt_3: 'Le nom est obligatoire.',
        txt_4: 'Le nom doit comporter au moins 3 caractères.',
        txt_5: 'Le nom est composé de 50 caractères maximum.',
        txt_6: 'Numéro de téléphone',
        txt_7: 'Le numéro de téléphone est obligatoire.',
        txt_8: 'Adresse e-mail',
        txt_9: 'L\'adresse électronique est obligatoire.',
        txt_10: 'Adresse électronique non valide',
        txt_11: 'Votre message ...',
        txt_12: 'Le message est requis.',
        txt_13: 'Le message doit comporter au moins 5 caractères.',
        txt_14: 'Le message comporte au maximum 250 caractères.',
        txt_15: 'Envoyer le message',
        txt_16: 'Envoi du message'
    },
    {
        txt_1: 'Contactanos vía mensaje',
        txt_2: 'Introduce tu nombre',
        txt_3: 'El nombre es obligatorio.',
        txt_4: 'El nombre tiene un mínimo de 3 caracteres.',
        txt_5: 'El nombre tiene un máximo de 50 caracteres.',
        txt_6: 'Número de teléfono',
        txt_7: 'El número de teléfono es obligatorio.',
        txt_8: 'Dirección de email',
        txt_9: 'La dirección de correo electrónico es obligatoria.',
        txt_10: 'Dirección de correo electrónico no válida',
        txt_11: 'Tu mensaje ...',
        txt_12: 'El mensaje es necesario.',
        txt_13: 'El mensaje tiene un mínimo de 5 caracteres.',
        txt_14: 'El mensaje tiene un máximo de 250 caracteres.',
        txt_15: 'Enviar mensaje',
        txt_16: 'Envío de mensajes'
    },
    {
        txt_1: 'Elérés üzeneten keresztül',
        txt_2: 'Név beírása',
        txt_3: 'A név megadása kötelező.',
        txt_4: 'A név legalább 3 karakteres.',
        txt_5: 'A név legfeljebb 50 karakteres.',
        txt_6: 'Telefonszám',
        txt_7: 'Telefonszám szükséges.',
        txt_8: 'E-mail cím',
        txt_9: 'Az e-mail cím megadása kötelező.',
        txt_10: 'Érvénytelen e-mail cím',
        txt_11: 'Az üzenet szövege ...',
        txt_12: 'Üzenetre van szükség.',
        txt_13: 'Az üzenet legalább 5 karakteres.',
        txt_14: 'Az üzenet maximum 250 karakteres.',
        txt_15: 'Az üzenet elküldése',
        txt_16: 'Üzenet küldése'
    },
    {
        txt_1: 'Потърсете ни чрез съобщение',
        txt_2: 'Въведете името си',
        txt_3: 'Името е задължително.',
        txt_4: 'Името е с минимум 3 символа.',
        txt_5: 'Името е с максимум 50 символа.',
        txt_6: 'Телефонен номер',
        txt_7: 'Изисква се телефонен номер.',
        txt_8: 'Имейл адрес',
        txt_9: 'Изисква се имейл адрес.',
        txt_10: 'Невалиден имейл адрес',
        txt_11: 'Вашето съобщение ...',
        txt_12: 'Изисква се съобщение.',
        txt_13: 'Съобщението е с минимум 5 символа.',
        txt_14: 'Съобщението е с максимум 250 символа.',
        txt_15: 'Изпрати съобщение.',
        txt_16: 'Изпращане на съобщение'
    },

];


