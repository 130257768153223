<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/logo/partner/DBIMA.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/logo/partner/DBIEM.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/logo/partner/Cagliero.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/logo/partner/CIDB.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a class="d-block">
                    <img src="assets/img/logo/partner/Erasmus.png" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
