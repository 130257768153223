import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { home_txt } from './home_txt';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    lang_: any;
    allLang_ = home_txt;
    langCode: string;
    LangCodeIndex: number;

    constructor(private LanguageService: LanguageService) { }

    ngOnInit(): void {
        this.lang_loader();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

}
