export const login_txt = [
    {
        txt_1: 'Welcome back',
        txt_2: 'New to Cagliero E-Cademy ?',
        txt_3: 'Register',
        txt_4: 'Your email address',
        txt_5: 'Your password',
        txt_6: 'Forgot Password?',
        txt_7: 'Login',
        txt_8: 'logging'
    },
    {
        txt_1: 'Bienvenue',
        txt_2: 'Nouveau sur l\'E-Cademy de Cagliero ? ',
        txt_3: 'S\'inscrire',
        txt_4: 'Votre adresse e-mail',
        txt_5: 'Votre mot de passe',
        txt_6: 'Mot de passe oublié?',
        txt_7: 'Connexion',
        txt_8: 'logging'
    },
    {
        txt_1: 'Bienvenido de nuevo',
        txt_2: '¿Nuevo en Cagliero E-Cademy?',
        txt_3: 'Regístrate',
        txt_4: 'Tu dirección de e-mail',
        txt_5: 'Tu contraseña',
        txt_6: '¿Olvidaste tu contraseña?',
        txt_7: 'Login',
        txt_8: 'logging'
    },
    {
        txt_1: 'Üdvözlünk újra!',
        txt_2: 'Új vagy a Cagliero E-Cademy-n?',
        txt_3: 'Regisztrálj!',
        txt_4: 'Email címed',
        txt_5: 'Jelszó',
        txt_6: 'Elfelejtett jelszó',
        txt_7: 'Bejelentkezés',
        txt_8: 'logging'
    },
    {
        txt_1: 'Добре дошли обратно',
        txt_2: 'Нов потребител на Cagliero E-Cademy ?',
        txt_3: 'Регистрация',
        txt_4: 'Вашият имейл адрес',
        txt_5: 'Вашата парола',
        txt_6: 'Забравена парола?',
        txt_7: 'Вход',
        txt_8: 'регистриране'
    },
];


