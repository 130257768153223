<div class="courses-sidebar-information">
    <h2>{{lang_.txt_1}}</h2>
    <ul>
        <li>
            <div onclick="toggleView('lessons')" class="cursor_p">
                <span><i class='bx bx-book'></i> {{lang_.txt_2}}:</span> 0 / {{partCount}}
            </div>

            <br><br>

            <div class="cs-animate-zoom" id="lessons">

                <div *ngFor="let part of partData ; let i = index">
                    <div class="cs-button cs-block cs-padding-16 cs-round row" [class.cs-light-gray]="lessonID == part.id " style="margin-left: 5px;" onclick="toggleTab(this)" *ngIf="part.unlocked">
                        <div class="col-10 cs-left-align" style="overflow-x:hidden;" title="{{part.partName}}">
                            <label>{{i+1}}. {{part.partName}}</label>
                        </div>
                        <div class="col-2">
                            <span><i class="bx cs-xlarge" [class.bx-chevron-down]="lessonID != part.id "
                                    [class.bx-chevron-up]="lessonID == part.id "></i></span>
                        </div>
                    </div>

                    <div class="cs-padding-16 cs-round row cs-medium cs-animate-zoom cs-hide part_detail_card" [class.cs-hide]="lessonID != part.id " style="margin-left: 50px;margin-right: 5px;" id="l_{{part.id}}" *ngIf="part.unlocked">

                        <a class="row cs-hover-light-gray cursor_p cs-padding-16" href="/learn-film-marking-with-dbima/module/{{moduleID}}/tutorial/{{part.id}}" [class.cs-light-gray]="lessonID == part.id && pageName == 'lesson'">
                            <div class="col-12">
                                <label class="font_w_l cursor_p" style="float: left;">
                                    <i class="material-icons cs-text-gray cs-large mr10">play_circle_filled</i>
                                    {{lang_.txt_3}}
                                </label>
                                <label class="font_w_l cs-small cursor_p" style="float: right;">{{part.partDurationMinute}} min <label *ngIf="part.partDurationSecond != 0">{{part.partDurationSecond}}
                                    sec</label></label>
                            </div>
                        </a>

                        <a class="row cs-hover-light-gray cursor_p cs-padding-16" href="/learn-film-marking-with-dbima/module/{{moduleID}}/tutorial/{{part.id}}/quiz" [class.cs-light-gray]="lessonID == part.id && pageName == 'quiz'">
                            <div class="col-12 ">
                                <label class="font_w_l cursor_p" style="float: left;">
                                    <i class="material-icons cs-text-gray cs-large mr10">quiz</i>
                                    {{lang_.txt_4}}
                                </label>
                            </div>
                        </a>

                    </div>

                    <div class="cs-button cs-block cs-padding-16 cs-round row cs-hover-pale-optionalColor" style="margin-left: 5px;cursor: default;" *ngIf="!part.unlocked">
                        <div class="col-10 cs-left-align" style="overflow-x:hidden;" title="{{part.partName}}">
                            <label class="cs-text-gray ">{{i+1}}. {{part.partName}}</label>
                        </div>
                        <div class="col-2">
                            <span><i class="bx bx-lock cs-xlarge" ></i></span>
                        </div>
                    </div>

                </div>
            </div>

        </li>

        <li>
            <span><i class='bx bx-detail'></i> {{lang_.txt_5}}:</span> {{lang_.txt_6}}
        </li>
    </ul>
</div>