import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import {
    sendOTP,
    validateOTP,
    changePassword,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { LanguageService } from 'src/app/services/language.service';
import { forgotPassword_txt } from "./forgot-password_txt";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {

    lang_: any;
    allLang_ = forgotPassword_txt;
    langCode: string;
    LangCodeIndex: number;

    fp_send_otp_passed = false;
    fp_validate_otp_passed = false;

    fp_get_otp_show = true;
    fp_validate_otp_show = false;
    fp_change_pwd_show = false;

    fp_get_otp_req_pending = false;
    fp_validate_otp_req_pending = false;
    fp_change_pwd_req_pending = false;

    userEmail;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private LanguageService: LanguageService
    ) {}

    ngOnInit(): void {
        this.lang_loader();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    fp_send_otp(form) {
        this.fp_get_otp_req_pending = true;
        this.http
            .get<any>(environment.baseUrl + sendOTP + form.email)
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.openSnackBar(
                            "OTP CODE has been Send to your email",
                            1
                        );
                        this.userEmail = form.email;
                        this.fp_send_otp_passed = true;

                        this.fp_get_otp_show = false;
                        this.fp_validate_otp_show = true;
                        this.fp_change_pwd_show = false;
                    } else {
                        this.openSnackBar(data.message, 1);
                    }
                    this.fp_get_otp_req_pending = false;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                    this.fp_get_otp_req_pending = false;
                },
            });
    }

    fp_validate_otp(form) {
        this.fp_validate_otp_req_pending = true;
        this.http
            .get<any>(
                environment.baseUrl +
                    validateOTP +
                    "otp=" +
                    form.otp_code +
                    "&username=" +
                    this.userEmail
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.openSnackBar("OTP CODE Confirmed", 1);
                        this.fp_validate_otp_passed = true;

                        this.fp_get_otp_show = false;
                        this.fp_validate_otp_show = false;
                        this.fp_change_pwd_show = true;
                    } else {
                        this.openSnackBar(data.message, 1);
                    }
                    this.fp_validate_otp_req_pending = false;
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error! ," + error.message,
                        0
                    );
                    this.fp_validate_otp_req_pending = false;
                },
            });
    }

    fp_change_pwd(form) {
        if (form.password == form.confirm_password) {
            this.fp_change_pwd_req_pending = true;
            this.http
                .post<any>(environment.baseUrl + changePassword, {
                    password: form.password,
                    oldPassword: "",
                    username: this.userEmail,
                    reset: true,
                })
                .subscribe({
                    next: (data) => {
                        if (data.status == 1) {
                            this.openSnackBar(
                                "Password Changed, login with your new credentials",
                                1
                            );
                            localStorage.clear();
                            this.router.navigate(["login"]);
                        } else {
                            this.openSnackBar(data.message, 1);
                        }
                        this.fp_change_pwd_req_pending = false;
                    },
                    error: (error) => {
                        this.openSnackBar("Something went Wrong, Please try again latter !",1);
                        this.fp_change_pwd_req_pending = false;
                    },
                });
        } else {
            this.openSnackBar("Password Mismatched", 1);
        }
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, "Dismiss", { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, "Dismiss");
        }
    }
}
