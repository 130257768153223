export const footer_txt = [
    {
        txt_1: 'Contact Us',
        txt_2: 'Useful Links',
        txt_3: 'Register',
        txt_4: 'Login',
        txt_5: 'Verify Certificate',
        txt_6: 'Profile',
        txt_7: 'Cookie & Privacy Policy',
        txt_8: 'Terms & Conditions',
        txt_9: 'Newsletter',
        txt_10: 'Subscribe to get the latest news and updates.',
        txt_11: 'Enter your E-mail address:',
        txt_12: 'Email address',
        txt_13: 'Email address is required.',
        txt_14: 'Invalid email address.',
        txt_15: 'Subscribe',
        txt_16: 'Subscribing'
    },
    {
        txt_1: 'Nous contacter',
        txt_2: 'Liens utiles',
        txt_3: 'S\'inscrire',
        txt_4: 'Se connecter',
        txt_5: 'Vérifiez le certificat',
        txt_6: 'Profil',
        txt_7: 'Politique de confidentialité',
        txt_8: 'Termes et conditions',
        txt_9: 'Newsletter',
        txt_10: 'Inscrivez-vous pour recevoir les dernières nouvelles et mises à jour.',
        txt_11: 'Saisissez votre adresse e-mail :',
        txt_12: 'Adresse e-mail',
        txt_13: 'Adresse e-mail est nécessaire.',
        txt_14: 'Adresse e-mail invalide.',
        txt_15: 'S\'abonner',
        txt_16: 'Subscribing'
    },
    {
        txt_1: 'Contáctanos',
        txt_2: 'Enlaces de interés',
        txt_3: 'Registro',
        txt_4: 'Login',
        txt_5: 'Verifica tu certificado',
        txt_6: 'Perfil',
        txt_7: 'Política de privacidad',
        txt_8: 'Términos y condiciones',
        txt_9: 'Newsletter',
        txt_10: 'Suscríbete para recibir las últimas noticias.',
        txt_11: 'Introduce tu dirección e-mail:',
        txt_12: 'Dirección de correo electrónico',
        txt_13: 'Se requiere Dirección de correo electrónico.',
        txt_14: 'Dirección de correo electrónico no válida.',
        txt_15: 'Subscríbete',
        txt_16: 'Subscribing'
    },
    {
        txt_1: 'Kapcsolat',
        txt_2: 'Hasznos linkek',
        txt_3: 'Regisztráció',
        txt_4: 'Bejelentkezés',
        txt_5: 'Tanúsítvány ellenőrzése',
        txt_6: 'Profil',
        txt_7: 'Adatvédelmi irányelvek',
        txt_8: 'Felhasználási feltételek',
        txt_9: 'Hírlevél',
        txt_10: 'Iratkozz fel, hogy értesülj a legfrissebb hírekről és újdonságokról.',
        txt_11: 'Írd be az e-mail címedet::',
        txt_12: 'Email cím',
        txt_13: 'E-mail cím megadása kötelező.',
        txt_14: 'Érvénytelen e-mail cím',
        txt_15: 'Iratkozz fel',
        txt_16: 'Subscribing'
    },
    {
        txt_1: 'Свържете се с нас',
        txt_2: 'Полезни връзки',
        txt_3: 'Регистрация',
        txt_4: 'Вход',
        txt_5: 'Проверка на сертификата',
        txt_6: 'Профил',
        txt_7: 'Политика за поверителност',
        txt_8: 'Правила и условия',
        txt_9: 'Бюлетин',
        txt_10: 'Абонирайте се, за да получавате последните новини и актуализации.',
        txt_11: 'Въведете имейл адреса си:',
        txt_12: 'Имейл адрес',
        txt_13: 'Изисква се имейл адрес.',
        txt_14: 'Невалиден имейл адрес',
        txt_15: 'Абонирайте се за',
        txt_16: 'Абониране за'
    },

];


