export const certificationVerify_txt = [
    {
        txt_1: 'Verify Your Certification',
        txt_2: 'Certification ID *',
        txt_3: 'Certification ID is required.',
        txt_4: 'Certification ID is Invalid !!!.',
        txt_5: 'Submit',
        txt_6: 'Validating',
        txt_7: 'Issued on'
    },
    {
        txt_1: 'Vérifiez votre certification',
        txt_2: 'ID de certification *',
        txt_3: 'Un identifiant de certification est requis.',
        txt_4: 'L\'ID de certification est invalide ! !!.',
        txt_5: 'Vérifier',
        txt_6: 'Valider',
        txt_7: 'Délivré le'
    },
    {
        txt_1: 'Verifica tu certificado',
        txt_2: 'ID del certificado *',
        txt_3: 'Se requiere una identificación de certificación.',
        txt_4: 'El ID de la certificación no es válido !!!.',
        txt_5: 'Enviar',
        txt_6: 'Validando',
        txt_7: 'Emitido el'
    },
    {
        txt_1: 'A tanúsítványod ellenőrzése',
        txt_2: 'A tanúsítvány adatai *',
        txt_3: 'Tanúsítói igazolvány szükséges.',
        txt_4: 'A tanúsítási azonosító érvénytelen !!!.',
        txt_5: 'Leadás',
        txt_6: 'Érvényesítés',
        txt_7: 'Kiadva'
    },
    {
        txt_1: 'Проверете сертификата си',
        txt_2: 'Номер на сертификата *',
        txt_3: 'Изисква се идентификационен номер на сертификата.',
        txt_4: 'Идентификационният номер на сертификата е невалиден !!!.',
        txt_5: 'Подаване на',
        txt_6: 'Утвърждаване на',
        txt_7: 'Издаден на'
    },
];


