import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import {
    getCourseDetails,
    updateExamProjectURL,
    trackUserProgressState,
    projectURLSubmissionCheck,
    getUserStateOfProgramRegistration,
} from "../../../../models/endpoints";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CourseClass } from "src/app/classes/CourseClass";
import { UploadService } from '../../../../services/upload-service';
import { LanguageService } from 'src/app/services/language.service';
import { examTwo_txt } from './exam-two_txt';

declare var loadExamResultChart;
@Component({
    selector: 'app-exam-two',
    templateUrl: './exam-two.component.html',
    styleUrls: ['./exam-two.component.scss'],
})
export class ExamTwoComponent implements OnInit {

    lang_: any;
    allLang_ = examTwo_txt;
    langCode: string;
    LangCodeIndex: number;

    noData = true;
    loading = true;

    currentUserName: string;
    currentUserId: number;
    currentUserToken: string;
    currentUserProgressCourseID: number;
    currentUserProgressModuleID: number;
    currentUserProgressPartID: number;

    courseID = parseInt(localStorage.getItem('e-cademy_courseId'));
    language;

    courseData = new CourseClass();

    nodata = true;

    all_quiz_completed = false;
    exam01Completed = false;
    exam02Completed = false;

    urlSubmitted = true;
    submittedURL;

    exam02resultDisplay;
    autoSaveInterval;
    imageFile: any;
    uploadText: string;

    firstTimeInProgram = true;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private uploadService: UploadService,
        private LanguageService: LanguageService
    ) { }

    ngOnInit(): void {
        this.lang_loader();
        this.uploadText = this.lang_.txt_29;
        this.loadCourseData();
        this.getCurrentUserData();
    }

    lang_loader() {
        this.langCode = this.LanguageService.getCode();
        this.LangCodeIndex = this.LanguageService.getCodeIndex(this.langCode);
        this.lang_ = this.allLang_[this.LangCodeIndex];
    }

    loadCourseData() {
        this.language = this.langCode;
        this.http
            .get<any>(
                environment.baseUrl +
                getCourseDetails +
                "language=" +
                this.language +
                "&courseId=" +
                this.courseID
            )
            .subscribe({
                next: (data) => {
                    this.courseData = data.course;
                },
                error: (error) => {
                    this.openSnackBar("Something went Wrong, Please try again latter !", 1);
                },
            });
    }

    //------------------------------ User Data

    getCurrentUserData() {
        const userStatus = parseInt(localStorage.getItem('XJFYUserStatus'));
        const token = localStorage.getItem('XJFYToken');
        const userObj = JSON.parse(localStorage.getItem('XJFYUser'));

        if (userStatus == 1) {
            this.currentUserName = userObj.firstName + ' ' + userObj.lastName;
            this.currentUserId = userObj.id;
            this.currentUserToken = token;
            this.getUserStateOfProgramRegistration_function();
        } else {
            this.router.navigate(['error/401']);
        }
    }

    getUserStateOfProgramRegistration_function() {
        this.http
            .get<any>(
                environment.baseUrl + getUserStateOfProgramRegistration + "courseId=" + this.courseID + "&userId=" + this.currentUserId,
                {
                    headers: new HttpHeaders().set(
                        "Authorization",
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.firstTimeInProgram = false;
                        this.trackUserProgressState_function();
                    } else {
                        this.firstTimeInProgram = true;
                        this.router.navigate(["error/401"]);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        "There was an error!," + error.message,
                        0
                    );
                },
            });
    }

    trackUserProgressState_function() {
        this.http
            .get<any>(
                environment.baseUrl +
                trackUserProgressState +
                'userId=' +
                this.currentUserId +
                '&courseId=' +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        'Authorization',
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        localStorage.setItem(
                            'XJFYUserProgressCourseID',
                            data.courseId
                        );
                        localStorage.setItem(
                            'XJFYUserProgressModuleID',
                            data.tutorialId
                        );
                        localStorage.setItem(
                            'XJFYUserProgressPartID',
                            data.partId
                        );
                        this.currentUserProgressCourseID = data.courseId;
                        this.currentUserProgressModuleID = data.tutorialId;
                        this.currentUserProgressPartID = data.partId;

                        if (data.percentage == 100) {
                            this.all_quiz_completed = true;

                            if (!data.exam01) {
                                this.router.navigate(['error/401']);
                            } else {
                                this.exam01Completed = true;
                                this.projectURLSubmissionCheck_fun();
                                if (!data.exam02) {
                                } else {
                                    this.exam02Completed = true;

                                    this.autoSaveInterval = setTimeout(() => {
                                        this.callToLoadExamResultChart(
                                            'exam02ResultChart',
                                            data.result02,
                                            40 - data.result02
                                        );
                                        this.exam02resultDisplay =
                                            '' + data.result02 + '/40';
                                    }, 1000);

                                }
                            }
                        } else {
                            this.router.navigate(['error/401']);
                        }

                        if (data.percentage == 100) {
                        } else {
                            this.router.navigate(['error/401']);
                        }
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        'There was an error! ,' + error.message,
                        0
                    );
                },
            });
    }

    // ---------------------------------------------------------------------------------------

    projectURLSubmissionCheck_fun() {
        this.http
            .get<any>(
                environment.baseUrl +
                projectURLSubmissionCheck +
                'userId=' +
                this.currentUserId +
                '&courseId=' +
                this.courseID,
                {
                    headers: new HttpHeaders().set(
                        'Authorization',
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status == 1) {
                        this.urlSubmitted = true;
                        this.submittedURL = data.message;
                    } else {
                        this.urlSubmitted = false;
                    }
                    this.noData = false;
                    this.loading = false;
                },
                error: (error) => {
                    this.noData = true;
                    this.loading = false;
                    this.openSnackBar(
                        'There was an error! ,' + error.message,
                        0
                    );
                },
            });
    }

    toggle_projectLinkSubmission() {
        document
            .getElementById('project_link_submission_btn')
            .classList.toggle('cs-hide');
        document
            .getElementById('project_link_submission_div')
            .classList.toggle('cs-hide');
    }

    callToLoadExamResultChart(id, correct, incorrect) {
        loadExamResultChart(id, correct, incorrect);
    }

    url_submit(form) {
        if (validURL(form.url)) {
            this.submit(form.url);
        } else {
            this.openSnackBar('Invalid URL !', 1);
        }

        function validURL(str) {
            const pattern = new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
                'i'
            ); // fragment locator
            return !!pattern.test(str);
        }
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

    logoInputChange(fileInputEvent: any) {
        this.imageFile = fileInputEvent.target.files[0];
        this.uploadText = this.imageFile.name;
        this.uploadService.uploadFile(this.imageFile).subscribe(response => {
            // @ts-ignore
            if (response.status === 1) {
                this.submit(response.path);
            } else {
                this.openSnackBar('Your request can\'t be completed right now.', 1);
            }
        }, error => {
            console.log(error);
            this.openSnackBar('Your request can\'t be completed right now.', 1);
        });
    }

    submit(url: any) {
        this.http
            .get<any>(
                environment.baseUrl +
                updateExamProjectURL +
                'userId=' +
                this.currentUserId +
                '&courseId=' +
                this.courseID +
                '&url=' +
                url,
                {
                    headers: new HttpHeaders().set(
                        'Authorization',
                        this.currentUserToken
                    ),
                }
            )
            .subscribe({
                next: (data) => {
                    if (data.status === 1) {
                        this.urlSubmitted = true;
                        this.submittedURL = url;
                        this.openSnackBar('Project Link Submitted', 1);
                    }
                },
                error: (error) => {
                    this.openSnackBar(
                        'There was an error! ,' + error.message,
                        0
                    );
                },
            });
    }

    download() {
        const link = document.createElement('a');
        link.download = 'template.docx';
        link.href = 'assets/template.docx';
        link.click();
    }
}
