export const dataNotFound_txt = [
    {
        txt_1: 'Data Not Found'
    },
    {
        txt_1: 'Données non trouvées'
    },
    {
        txt_1: 'Datos no encontrados'
    },
    {
        txt_1: 'Nem talált adat'
    },
    {
        txt_1: 'Данните не са намерени'
    },

];


