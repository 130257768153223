export const home_txt = [
    {
        txt_1: 'Testimonials',
        txt_2: 'Our Partners'
    },
    {
        txt_1: 'Témoignages',
        txt_2: 'Nos partenaires'
    },
    {
        txt_1: 'Testimonios',
        txt_2: 'Nuestros socios'
    },
    {
        txt_1: 'Vélemények',
        txt_2: 'Partnereink'
    },
    {
        txt_1: 'Отзиви',
        txt_2: 'Нашите партньори'
    },

];


