export const program_txt = [
    {
        txt_1: 'Course Language',
        txt_2: 'Start Program',
        txt_3: 'Starting Program',
        txt_4: 'Continue Program',
        txt_5: 'Get Certificate',
        txt_6: 'Register Now',
        txt_7: 'Tutorial Topics',
        txt_8: 'Tutorials',
        txt_9: 'Quiz',
        txt_10: 'Yes',
        txt_11: 'Level',
        txt_12: 'Beginner',
        txt_13: 'Certificate',
        txt_14: 'Yes – DBIMA',
        txt_15: 'Dashboard',
    },
    {
        txt_1: 'Langue du cours',
        txt_2: 'Programme de démarrage',
        txt_3: 'Programme de départ',
        txt_4: 'Continuer le programme',
        txt_5: 'Obtenir un certificat',
        txt_6: 'Inscrivez-vous maintenant',
        txt_7: 'Sujets de tutorat',
        txt_8: 'Tutoriels',
        txt_9: 'Quiz',
        txt_10: 'Oui',
        txt_11: 'Niveau',
        txt_12: 'Débutant',
        txt_13: 'Certificat',
        txt_14: 'Oui - DBIMA',
        txt_15: 'Tableau de bord',
    },
    {
        txt_1: 'Idioma del curso',
        txt_2: 'Programa de inicio',
        txt_3: 'Programa de inicio',
        txt_4: 'Continuar con el programa',
        txt_5: 'Obtener certificado',
        txt_6: 'Regístrese ahora',
        txt_7: 'Temas tutoriales',
        txt_8: 'Tutoriales',
        txt_9: 'Cuestionario',
        txt_10: 'Sí',
        txt_11: 'Nivel',
        txt_12: 'Principiante',
        txt_13: 'Certificado',
        txt_14: 'Sí - DBIMA',
        txt_15: 'Tablero de mandos',
    },
    {
        txt_1: 'Tanfolyam Nyelv',
        txt_2: 'Program indítása',
        txt_3: 'Program indítása',
        txt_4: 'Program folytatása',
        txt_5: 'Tanúsítvány beszerzése',
        txt_6: 'Regisztráljon most',
        txt_7: 'Tutorial témák',
        txt_8: 'Tutorials',
        txt_9: 'Kvíz',
        txt_10: 'Igen',
        txt_11: 'Szint',
        txt_12: 'Kezdő',
        txt_13: 'Tanúsítvány',
        txt_14: 'Igen - DBIMA',
        txt_15: 'Műszerfal',
    },
    {
        txt_1: 'Език на курса',
        txt_2: 'Начало на програмата',
        txt_3: 'Начална програма',
        txt_4: 'Продължаване на програмата',
        txt_5: 'Получаване на сертификат',
        txt_6: 'Регистрирайте се сега',
        txt_7: 'Учебни теми',
        txt_8: 'Наръчници',
        txt_9: 'Викторина',
        txt_10: 'Да',
        txt_11: 'Ниво',
        txt_12: 'Начинаещи',
        txt_13: 'Сертификат',
        txt_14: 'Да - DBIMA',
        txt_15: 'Информационно табло',
    },
];


