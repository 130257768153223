export const register_txt = [
    {
        txt_1: 'Register now to access the free tutorials',
        txt_2: 'Already Registered?',
        txt_3: 'Login',
        txt_4: 'First Name',
        txt_5: 'First Name is required.',
        txt_6: 'First Name is minimum 3 characters.',
        txt_7: 'First Name is maximum 20 characters.',
        txt_8: 'Last Name',
        txt_9: 'Last Name is required.',
        txt_10: 'Last Name is minimum 3 characters.',
        txt_11: 'Last Name is maximum 20 characters.',
        txt_12: 'Select your role',
        txt_13: 'Student',
        txt_14: 'Teacher',
        txt_15: 'Other',
        txt_16: 'Role is required.',
        txt_17: 'Type your Role name ...',
        txt_18: 'Role name is required.',
        txt_19: 'Type your School/Establishment name ...',
        txt_20: 'School/Establishment name is required.',
        txt_21: 'Email address',
        txt_22: 'Email address is required.',
        txt_23: 'Invalid email address',
        txt_24: 'Password',
        txt_25: 'Password is required.',
        txt_26: 'Password minimum is 6 characters.',
        txt_27: 'Password maximum is 50 characters.',
        txt_28: 'Confirm your password',
        txt_29: 'Confirm your Password',
        txt_30: 'Register',
        txt_31: 'Registering'
    },
    {
        txt_1: 'Inscrivez-vous maintenant pour accéder aux tutoriels gratuits',
        txt_2: 'Déjà inscrit ?',
        txt_3: 'Connexion',
        txt_4: 'Prénom',
        txt_5: 'Le prénom est obligatoire.',
        txt_6: 'Le prénom doit comporter au moins 3 caractères.',
        txt_7: 'Le prénom comporte 20 caractères maximum.',
        txt_8: 'Nom',
        txt_9: 'Le nom est obligatoire.',
        txt_10: 'Le nom de famille doit comporter au moins 3 caractères.',
        txt_11: 'Le nom de famille comporte 20 caractères au maximum.',
        txt_12: 'Sélectionnez votre fonction',
        txt_13: 'Étudiant',
        txt_14: 'Enseignant',
        txt_15: 'Autre',
        txt_16: 'Le rôle est requis.',
        txt_17: 'Tapez votre nom de rôle ...',
        txt_18: 'El nombre del rol es obligatorio.',
        txt_19: 'Tapez le nom de votre école/établissement ...',
        txt_20: 'Le nom de l\'école/établissement est obligatoire.',
        txt_21: 'Adresse e-mail',
        txt_22: 'L\'adresse e-mail est obligatoire',
        txt_23: 'Adresse électronique non valide',
        txt_24: 'Mot de passe',
        txt_25: 'Le mot de passe est obigatoire.',
        txt_26: 'Le mot de passe doit comporter au moins 6 caractères.',
        txt_27: 'Le mot de passe ne doit pas dépasser 50 caractères.',
        txt_28: 'Confirmez votre mot de passe',
        txt_29: 'Confirmez votre mot de passe',
        txt_30: 'S\'inscrire',
        txt_31: 'Registering'
    },
    {
        txt_1: 'Regístrate ahora para acceder a nuestros tutoriales gratuitos',
        txt_2: '¿Ya registrado?',
        txt_3: 'Login',
        txt_4: 'Nombre',
        txt_5: 'Se necesita el nombre.',
        txt_6: 'El nombre tiene un mínimo de 3 caracteres.',
        txt_7: 'El nombre tiene un máximo de 20 caracteres.',
        txt_8: 'Apellidos',
        txt_9: 'Se necesita el apellido.',
        txt_10: 'Los apellidos tienen un mínimo de 3 caracteres.',
        txt_11: 'Los apellidos tienen un máximo de 20 caracteres.',
        txt_12: 'Selecciona tu rol',
        txt_13: 'Alumno',
        txt_14: 'Profesor',
        txt_15: 'Otro',
        txt_16: 'El papel es necesario.',
        txt_17: 'Escribe tu rol',
        txt_18: 'Se necesita el rol.',
        txt_19: 'Escribe tu escuela/institución',
        txt_20: 'Se necesita el nombre de la escuela/institución.',
        txt_21: 'Dirección e-mail',
        txt_22: 'Se necesita la dirección e-mail.',
        txt_23: 'Dirección de correo electrónico no válida',
        txt_24: 'Contraseña',
        txt_25: 'Se necesita la contraseña.',
        txt_26: 'La contarseña debe contener 6 carácteres como mínimo',
        txt_27: 'La contraseña tiene un máximo de 50 caracteres.',
        txt_28: 'Confirma tu contraseña',
        txt_29: 'Confirma tu contraseña',
        txt_30: 'Registro',
        txt_31: 'Registering'
    },
    {
        txt_1: 'Regisztrálj most, hogy elérhesd az ingyenes oktatóanyagokat',
        txt_2: 'Regisztráltál már?',
        txt_3: 'Bejelentkezés',
        txt_4: 'Utónév',
        txt_5: 'Utónév szükséges',
        txt_6: 'A keresztnév legalább 3 karakterből áll.',
        txt_7: 'A keresztnév legfeljebb 20 karakterből áll.',
        txt_8: 'Családi név',
        txt_9: 'Családi név szükséges',
        txt_10: 'A vezetéknév legalább 3 karakter.',
        txt_11: 'A vezetéknév legfeljebb 20 karakter.',
        txt_12: 'Válaszd ki a szerepkörödet!',
        txt_13: 'Hallgató',
        txt_14: 'Tanár',
        txt_15: 'Egyéb',
        txt_16: 'Szükséges szerep.',
        txt_17: 'Írd be a szerepkörödet!',
        txt_18: 'Szerepkör szükséges',
        txt_19: 'Írd be az iskolád/intézményed nevét!',
        txt_20: 'Iskola/Intézmény szükséges',
        txt_21: 'E-mail cím',
        txt_22: 'E-mail cím szükséges',
        txt_23: 'Érvénytelen e-mail cím',
        txt_24: 'Jelszó',
        txt_25: 'Jelszó szükséges',
        txt_26: 'A jelszó minimum 6 karakterből álljon!',
        txt_27: 'A jelszó maximális hossza 50 karakter.',
        txt_28: 'A jelszó megerősítése',
        txt_29: 'A jelszó megerősítése',
        txt_30: 'Regisztráció',
        txt_31: 'Registering'
    },
    {
        txt_1: 'Регистрирайте се сега, за да получите достъп до безплатните уроци',
        txt_2: 'Вече сте се регистрирали?',
        txt_3: 'Вход',
        txt_4: 'Първо име',
        txt_5: 'Първото име е задължително.',
        txt_6: 'Първото име е с минимум 3 символа.',
        txt_7: 'Първото име е с максимум 20 символа.',
        txt_8: 'Фамилия',
        txt_9: 'Фамилията е задължителна.',
        txt_10: 'Фамилията съдържа минимум 3 символа.',
        txt_11: 'Фамилията е с максимум 20 символа.',
        txt_12: 'Изберете ролята си',
        txt_13: 'Студент',
        txt_14: 'Учител',
        txt_15: 'Други',
        txt_16: 'Изисква се роля.',
        txt_17: 'Въведете името на ролята си ...',
        txt_18: 'Името на ролята е задължително.',
        txt_19: 'Въведете името на училището/заведението си...',
        txt_20: 'Изисква се името на училището/заведението.',
        txt_21: 'Имейл адрес',
        txt_22: 'Изисква се имейл адрес.',
        txt_23: 'Невалиден имейл адрес',
        txt_24: 'Парола',
        txt_25: 'Изисква се парола.',
        txt_26: 'Минималната стойност на паролата е 6 символа.',
        txt_27: 'Максималната стойност на паролата е 50 символа.',
        txt_28: 'Потвърдете паролата си',
        txt_29: 'Потвърдете паролата си',
        txt_30: 'Регистрация',
        txt_31: 'Регистрация на'
    }
];


