<app-header></app-header>
<!--
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:hello@ednuv.com">hello@ednuv.com</a></p>
                    <p><a href="mailto:ednuv@hello.com">ednuv@hello.com</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Location Here</h3>
                    <p><a href="https://goo.gl/maps/Mii9keyeqXeNH4347" target="_blank">2750 Quadra Street Victoria Road, New York, United States</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:1234567890">+123 456 7890</a></p>
                    <p><a href="tel:2414524526">+241 452 4526</a></p>
                </div>
            </div>
        </div>
    </div>
</div>-->

<div class="contact-area pb-100" style="margin-top: 200px;">
    <div class="container">
        <div class="section-title cs-padding">
            <h2>{{lang_.txt_1}}</h2>
        </div>
        <!-- cu-box-shadow -->
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="{{lang_.txt_2}}" [class.invalid_input]="name.touched && !name.valid">
                            <div class="err_msg" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">{{lang_.txt_3}}</div>
                                <div *ngIf="name.errors.minlength">{{lang_.txt_4}}</div>
                                <div *ngIf="name.errors.maxlength">{{lang_.txt_5}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="number" #number="ngModel" class="form-control" id="number" placeholder="{{lang_.txt_6}}" [class.invalid_input]="number.touched && !number.valid">
                            <div class="err_msg" *ngIf="number.touched && !number.valid">{{lang_.txt_7}}</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" [email]="true" placeholder="{{lang_.txt_8}}" [class.invalid_input]="email.touched && !email.valid">
                            <div class="err_msg" *ngIf="email.touched && !email.valid">
                                <div *ngIf="email.errors.required">{{lang_.txt_9}}</div>
                                <div *ngIf="!email.errors.required">{{lang_.txt_10}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" minlength="5" maxlength="250" cols="30" rows="5" class="form-control" placeholder="{{lang_.txt_11}}" [class.invalid_input]="message.touched && !message.valid"></textarea>
                            <div class="err_msg" *ngIf="message.touched && !message.valid">
                                <div *ngIf="message.errors.required">{{lang_.txt_12}}</div>
                                <div *ngIf="message.errors.minlength">{{lang_.txt_13}}</div>
                                <div *ngIf="message.errors.maxlength">{{lang_.txt_14}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">

                        <button type="button" class="default-btn" style="cursor: not-allowed;" *ngIf="!contactForm.valid && !sendMessagePending">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">{{lang_.txt_15}}</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>

                        <button type="submit" class="default-btn" *ngIf="contactForm.valid && !sendMessagePending">
                            <i class='bx bx-paper-plane icon-arrow before'></i>
                            <span class="label">{{lang_.txt_15}}</span>
                            <i class="bx bx-paper-plane icon-arrow after"></i>
                        </button>

                        <button type="submit" class="default-btn" *ngIf="contactForm.valid && sendMessagePending">
                            <i class='icon-arrow before'><i class="bx bx-loader-alt fa-spin "></i></i>
                            <span class="label">{{lang_.txt_16}}</span>
                            <i class="icon-arrow after"><i class="bx bx-loader-alt fa-spin "></i></i>
                        </button>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>

<!--<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.165695483931!2d2.3953251156742406!3d48.855050679287096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e672762a66189d%3A0xb1e99231a74dbfb6!2s75%20Rue%20Alexandre%20Dumas%2C%2075020%20Paris%2C%20France!5e0!3m2!1sen!2slk!4v1624888352460!5m2!1sen!2slk"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>-->