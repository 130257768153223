<header class="header-area">
    <div class="top-header top-header-style-four">
        <div class="container" style="padding-bottom: 10px;">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <div class="top-header-social">
                        <span>{{lang_.txt_1}}:</span>
                        <a href="https://www.facebook.com/dbimaeu/" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="https://twitter.com/DBIMA_EU" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="https://www.linkedin.com/company/dbimaeu/" target="_blank"><i
                                class='bx bxl-linkedin'></i></a>
                        <a href="https://www.instagram.com/dbima_france/" target="_blank"><i
                                class='bx bxl-instagram'></i></a>
                        <a href="https://www.youtube.com/channel/UC9rc0LuQA7FYBEOHivM1dIA" target="_blank"><i
                                class='bx bxl-youtube'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4" [class.cs-hide-small]="!(currentUserName == null)">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login" *ngIf="(currentUserName == null)"><i class='bx bx-log-in'></i>
                                {{lang_.txt_6}}</a></li>
                        <li><a routerLink="/register" *ngIf="(currentUserName == null)"><i
                                    class='bx bx-log-in-circle'></i> {{lang_.txt_7}}</a></li>
                        <li class="cs-hide-small"><a *ngIf="!(currentUserName == null)" routerLink="/my-dashboard"><i
                                    class='bx bx-user'></i>{{currentUserName}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area " ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light cs-card" id="navBarComponent"
                [class.active]="classApplied">
                <a class="navbar-brand cs-hide-small" routerLink="/">
                    <img src="assets/img/logo/site/logo_main.png" alt="logo" style="height: 60px;width: auto;">
                    <img src="assets/img/logo/header/Co-Funded by erasmus.png" alt="logo"
                        style="height: 40px;width: auto;" class=" cs-margin-left">
                </a>
                <a class="navbar-brand cs-hide-large cs-hide-medium" style="width: 50%;" routerLink="/">
                    <img src="assets/img/logo/header/mobile-logo.png" alt="logo"
                        style="height: auto;width: auto;margin: 5px 0px 5px 0px;">
                </a>
                <button class="navbar-toggler cs-margin-left cs-hide-medium" type="button" style="content: center;">
                    <a routerLink="/my-dashboard" class="cart-wrapper-btn d-inline-block cs-margin-right"
                        *ngIf="!(currentUserName == null)">
                        <img src="{{currentUserprofileUrl}}" class=" cs-circle" style="width: 20px;height:20px;"
                            onerror="headerProfUrlError(this)">
                    </a>

                    <div class="search-box d-inline-block">
                        <img src="assets/img/flags/eng.svg" *ngIf="langCode == 'E'" style="width: 20px;height:auto;"
                            class="shadow" alt="image" onclick="modelShow('mobileView_langChanger')">
                        <img src="assets/img/flags/fre.svg" *ngIf="langCode == 'F'" style="width: 20px;height:auto;"
                            class="shadow" alt="image" onclick="modelShow('mobileView_langChanger')">
                        <img src="assets/img/flags/spa.svg" *ngIf="langCode == 'S'" style="width: 20px;height:auto;"
                            class="shadow" alt="image" onclick="modelShow('mobileView_langChanger')">
                        <img src="assets/img/flags/hun.svg" *ngIf="langCode == 'H'" style="width: 20px;height:auto;"
                            class="shadow" alt="image" onclick="modelShow('mobileView_langChanger')">
                        <img src="assets/img/flags/bul.svg" *ngIf="langCode == 'B'" style="width: 20px;height:auto;"
                            class="shadow" alt="image" onclick="modelShow('mobileView_langChanger')">
                    </div>

                </button>
                <button class="navbar-toggler cs-margin-right" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{lang_.txt_2}}</a></li>
                        <li class="nav-item"><a routerLink="/learn-film-marking-with-dbima" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{lang_.txt_3}}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/certification-verify" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{lang_.txt_4}}</a>
                        </li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{lang_.txt_5}}</a></li>
                    </ul>
                </div>
                <div class="others-option cs-hide-small">

                    <a routerLink="/my-dashboard" class="cart-wrapper-btn d-inline-block"
                        *ngIf="!(currentUserName == null)">
                        <img src="{{currentUserprofileUrl}}" onerror="headerProfUrlError(this)" class=" cs-circle"
                            style="width: 30px;height:30px;margin: 0px 0px 10px 0px;">
                    </a>
                    <app-language-changer></app-language-changer>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer cs-white"></div>
            <div class="search-overlay-layer cs-white"></div>
            <div class="search-overlay-layer cs-white"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line cs-black"></span>
                <span class="search-overlay-close-line cs-black"></span>
            </div>
            <div class="search-overlay-form cs-white" align="center">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

<div id="mobileView_langChanger" class="cs-modal" style="z-index: 999;" *ngIf="(currentUserName == null)">
    <div class="cs-modal-content cs-animate-zoom cs-card cs-round cs-padding-16"
        style="z-index: 9999;width: fit-content;">
        <div class="cs-container">
            <div>
                <h3>Select the Language</h3>
                <span onclick="modelClose('mobileView_langChanger')"
                    class="cs-button cs-display-topright cs-large">&times;</span>
                <hr>
            </div>
            <div align="center">
                <div align="left" style="width: fit-content;">
                    <div *ngFor="let language of languageList">
                        <div *ngIf="language.code == 'E'">
                            <div class="dropdown" (click)="lang_selector('E',language.courseId)"
                                *ngIf="language.code == 'E'">
                                <button class="dropbtn cs-round" [class.cs-pale-optionalColor]="langCode == 'E'"><img
                                        src="assets/img/flags/eng.svg" class="shadow" alt="image">
                                    <span style="margin-left: 10px">English</span></button>
                            </div>
                            <br>
                        </div>
                    </div>
                    <div *ngFor="let language of languageList">
                        <div *ngIf="language.code == 'F'">
                            <div class="dropdown" (click)="lang_selector('F',language.courseId)">
                                <button class="dropbtn cs-round" [class.cs-pale-optionalColor]="langCode == 'F'"><img
                                        src="assets/img/flags/fre.svg" class="shadow" alt="image">
                                    <span style="margin-left: 10px">French</span></button>
                            </div>
                            <br>
                        </div>
                    </div>
                    <div *ngFor="let language of languageList">
                        <div *ngIf="language.code == 'S'">
                            <div class="dropdown" (click)="lang_selector('S',language.courseId)">
                                <button class="dropbtn cs-round" [class.cs-pale-optionalColor]="langCode == 'S'"><img
                                        src="assets/img/flags/spa.svg" class="shadow" alt="image">
                                    <span style="margin-left: 10px">Spanish</span></button>
                            </div>
                            <br>
                        </div>

                    </div>
                    <div *ngFor="let language of languageList">
                        <div *ngIf="language.code == 'H'">
                            <div class="dropdown" (click)="lang_selector('H',language.courseId)">
                                <button class="dropbtn cs-round" [class.cs-pale-optionalColor]="langCode == 'H'"><img
                                        src="assets/img/flags/hun.svg" class="shadow" alt="image">
                                    <span style="margin-left: 10px">Hungarian</span></button>
                            </div>
                            <br>
                        </div>

                    </div>
                    <div *ngFor="let language of languageList">
                        <div *ngIf="language.code == 'B'">
                            <div class="dropdown" (click)="lang_selector('B',language.courseId)">
                                <button class="dropbtn cs-round" [class.cs-pale-optionalColor]="langCode == 'B'"><img
                                        src="assets/img/flags/bul.svg" class="shadow" alt="image">
                                    <span style="margin-left: 10px">Bulgarian</span></button>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- The Modal -->
<div id="firstTimeAccessChecker_modal" class="cs-modal cookie-model" style="z-index: 9999999999999999999;">
    <div class="cs-modal-content cs-animate-top cs-card-4 cs-round">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <a class="navbar-brand cs-hide-small" routerLink="/">
                        <img src="assets/img/logo/site/logo_main.png" alt="logo" style="height: 60px;width: auto;">
                        <img src="assets/img/logo/header/Co-Funded by erasmus.png" alt="logo"
                            style="height: 40px;width: auto;" class=" cs-margin-left">
                    </a>
                    <a class="navbar-brand cs-hide-large cs-hide-medium" style="width: 50%;" routerLink="/">
                        <img src="assets/img/logo/header/mobile-logo.png" alt="logo"
                            style="height: 40px;width: auto;margin: 5px 0px 5px 0px;">
                    </a>
                </h5>
                <!-- <button type="button" class="close cs-btn cs-large cs-round cs-border" onclick="closeModal('firstTimeAccessChecker_modal')">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <h1><b>Cookie & Privacy Policy</b></h1>
                <p>Cagliero E-Cademy use cookies or similar technologies and process personal data that may be
                    cross-referenced with information provided as part of our services for the following purposes: To
                    improve your user experience, To produce audience
                    statistics, To measure the performance of these advertisements, To use precise geolocation data, To
                    offer you functionalities relating to social networks, to fight against fraud. Some cookies are
                    necessary for the operation of the
                    site and our services. You can accept or continue browsing without accepting. <a
                        class="policyTitles" href="cookie-and-privacy-policy">Cookie & Privacy Policy</a></p>
                <hr>
                <div>
                    <a onclick="removeFTAC_Modal('accept');" class="default-btn cu-pointer btn-sm cs-round mt-2"
                        style="padding-left:30px;border-radius: 5px;">
                        Accept<span></span>
                    </a>
                    <span class=" cs-hide-large cs-hide-medium"><br></span>
                    <a onclick="removeFTAC_Modal('continue-without-accept');"
                        class="default-btn-reverse cu-pointer btn-sm cs-round ms-lg-2 mt-2 ms-md-2 ms-sm-2"
                        style="padding-left:30px;border-radius: 5px;">
                        Continue without Accept
                        <span></span>
                    </a>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" onclick="closeModal('firstTimeAccessChecker_modal')">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
        </div>
    </div>
</div>