<app-header></app-header>
<br><br><br><br>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="courses-details-header">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="courses-title">
                                <div class="row">
                                    <div class="col-6">
                                        <h2 routerLink="/learn-film-marking-with-dbima" class="cursor_p">
                                            {{courseData.courseName}}</h2>
                                        <h4>{{lang_.txt_1}}</h4>
                                    </div>
                                    <div class="col-6" style="text-align: right;">
                                        <a class="default-btn mb20 font_w_l cursor_p" style="float: right;border-radius: 50px 0px 50px 50px;" routerLink="/learn-film-marking-with-dbima/dashboard">
                                            <i class='bx bxs-dashboard icon-arrow before'></i>
                                            <span class="label">{{lang_.txt_2}}</span>
                                            <i class="bx bx-paper-plane icon-arrow after"></i>
                                        </a>
                                    </div>
                                </div>

                                <br>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-4">
                        <div class="courses-sidebar-information">
                            <h4>{{lang_.txt_3}}</h4>
                            <ul>
                                <li>
                                    <div onclick="toggleView('lessons')" class="cursor_p">
                                        <span><i class='bx bx-book'></i> {{lang_.txt_4}}:</span> 1 /2
                                    </div>

                                    <br><br>

                                    <div class="cs-animate-zoom" id="lessons">

                                        <div>
                                            <a class="cs-button cs-block cs-padding-16 cs-round row" href="learn-film-marking-with-dbima/exam-mcq" style="margin-left: 5px;">
                                                <div class="col-10 cs-left-align">
                                                    <label>{{lang_.txt_5}}</label>
                                                </div>
                                                <div class="col-2">
                                                    <span><i class="bx bx-check-double cs-xlarge"></i></span>
                                                </div>
                                            </a>

                                            <button class="cs-button cs-block cs-padding-16 cs-round row cs-light-gray" style="margin-left: 5px;cursor: default;">
                                                <div class="col-10 cs-left-align">
                                                    <label>{{lang_.txt_1}}</label>
                                                </div>
                                                <div class="col-2">
                                                    <span><i class="bx cs-xlarge"
                                                            [class.bx-check-double]="exam02Completed"
                                                            [class.bx-loader-alt]="!exam02Completed"></i></span>
                                                </div>
                                            </button>

                                        </div>
                                    </div>

                                </li>

                                <li>
                                    <span><i class='bx bx-detail'></i> {{lang_.txt_6}}:</span> <i class="bx bx-check-double cs-xlarge cs-text-green"></i>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-1"></div>

                    <div class="col-lg-6">
                        <div class="courses-details-image cs-card">
                            <div style="padding: 30px;">
                                <div class="courses-details-header">
                                    <div class="courses-title">
                                        <h4>
                                            <b>{{lang_.txt_7}}</b> {{lang_.txt_8}}
                                            <b>{{lang_.txt_9}}</b> ({{lang_.txt_10}}).
                                            <br><br> {{lang_.txt_11}}
                                            <br><br>
                                            <span style="margin-left: 50px">&#x2022; {{lang_.txt_12}}</span><br><br> {{lang_.txt_13}}:
                                            <br><br>
                                            <b>{{lang_.txt_14}}</b><br>
                                            <span style="margin-left: 50px">&#x2022; {{lang_.txt_15}}</span><br>
                                            <span style="margin-left: 50px">&#x2022; {{lang_.txt_16}}</span><br>
                                            <span style="margin-left: 50px">&#x2022; {{lang_.txt_17}}</span><br>
                                            <span style="margin-left: 50px">&#x2022; {{lang_.txt_18}}</span><br><br>
                                            <b>{{lang_.txt_19}}</b><br>
                                            <span style="margin-left: 50px">&#x2022; {{lang_.txt_20}}</span><br><br> {{lang_.txt_21}}<br> {{lang_.txt_22}}
                                        </h4>
                                    </div>
                                </div>

                                <div class="courses-details-desc">

                                    <button class="default-btn mb20 font_w_l cursor_p" style="float: left;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;">
                                        <i class='bx bx-link icon-arrow before'></i>
                                        <span class="label" (click)="download()">{{lang_.txt_23}}</span>
                                        <i class='bx bx-link icon-arrow after'></i>
                                    </button>
                                </div>

                                <div class="courses-details-desc" *ngIf="!urlSubmitted">

                                    <button class="default-btn mb20 font_w_l cursor_p" style="float: right;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;">
                                        <i class='bx bx-file icon-arrow before'></i>
                                        <input #csvInput hidden="true" type="file" onclick="this.value=null"
                                            (change)="logoInputChange($event)" />
                                        <span class="label" (click)="csvInput.click()">{{uploadText}}</span>
                                        <i class='bx bx-file icon-arrow after'></i>
                                    </button>

                                    <br><br><br>
                                </div>

                                <div *ngIf="urlSubmitted">
                                    <div style="padding: 30px;">
                                        <div class="form-group">
                                            <input type="text" class="form-control" value="{{submittedURL}}" readonly style="color: gray;">
                                        </div>
                                        <br>
                                        <div align='center' *ngIf="!exam02Completed">
                                            <i class='bx bx-loader-alt cs-xxxlarge' style="color: var(--mainColor);"></i>
                                            <h2>{{lang_.txt_24}}</h2>
                                            <p class="cs-small">{{lang_.txt_25}}<i class="bx bx-check-circle cs-text-green"></i></p>
                                            <p>{{lang_.txt_26}}</p>
                                        </div>

                                        <div align='center' *ngIf="exam02Completed">
                                            <br>
                                            <div id="exam02ResultChart_div" style="width:300px;max-width:300px" class="cs-display-container">
                                                <div class="cs-display-middle">
                                                    <h2>{{exam02resultDisplay}}</h2>
                                                </div>
                                            </div>
                                            <br>
                                            <div>
                                                <h2>{{lang_.txt_27}}</h2>
                                                <a class="cursor_p cs-hover-text-blue" href="learn-film-marking-with-dbima/dashboard">{{lang_.txt_28}}<i class="bx bx-certification"></i></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-1"></div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</div>